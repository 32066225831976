import classes from './skillPicker.modal.module.scss';

import React from 'react';
import {
  db,
  Endpoints,
  formValidator,
  skillsResponseNormalizer,
  useTranslation,
} from '@lobox/utils';
import ModalForm from '../Modal/ModalForm';
import SvgSkills from '../shared/svg/SvgSkills';
import type { ModalFormProps } from '../type';

interface SkillPickerModalProps {
  onSuccess: ({ name, level }: any) => void;
  onClose: () => void;
  modalFormProps?: Partial<ModalFormProps>;
  initialValues?: any;
}

const SkillPickerModal = ({
  onClose,
  onSuccess,
  modalFormProps = {},
  initialValues = {},
}: SkillPickerModalProps): JSX.Element => {
  const { t } = useTranslation();

  const groups = () => [
    {
      name: 'name',
      cp: 'asyncAutoComplete',
      maxLength: 50,
      label: t('title'),
      helperText: t('skill_title_helper'),
      url: Endpoints.App.Common.getSkills,
      normalizer: skillsResponseNormalizer,
      required: true,
    },
    {
      name: 'level',
      cp: 'dropdownSelect',
      label: t('level'),
      helperText: t('skill_level_helper'),
      wrapStyle: classes.formItem,
      options: db.SKILL_LEVELS,
      required: true,
    },
  ];

  return (
    <ModalForm
      {...{
        initialValues,
        hideBack: true,
        onClose,
        onBack: onClose,
        title: initialValues?.id ? t('edit_skill') : t('add_skill'),
        local: true,
        image: <SvgSkills />,
        styles: {
          backdrop: classes.backdrop,
        },
        validationSchema: () =>
          formValidator.object().shape({
            level: formValidator
              .object()
              .test('value', 'required_level', (val) => val?.value),
          }),
        visibleHeaderBorderBottom: false,
        onSuccess,
        visibleRequiredHint: false,
        groups,
        ...modalFormProps,
      }}
    />
  );
};

export default SkillPickerModal;
