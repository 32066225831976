import { isString } from 'lodash';
import React from 'react';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import type { IconButtonColorSchema } from '../Button/IconButton';
import IconButton from '../Button/IconButton';
import Flex from '../Flex';
import classes from './index.module.scss';

type StyleProps = {
  root?: string;
  checkbox?: string;
  textContainer?: string;
  label?: string;
  helperText?: string;
};

interface CheckBoxProps {
  value?: any;
  label?: any;
  helperText?: any;
  error?: any;
  onChange?: any;
  font?: any;
  classNames?: Partial<StyleProps>;
  labelProps?: any;
  defaultSchema?: IconButtonColorSchema;
  selectedSchema?: IconButtonColorSchema;
  disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  value,
  label,
  helperText,
  error,
  onChange,
  font,
  classNames,
  labelProps = {},
  selectedSchema = 'tertiary-transparent',
  defaultSchema = 'transparent',
  disabled,
}) => {
  const onChangeHandler = () => onChange?.(!value);

  return (
    <Flex className={cnj(classes.checkBoxRoot, classNames?.root)}>
      <IconButton
        className={cnj(
          classes.icon,
          classNames?.checkbox,
          value && classes.selectedIcon,
          error && classes.errorBoundary,
          disabled && classes.disabled
        )}
        name={value ? 'check-square' : 'square'}
        type="far"
        colorSchema={value ? selectedSchema : defaultSchema}
        onClick={onChangeHandler}
        iconProps={{
          size: 22,
        }}
        size="sm20"
      />

      {label && isString(label) && (
        <Flex
          className={cnj(
            classes.textContainer,
            helperText && classes.marginTop,
            classNames?.textContainer
          )}
        >
          <Typography
            color="thirdText"
            size={15}
            ml={-2}
            font={font}
            className={classes?.label}
            {...labelProps}
          >
            {label}
          </Typography>
          {helperText && (
            <Typography
              color="thirdText"
              size={15}
              className={classes?.helperText}
            >
              {helperText}
            </Typography>
          )}
        </Flex>
      )}
      {label && !isString(label) && label}
    </Flex>
  );
};

export default CheckBox;
