import React from 'react';
import { Field as FormikField } from 'formik';
import type { FieldProps } from 'formik';
import Field from './Field';
import { useTranslation } from '@lobox/utils';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

interface Props {
  name: string;
  component: any;
  label?: string;
  required?: boolean;
  privateable?: boolean;
  cp: any;
  onChange?: Function;
  trim?: boolean;
  forceVisibleError?: boolean;
  isFocused?: boolean;
  visibleOptionalLabel?: boolean;
}

type FormikFieldProps = FieldProps;

const FieldWrapper: React.FC<Props> = ({
  name,
  required,
  label,
  visibleOptionalLabel = true,
  ...rest
}) => {
  const { t } = useTranslation();

  const validate = React.useCallback(
    (value: any) => {
      let errorMessage;
      if (required && isEmpty(value) && !isDate(value)) {
        errorMessage = 'this field is required';
      }

      return errorMessage;
    },
    [required]
  );

  const updatedLabel =
    !required && !!label && visibleOptionalLabel
      ? `${label} (${t('optional')})`
      : label;

  return (
    <FormikField name={name} validate={validate}>
      {(formikFieldProps: FormikFieldProps) => (
        <Field
          {...rest}
          label={updatedLabel}
          required={required}
          name={name}
          formikFieldProps={formikFieldProps}
        />
      )}
    </FormikField>
  );
};

export default FieldWrapper;
