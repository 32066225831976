import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useDebounceState,
  useStateCallback,
  useTranslation,
} from '@lobox/utils';
import cnj from '../utils/cnj';
import Ripple from '../Ripple';
import Flex from '../Flex/Flex.component';
import type { TextProps } from '../Typography';
import Typography from '../Typography';
import IconButton from '../Button/IconButton';
import type { AutoCompleteProps } from '../AutoComplete';
import AsyncAutoComplete from '@lobox/uikit/AutoComplete/AsyncAutoComplete';
import Button from '../Button/Button.component';
import classes from './index.module.scss';
import { sortBy } from 'lodash';
import FixedRightSideModal from '@shared/components/molecules/Modal/FixedRightSideModalDialog';
import { ModalFooter, ModalHeaderSimple } from '@shared/components/molecules';
import DividerDot from '../Divider/Divider.dot';
import OverflowTip from '../Typography/OverflowTip';

type ItemType = { value: string | number; label: string };

interface CheckBoxGroupProps {
  value?: Array<ItemType>;
  className?: string;
  classNames?: {
    container?: string;
    optionsContainer?: string;
    inputContainer?: string;
    itemWrapper?: string;
  };
  onChange: Function;
  options: Array<ItemType>;
  asyncAutoCompleteProps?: AutoCompleteProps;
  labelProps?: TextProps;
  placeholder?: string;
  isCustomEntryAllowed?: boolean;
  onFocus: Function;
  onBlur: Function;
  isFocus: boolean;
  alwaysShowAutoCompleteInfo?: boolean;
  withConfirmation?: boolean;
  optionsVariant?: AutoCompleteProps['optionsVariant'] | 'modalcheckboxgroup';
  onLocalValuesChange?: (items: Array<ItemType>) => any;
  name: string;
}

const CheckBoxGroup: React.FC<CheckBoxGroupProps> = (props) => {
  const {
    value = [],
    className,
    classNames,
    onChange,
    options: propOptions,
    asyncAutoCompleteProps,
    labelProps = {},
    placeholder,
    isCustomEntryAllowed,
    isFocus,
    onBlur,
    onFocus,
    alwaysShowAutoCompleteInfo,
    withConfirmation = false,
    optionsVariant = 'none',
    onLocalValuesChange,
    name,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [visibleNewItemInput, toggleNewItemHandler] = useState(false);
  const [options, setOptions] = useStateCallback<Array<ItemType>>([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const { setValue, debounceValue } = useDebounceState<ItemType>(
    {} as ItemType,
    250
  );
  const valueLabels = useMemo(() => value.map((item) => item.label), [value]);
  const valueValues = useMemo(() => value.map((item) => item.value), [value]);
  const isInputVisible = alwaysShowAutoCompleteInfo || visibleNewItemInput;

  const propOptionsStr = propOptions?.map((item) => item?.value)?.join('');

  useEffect(() => {
    const updatedOptions = propOptions.reduce(
      (prev: Array<ItemType>, curr: ItemType) => {
        if (valueValues?.includes(curr.value)) {
          return [curr, ...prev];
        }
        return [...prev, curr];
      },
      []
    );

    setOptions(updatedOptions);
  }, [propOptionsStr, setOptions]);

  const hasDebounceValue = isCustomEntryAllowed
    ? !!debounceValue?.label?.trim()?.length
    : debounceValue?.value;

  const optionsLabel = useMemo(
    () => options?.map((item) => item.label),
    [options]
  );

  const onChangeHandler = (val: ItemType) => {
    setValue(val);
  };
  const handleSelect = (val: ItemType) => {
    if (!val) return;
    setValue(val);
    onAddHandler(val);
  };

  const onAddHandler = (_value = debounceValue) => {
    const isOnOptions = optionsLabel?.includes(_value?.label as string);

    if (isOnOptions) {
      onChange([...value, _value]);
      setValue({} as ItemType);
      toggleNewItemHandler(false);
    } else {
      setOptions(
        (prev: any) => [_value, ...prev],
        () => {
          onClickCheckBoxHandler(_value as any);
          setValue({} as ItemType);
          toggleNewItemHandler(false);
        }
      );
    }
  };

  const addNewItemHandler = () => {
    toggleNewItemHandler(true);
  };

  const visibleOptions =
    debounceValue?.label?.length && optionsVariant === 'none'
      ? autoCompleteOptions
      : options;
  const isAutoCompleteOption = !!debounceValue?.label?.length;

  const onClickCheckBoxHandler = (item: ItemType) => {
    if (valueValues?.includes(item.value)) {
      onChange(value.filter((i) => i.value !== item.value));
    } else {
      onChange([...value, item]);
    }
    if (isAutoCompleteOption && optionsVariant !== 'dropdown') {
      const isOnOptions = optionsLabel?.includes(item?.label as string);
      if (isOnOptions) return;
      setOptions((prev) => [item, ...prev]);
    }
  };

  const handleClick = () => {
    if (optionsVariant === 'modalcheckboxgroup') {
      setIsOpen(true);
    }
  };

  const autoCompleteOptionsVariant =
    optionsVariant === 'modalcheckboxgroup' ? 'none' : optionsVariant;

  const onSaveOptions = () => {
    setOptions(localValues?.current);
    setIsOpen(false);
  };

  const localValues = useRef<any[]>();

  function handleLocalValuesChange(options: any[]) {
    localValues.current = options;
  }

  useEffect(() => {
    onLocalValuesChange?.(visibleOptions);
  }, [visibleOptions]);

  return (
    <>
      <Flex className={classNames?.container}>
        <Flex className={classNames?.optionsContainer}>
          {visibleOptions?.map((item: any) => {
            const isSelected = valueValues.includes(item.value);

            return (
              <Flex
                className={cnj(classes.itemWrapper, classNames?.itemWrapper)}
                key={item.value}
              >
                <Ripple onClick={() => onClickCheckBoxHandler(item)}>
                  <Flex className={cnj(classes.radioGroupRoot, className)}>
                    <IconButton
                      className={cnj(
                        classes.icon,
                        isSelected && classes.selectedIcon
                      )}
                      name={isSelected ? 'check-square' : 'square'}
                      type="far"
                      colorSchema={
                        isSelected ? 'tertiary-transparent' : 'transparent2'
                      }
                      iconProps={{
                        size: 22,
                      }}
                      size="sm20"
                    />
                    <Flex
                      className={
                        !!item?.helperText ? classes.labelContainer : undefined
                      }
                    >
                      <OverflowTip
                        size={15}
                        height={21}
                        color="thirdText"
                        lineNumber={1}
                        isTruncated
                        {...labelProps}
                      >
                        {t(item.label)}
                      </OverflowTip>
                    </Flex>
                    {item?.helperText && (
                      <>
                        <DividerDot />
                        <OverflowTip
                          size={12}
                          height={14}
                          color="secondaryDisabledText"
                          lineNumber={1}
                          isTruncated
                        >
                          {t(item.helperText)}
                        </OverflowTip>
                      </>
                    )}
                  </Flex>
                </Ripple>
              </Flex>
            );
          })}
        </Flex>
        {isInputVisible && (
          <Flex className={classNames?.inputContainer}>
            <AsyncAutoComplete
              variant="simple-large"
              placeholder={placeholder}
              value={debounceValue}
              onChange={onChangeHandler}
              rightIconProps={{ name: 'search' }}
              visibleRightIcon
              isFocus={isFocus}
              onBlur={onBlur}
              onFocus={onFocus}
              {...asyncAutoCompleteProps}
              name={`additionalItems-${name} `}
              onSelect={!withConfirmation && handleSelect}
              onOptions={
                optionsVariant === 'none' ? setAutoCompleteOptions : undefined
              }
              optionsVariant={autoCompleteOptionsVariant}
              onClick={handleClick}
            />
          </Flex>
        )}
        {asyncAutoCompleteProps && !isInputVisible && (
          <IconButton
            className={cnj(
              classes.addBtn,
              asyncAutoCompleteProps?.plusButtonClassName
            )}
            type="far"
            size="md15"
            name="plus"
            colorSchema="backgroundIconSecondary"
            onClick={addNewItemHandler}
          />
        )}
      </Flex>
      {isOpen && (
        <FixedRightSideModal isOpen>
          <ModalHeaderSimple
            visibleHeaderDivider
            title={t(props?.formGroup?.title)?.replace('*', '')}
            hideBack={false}
            backButtonProps={{
              onClick: () => setIsOpen(false),
            }}
            noCloseButton
          />
          <CheckBoxGroup
            {...props}
            optionsVariant="none"
            alwaysShowAutoCompleteInfo
            classNames={{
              container: classes.reverse,
              inputContainer: classes.modalInput,
            }}
            options={visibleOptions}
            onLocalValuesChange={handleLocalValuesChange}
          />
          <ModalFooter className={cnj(classes.footer)}>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              schema="ghost"
              labelProps={{ color: 'primaryText' }}
              label={t('cancel')}
            />
            <Flex className={classes.divider} />
            <Button onClick={onSaveOptions} fullWidth label={t('save')} />
          </ModalFooter>
        </FixedRightSideModal>
      )}
    </>
  );
};

export default CheckBoxGroup;
