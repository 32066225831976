import React from 'react';
import Flex from '@lobox/uikit/Flex/index';
import { JobBoard } from '../../../shared/components/organism';
import {
  appEnvironment,
  useTranslation,
  landingRouteNames,
} from '@lobox/utils';
import classes from './Prime.JobBoard.module.scss';

const PrimeJobBoard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex className={classes.primeJobBoardRoot}>
      <JobBoard
        reverse
        className={classes.firstJobBoard}
        title={t('job_board')}
        subtitle={t('job_board_content')}
        description={t('job_board_desc')}
        list={[
          {
            title: t('find_jobs'),
            subTitle: t('find_jobs_content'),
            icon: 'briefcase',
            iconType: 'far',
            color: 'success',
            to: landingRouteNames.findJob,
          },
          {
            title: t('follow_industry'),
            subTitle: t('find_jobs_content'),
            icon: 'industry-alt',
            iconType: 'far',
            color: 'brand',
            to: landingRouteNames.followIndustry,
          },
          {
            title: t('career_management'),
            subTitle: t('career_management_content'),
            icon: 'user-tie',
            iconType: 'far',
            color: 'warning',
            to: landingRouteNames.careerManagement,
          },
        ]}
        image={`${appEnvironment.storageBaseUrl}/assets/images/landing/job01.webp`}
      />
      <JobBoard
        title={t('business_tools')}
        subtitle={t('business_tools_content')}
        description={t('job_board_desc')}
        list={[
          {
            title: t('create_job_p'),
            subTitle: t('create_job_content'),
            icon: 'clipboard-list',
            iconType: 'far',
            color: 'success',
            to: landingRouteNames.signup,
          },
          {
            title: t('send_tests'),
            subTitle: t('send_tests_content'),
            icon: 'file-alt',
            iconType: 'far',
            color: 'brand',
            to: landingRouteNames.signup,
          },
          {
            title: t('setup_meetings'),
            subTitle: t('setup_meetings_content'),
            icon: 'address-book',
            iconType: 'far',
            color: 'warning',
            to: landingRouteNames.signup,
          },
        ]}
        image={`${appEnvironment.storageBaseUrl}/assets/images/landing/job02.webp`}
      />
    </Flex>
  );
};

export default PrimeJobBoard;
