import classes from './ProfileModalHeaderImage.module.scss';

import React from 'react';
import Divider from '../Divider';
import cnj from '../utils/cnj';
import Flex from '../Flex';

interface ModalFormProps {
  image?: React.ReactElement;
  styles?: any;
  rightSideProps?: any;
}

const ModalHeaderImage: React.FC<ModalFormProps> = ({
  image,
  styles,
  rightSideProps,
}) => {
  const isRightSide = rightSideProps?.isFromRightSide;
  const Image =
    // @ts-ignore
    React.cloneElement(image, {
      className: cnj(classes.modalImgWrap, styles?.imageStyle),
    });

  return (
    <>
      <Flex
        className={cnj(
          classes.modalImgContainer,
          isRightSide && classes.isRightSide
        )}
      >
        {Image}
      </Flex>
      {!isRightSide && <Divider className={classes.divider} />}
    </>
  );
};

export default ModalHeaderImage;
