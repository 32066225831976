import React from 'react';
import { isEmptyObjectValues } from '@lobox/utils';
import { Field } from 'formik';
import type { FieldProps } from 'formik';
import Button from '../Button';
import type { ButtonProps } from '../Button';

interface SubmitButtonProps extends ButtonProps {
  active?: boolean;
  type?: string;
  analyticsLabel?: string;
  style?: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ active, ...rest }) => {
  const disabled = (
    isSubmitting: boolean,
    isValid: boolean,
    status?: object
  ) =>
    active
      ? isSubmitting
      : isSubmitting || !isValid || !isEmptyObjectValues(status);

  return (
    <Field>
      {({
        form: { handleSubmit, isSubmitting, isValid, status },
      }: FieldProps) => (
        <Button
          {...rest}
          onClick={handleSubmit as any}
          disabled={disabled(isSubmitting, isValid, status)}
        />
      )}
    </Field>
  );
};

export default SubmitButton;
