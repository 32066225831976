import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import classes from './BrandLine.module.scss';

export interface BrandLineProps {
  className?: string;
}

const BrandLine: React.FC<BrandLineProps> = ({ className }) => {
  return <Flex className={cnj(classes.brandLineRoot, className)} />;
};

export default BrandLine;
