import classes from './ModalHeaderSearchInput.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import SearchInput from '../SearchInput';
import Flex from '../Flex';

interface StyleProps {
  searchIcon?: string;
  searchInput?: string;
}

export interface ModalHeaderSearchInputProps {
  styles?: Partial<StyleProps>;
  onSearchTextChanged?: (value: string) => void;
  toggleSearchBox?: (isOpen: boolean) => void;
  placeholder?: string;
}

const ModalHeaderSearchInput: React.FC<ModalHeaderSearchInputProps> = ({
  styles,
  toggleSearchBox,
  onSearchTextChanged,
  placeholder,
}) => {
  return (
    <Flex className={cnj(classes.searchIcon, styles?.searchIcon)}>
      <SearchInput
        toggleVariant="solid"
        className={cnj(classes.searchInput, styles?.searchInput)}
        toggleable
        onToggle={toggleSearchBox}
        onChange={onSearchTextChanged}
        placeholder={placeholder}
      />
    </Flex>
  );
};

export default ModalHeaderSearchInput;
