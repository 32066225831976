import React from 'react';
import cnj from '../utils/cnj';
import componentBuilder from './ComponentBuilder';
import FormGroupHeader from './FormGroupHeader';
import Flex from '../Flex';
import classes from './DynamicFormBuilder.module.scss';
import Divider from '../Divider';

interface DynamicFormBuilderProps {
  groups: Array<any>;
  className?: string;
}

const DynamicFormBuilder: React.FC<DynamicFormBuilderProps> = ({
  groups,
  className,
}) => {
  const lastIndex = groups?.length - 1;
  return (
    <Flex className={cnj(classes.formRoot, className)}>
      {groups.map((item: any, index) => (
        <>
          <Flex
            key={item.name}
            className={cnj(classes.formItem, item.wrapStyle)}
          >
            {/* @ts-ignore */}
            {item.formGroup && <FormGroupHeader {...item.formGroup} />}
            {componentBuilder({ ...item })}
          </Flex>
          {!!item?.divider && lastIndex !== index && (
            <Divider className={item?.divider?.className} />
          )}
        </>
      ))}
    </Flex>
  );
};

export default DynamicFormBuilder;
