import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Button from '@lobox/uikit/Button';
import type { ButtonProps } from '@lobox/uikit/Button';
import classes from './NavMenu.module.scss';

export interface NavMenuProps {
  className?: string;
  itemsList: Array<ButtonProps & { key?: string; id?: string }>;
  specialButtonProps: ButtonProps;
}

const NavMenu: React.FC<NavMenuProps> = ({
  className,
  itemsList,
  specialButtonProps,
}) => {
  return (
    <Flex className={cnj(classes.navMenuRoot, className)}>
      {itemsList.map((item) => (
        <Button
          key={item.label as string}
          schema="ghost"
          labelColor="white"
          {...item}
        />
      ))}
      <Button {...specialButtonProps} />
    </Flex>
  );
};

export default NavMenu;
