import React from 'react';

interface GirlOnDeskProps {
  className?: string;
}

const GirlOnDesk: React.FC<GirlOnDeskProps> = ({ className }) => (
  <svg
    width={935}
    height={253}
    viewBox="0 0 935 253"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M43.139 2.878s7.989 3.918 6.004 14.545c-1.985 10.626-2.839 12.058-.628 16.203 2.21 4.145 4.823 21.88 2.839 25.22-1.985 3.367-24.62-14.745-27.736-29.943-3.14-15.198 1.985-21.956 3.794-24.468 1.783-2.537 8.667-5.15 15.727-1.557z"
      fill="url(#girlOnDesk_prefix__paint0_linear)"
    />
    <path
      d="M40.124 249.514h1.809v-8.541s3.542 2.362 5.778 5.627c2.236 3.266 5.3 2.99 9.949 2.789 4.673-.201 4.446-2.085 4.044-4.723-.402-2.638-5.15-3.467-6.682-3.894-1.533-.427-5-6.406-6.13-7.863-1.533-1.984-2.337-3.893-2.337-3.893s-.879-3.542-5.175 0c-4.321 3.542-3.065 10.375-2.588 11.003.503.628 1.332 9.495 1.332 9.495z"
      fill="#112B46"
    />
    <path
      d="M49.243 240.772c1.608 1.055 5.779 0 5.779 0-1.181-1.532-8.467-11.756-8.467-11.756-4.421-2.588-6.18.979-6.18.979 1.834.528 7.26 9.747 8.868 10.777zM95.796 83.29s2.889.552 3.894-.805c.854-1.155 2.763-2.034 3.366-2.31.804-.377 2.814-2.412 3.266-1.407.478 1.004-3.165 3.516-3.818 4.195-.654.678 3.567.98 4.271 1.633.728.653-.176 7.611-3.04 7.059-2.864-.553-5.578-3.015-6.105-3.015-.528 0-2.11-.301-2.11-.301l.276-5.05z"
      fill="#FFE5C2"
    />
    <path
      d="M106.146 84.294s8.668 2.236 9.12 2.864c.452.628.301 1.105-.528.93-.829-.176-8.441-1.91-8.441-1.91s9.798 4.371 10.3 4.799c.277.25.051 1.18-.402 1.105-.452-.05-10.425-3.844-10.425-3.844s9.144 4.095 9.446 4.597c.251.428-.176 1.206-.88.955-.703-.251-9.421-3.592-9.421-3.592s6.482 3.391 6.482 3.969c0 .352-.151 1.13-1.809.2-1.633-.929-5.753-2.712-5.753-2.712l2.311-7.36z"
      fill="#FFE5C2"
    />
    <path
      d="M16.66 250.117h1.105v-6.255s1.984 2.035 2.889 5.024c.904 2.99 4.145 3.894 9.094 3.743 4.95-.15 5.025-2.085 4.598-5.024-.427-2.914-4.598-4.446-4.598-4.446-1.884-1.683-6.557-8.592-6.557-8.592s-2.99-.803-5.351 1.106c-2.362 1.884-1.608 6.179-1.558 7.285.1 1.105.377 7.159.377 7.159z"
      fill="#112B46"
    />
    <path
      d="M20.076 100.849s-5.879 7.662-7.487 16.756c-1.607 9.093.955 39.59.88 44.062-.076 4.496-4.246 15.675-3.417 24.116.829 8.465 4.12 50.568 4.12 50.568l9.02-1.784s-.528-42.931 0-46.473c.527-3.542 1.582-16.505 1.783-17.962.2-1.457 13.265-39.841 13.265-46.398.025-6.556-3.945-33.561-18.164-22.885z"
      fill="url(#girlOnDesk_prefix__paint1_linear)"
    />
    <path
      d="M49.67 103.838s9.673 36.928 10.828 42.053c1.156 5.124 2.814 9.344 2.814 11.505 0 5.049-1.884 13.138-1.884 13.138l-12.285 58.783H37.436s6.532-35.27 6.708-42.153c.175-6.908 5.502-16.73 4.446-19.644-1.055-2.914-16.807-36.652-16.807-36.652s-11.933-37.756 17.888-27.03z"
      fill="url(#girlOnDesk_prefix__paint2_linear)"
    />
    <path
      d="M29.748 243.159l-6.557-8.592-5.351 1.106s1.432 3.843 4.045 6.732c3.09 3.417 7.863.754 7.863.754z"
      fill="#FFE5C2"
    />
    <path
      d="M45.676 41.086s3.995.377 7.01 3.618c3.014 3.24 5.853 8.39 6.632 10.098.779 1.734 11.858 17.685 12.436 18.037.577.352 6.13 2.21 9.822 3.467 5.678 1.909 17.084 6.054 17.084 6.054l-2.261 7.16s-21.455-7.01-26.806-8.34c-6.557-1.608-21.681-21.002-21.681-21.002s-11.28-13.213-2.236-19.092z"
      fill="url(#girlOnDesk_prefix__paint3_linear)"
    />
    <path
      d="M38.717 38.273s5.175 2.436 6.959 2.788c1.784.352 8.768 9.395 9.974 12.711 1.23 3.291 0 8.265-2.513 14.068-2.512 5.803-.15 7.762-1.28 13.565-1.157 5.803-.78 27.558-.553 30.798.226 3.241-33.59 8.114-40.272 2.236 0 0 4.522-19.594 9.873-25.196 1.156-1.206 1.03-9.27.251-11.28-.779-2.009-9.697-17.006-9.697-17.006s-.653-15.223 3.24-18.238c3.367-2.612 12.562-3.542 14.22-4.12 1.608-.577 6.431-.829 9.798-.326z"
      fill="url(#girlOnDesk_prefix__paint4_linear)"
    />
    <path
      d="M41.18 32.32s-.152 1.431-.328 3.34c-.075.754-.15 1.608-.2 2.463-.202 2.587-.377 5.35-.277 6.757 0 0-5.376 5.376-11.481-6.28 0 0 2.563-4.798.628-11.355C27.588 20.69 41.18 32.32 41.18 32.32z"
      fill="#FFE5C2"
    />
    <path
      d="M56.202 70s1.282-8.59-.075-13.816c0 0 0 4.497-2.462 10.877l2.537 2.94z"
      fill="#2A6CE3"
    />
    <path
      d="M24.749 39.428s3.693 10.903 13.842 10.099c10.15-.804 4.799-9.22 4.799-9.22s-2.538-1.456-3.241-1.582c-.703-.126-11.205-.276-11.205-.276l-4.195.98z"
      fill="#FFE5C2"
    />
    <path
      d="M43.415 40.333s5.3 14.696 5.3 29.668.428 39.364 2.287 47.076c1.86 7.712 7.135 31.527 7.135 31.527l4.145-2.135s-9.948-38.033-10.702-40.772c-.754-2.738.578-31.702 1.281-34.139.704-2.437 3.743-13.213 3.266-16.228-.603-3.768-4.12-9.37-9.823-14.168l-2.89-.829z"
      fill="url(#girlOnDesk_prefix__paint5_linear)"
    />
    <path
      d="M26.759 38.977s3.29 10.575 4.974 24.116c1.683 13.54-5.125 76.392-9.698 90.661L4.35 150.89s3.819-44.414 8.969-52.503c5.15-8.088 6.808-15.248 1.23-32.104-5.576-16.881 2.438-25.221 2.438-25.221s8.215-1.91 9.773-2.085z"
      fill="url(#girlOnDesk_prefix__paint6_linear)"
    />
    <path
      d="M45.852 83.792l-21.455 37.154L.556 107.179 21.08 69.498l24.77 14.294z"
      fill="url(#girlOnDesk_prefix__paint7_linear)"
    />
    <path
      d="M43.264 85.073l-19.043 33.009-21.178-12.234 18.214-33.46 22.007 12.685z"
      fill="#fff"
    />
    <path
      d="M36.23 82.11l1.331-2.312-8.541-4.949-1.684 2.135 8.894 5.125z"
      fill="url(#girlOnDesk_prefix__paint8_linear)"
    />
    <path
      d="M35.225 108.486s-.98 0-1.407-.201c0 0-.754-.427-1.306-.704-.176-.075-.327-.151-.427-.201-.428-.151-1.432-.351-2.387-1.005-.955-.653-4.296-4.546-4.296-4.546l1.583-2.412 1.155-2.462s7.06.452 8.014.955c.101.05.176.1.252.15.753.503 1.13 1.206 1.482 1.533.126.125 1.407 1.356 1.357 1.884-.05.728-.402 1.231-.855 1.457 0 0-.175 1.432-.879 2.11 0 0-.126 1.331-1.23 1.909 0 0-.277 1.332-1.056 1.533z"
      fill="#FFE5C2"
    />
    <path
      d="M20.679 41.086s-6.507-1.859-10.25 5.3c-3.744 7.185-3.718 10.25-4.22 13.742-.478 3.316-2.588 12.334-2.815 13.289-.226.93-5.024 12.058-2.813 15.952 2.21 3.918 24.77 15.424 24.62 16.278-.15.854 3.894-8.692 3.894-8.692s-9.12-5.1-10.602-6.657c-2.437-2.537-7.537-5.275-6.507-8.44 1.231-3.718 4.95-16.204 5.804-18.037.879-1.834 8.39-20.398 2.889-22.735z"
      fill="url(#girlOnDesk_prefix__paint9_linear)"
    />
    <path
      d="M41.983 4.058s11.607 3.643 5.301 23.664c-6.306 20.021-22.133-2.311-20.475-14.52C28.467.993 41.983 4.058 41.983 4.058z"
      fill="#FFE5C2"
    />
    <path
      d="M48.465 15.74s-5.754 5.652-15.4 6.355c0 0-3.719 4.346-3.995 6.055 0 0-8.617-14.671 1.985-23.463 10.576-8.793 21.304 7.41 17.41 11.053z"
      fill="url(#girlOnDesk_prefix__paint10_linear)"
    />
    <path
      d="M33.19 22.497c-.075-1.457-.98-4.773-3.14-3.843-2.161.929.125 7.686 1.557 7.26 0 0 1.759.024 1.583-3.417z"
      fill="#FFE5C2"
    />
    <path
      d="M40.852 35.66c-.075.754-.15 1.608-.2 2.462 0 0-5.126-.301-8.542-7.913.2.327 3.97 5.828 8.742 5.451z"
      fill="#FED2A4"
    />
    <path
      d="M163.27 62.983l-23.238 12.761-23.314-36.676 28.213-9.797 18.339 33.712z"
      fill="url(#girlOnDesk_prefix__paint11_linear)"
    />
    <path
      d="M161.788 61.877L140.082 73.81l-21.806-34.29 26.378-9.17 17.134 31.527z"
      fill="#fff"
    />
    <path
      d="M169.727 55.472s-4.045-1.934-5.803-2.94c-1.784-1.004-6.331-2.386-6.708-2.738-.377-.326-13.516-1.13-14.32-1.833-.804-.679-1.583 1.607 1.834 2.361 3.417.754 8.165 2.562 8.416 3.894.251 1.331-3.492 1.658-3.894 2.738-.377 1.105.1.854.653 1.08.528.226 4.472-1.934 6.205-1.331 1.734.628 4.372-.226 5.703.226 1.332.452 7.788 2.612 7.788 2.612l.126-4.07zM138.901 71.147s-3.391-3.065-5.049-4.346c-1.633-1.281-4.723-5.05-5.175-5.25-.453-.226-6.331-12.31-7.311-12.787-.955-.477.904-2.11 2.939.804 2.035 2.914 5.603 6.682 6.959 6.406 1.357-.276.201-3.944 1.08-4.723.88-.779.855-.226 1.282.176.427.427-.101 4.974 1.155 6.355 1.256 1.382 1.483 4.22 2.412 5.301.955 1.08 5.502 6.305 5.502 6.305l-3.794 1.759z"
      fill="#FFE5C2"
    />
    <path
      d="M212.009 234.432s.728 5.1.829 5.853c.1.754 8.164-.452 8.164-.452l.654-6.079c.025.025-8.617-.025-9.647.678z"
      fill="#424773"
    />
    <path
      d="M212.762 239.658s-2.562 3.064-5.024 4.421c-2.487 1.331-6.356 1.859-7.839 3.266-1.055 1.004-3.843 5.3 6.432 5.225 10.275-.076 15.224.603 16.33-1.055 1.105-1.658-1.583-12.184-1.583-12.184s-2.437.854-8.316.327z"
      fill="#112B46"
    />
    <path
      d="M154.151 234.432s.729 5.1.829 5.853c.1.754 8.165-.452 8.165-.452l.653-6.079c0 .025-8.642-.025-9.647.678z"
      fill="#424773"
    />
    <path
      d="M154.879 239.657s-2.562 3.065-5.024 4.422c-2.487 1.331-11.582 2.436-13.039 3.818-1.055 1.005-2.814 5.024 11.657 4.647 10.275-.276 15.224.603 16.33-1.055 1.105-1.658-1.583-12.183-1.583-12.183s-2.311.929-8.341.351z"
      fill="#112B46"
    />
    <path
      d="M205.326 103.478s7.587 12.058 7.034 22.96c-.578 10.902 2.764 22.81 3.894 34.189 1.281 12.687 6.482 42.731 6.658 49.539.201 6.782-.804 28.462-.804 28.462l-10.401.226s-8.743-52.528-11.004-57.904c-2.261-5.35-13.491-43.584-13.867-47.855-.402-4.271 18.49-29.617 18.49-29.617z"
      fill="url(#girlOnDesk_prefix__paint12_linear)"
    />
    <path
      d="M165.381 105.11s-5.728 29.869-6.482 36.551c-.754 6.682-1.256 32.28-1.633 36.45-.377 4.17-2.512 54.563-4.522 60.743h13.315s10.225-36.627 10.225-53.96 7.889-37.681 12.988-49.111c5.1-11.43 16.757-11.707 19.898-32.909-.025 0-39.544-4.496-43.789 2.236z"
      fill="url(#girlOnDesk_prefix__paint13_linear)"
    />
    <path
      d="M192.664 2.115s-10.175-3.793-14.873 3.944c-4.698 7.737-4.245 23.287.88 25.196 5.125 1.91 12.837.904 15.475-3.944s5-11.857 4.899-16.555c-.125-4.697-3.693-7.285-6.381-8.641z"
      fill="#FFE5C2"
    />
    <path
      d="M179.776 31.607s.427 2.738-.276 4.094c-.704 1.357 13.189 1.382 13.189 1.382s1.131-7.36 3.266-13.59c2.161-6.23-16.179 8.114-16.179 8.114z"
      fill="#FFE5C2"
    />
    <path
      d="M195.98 23.493s-.954 1.582-4.874.98c-3.919-.604-8.692-5.829-10.325-6.406-1.633-.578-1.658-1.533-1.658-1.533l-1.03 1.507-1.734.1s1.583-4.646-.804-6.556c-2.386-1.909 2.488-15.072 15.049-10.701 0 0 9.647 2.663 9.295 8.139-.377 5.502-1.783 11.757-3.919 14.47z"
      fill="url(#girlOnDesk_prefix__paint14_linear)"
    />
    <path
      d="M178.671 35.55s.05-2.587 1.206-2.838c1.18-.251 12.963-1.382 14.395-.804v3.014c.025-.025-2.764 1.684-15.601.629z"
      fill="#5B8CEA"
    />
    <path
      d="M179.374 14.55c.226-.93 2.563-3.543 3.467-.73.905 2.814-1.357 6.608-2.613 6.281-1.256-.326-1.407-3.215-.854-5.552z"
      fill="#FFE5C2"
    />
    <path d="M131.942 66.123c-.075.024-.075.049 0 0z" fill="#323657" />
    <path
      d="M213.893 132.191s-6.256 1.834-16.908 3.014c-10.652 1.181-37.282-.427-37.433-1.683-.176-1.256 5.728-46.85 5.402-49.212-.327-2.336-.528-10.726-.528-10.726-.126 2.286-2.462 11.656-5.276 16.755-1.03 1.834-4.446 7.637-6.079 6.306-7.964-6.356-20.325-30.346-21.103-30.547.653-.276 5.853-2.688 6.582-2.914.628-.201 6.381 5.878 9.873 9.646a286.821 286.821 0 012.663 2.914c1.005-2.26 4.673-16.53 5.803-21.478 2.563-11.129 2.186-11.606 2.186-11.606 7.788-2.361 19.621-7.16 19.621-7.16l15.601-.627s14.32 3.165 19.973 5.702c.527.251.527 12.786 2.009 16.077l-4.672 9.873s-2.714 19.544-3.266 28.236c-.277 4.019 5.552 37.43 5.552 37.43z"
      fill="url(#girlOnDesk_prefix__paint15_linear)"
    />
    <path
      d="M216.907 72.956c-.402 1.306-2.261 2.436-3.467 2.914a4.638 4.638 0 01-2.487.251l-.603-.126-10.702-1.984s-2.336-26.955 14.622-33.41c.427.15 6.381 20.272 2.637 32.355z"
      fill="url(#girlOnDesk_prefix__paint16_linear)"
    />
    <path
      d="M213.441 75.87a4.641 4.641 0 01-2.488.25l-.603-.125 1.056-13.766 2.035 13.64zM151.061 75.769l-1.307 5.527s-1.406-5.552-1.356-8.441a302.058 302.058 0 012.663 2.914z"
      fill="#3C68C7"
    />
    <path
      d="M195.578 3.02s13.34-1.684.226 20.623l-.226-20.624z"
      fill="url(#girlOnDesk_prefix__paint17_linear)"
    />
    <path
      d="M127.471 37.108l-1.357-2.336 10.677-3.693 1.357 2.311-10.677 3.718z"
      fill="url(#girlOnDesk_prefix__paint18_linear)"
    />
    <path
      d="M883.584 251.505h.086a.826.826 0 00.824-.826l-1.251-53.953-3.666-.427 3.126 54.38c0 .484.398.826.881.826z"
      fill="url(#girlOnDesk_prefix__paint19_linear)"
    />
    <path
      d="M904.901 251.505h.085a.826.826 0 00.824-.826l-1.25-53.953-3.667-.427 3.127 54.38c0 .484.398.826.881.826z"
      fill="url(#girlOnDesk_prefix__paint20_linear)"
    />
    <path
      d="M846.067 251.505h-.085a.827.827 0 01-.825-.826l1.251-53.953 3.667-.427-3.127 54.38a.874.874 0 01-.881.826z"
      fill="url(#girlOnDesk_prefix__paint21_linear)"
    />
    <path
      d="M907.317 193.393v-36.974c0-6.495-5.258-11.765-11.739-11.765h-26.603c-6.48 0-11.738 5.27-11.738 11.765v38.37l50.08-1.396z"
      fill="url(#girlOnDesk_prefix__paint22_linear)"
    />
    <path
      d="M852.462 199.233h50.393c2.444 0 4.433-1.994 4.433-4.444v-1.395h-54.826v5.839z"
      fill="url(#girlOnDesk_prefix__paint23_linear)"
    />
    <path
      d="M846.408 199.233h38.654c1.478 0 2.7-1.197 2.7-2.706v-.456a2.701 2.701 0 00-2.7-2.706h-38.654a2.701 2.701 0 00-2.7 2.706v.456c0 1.481 1.222 2.706 2.7 2.706z"
      fill="url(#girlOnDesk_prefix__paint24_linear)"
    />
    <path
      d="M728.257 251.676h-.085a.826.826 0 01-.825-.826l1.251-53.952 3.666-.428-3.126 54.352a.899.899 0 01-.881.854z"
      fill="url(#girlOnDesk_prefix__paint25_linear)"
    />
    <path
      d="M706.94 251.676h-.085a.825.825 0 01-.824-.826l1.25-53.952 3.667-.428-3.127 54.352a.899.899 0 01-.881.854z"
      fill="url(#girlOnDesk_prefix__paint26_linear)"
    />
    <path
      d="M765.746 251.676h.085c.455 0 .824-.37.824-.826l-1.25-53.952-3.667-.428 3.127 54.38a.895.895 0 00.881.826z"
      fill="url(#girlOnDesk_prefix__paint27_linear)"
    />
    <path
      d="M704.524 193.536v-36.975c0-6.495 5.258-11.765 11.739-11.765h26.574c6.481 0 11.739 5.27 11.739 11.765v38.371l-50.052-1.396z"
      fill="url(#girlOnDesk_prefix__paint28_linear)"
    />
    <path
      d="M759.351 199.376h-50.393a4.445 4.445 0 01-4.434-4.444v-1.396h54.827v5.84z"
      fill="url(#girlOnDesk_prefix__paint29_linear)"
    />
    <path
      d="M765.405 199.376h-38.654a2.702 2.702 0 01-2.701-2.706v-.456a2.702 2.702 0 012.701-2.706h38.654c1.478 0 2.7 1.196 2.7 2.706v.456a2.702 2.702 0 01-2.7 2.706z"
      fill="url(#girlOnDesk_prefix__paint30_linear)"
    />
    <path
      d="M763.984 184.307c-3.809 6.637-8.584 9.201-8.584 9.201h-19.924c0 .028 31.407-14.243 28.508-9.201z"
      fill="#120E59"
    />
    <path
      d="M748.692 235.695c-.511.599-.596 1.254-.341 1.966.484 1.31 2.047 2.792 3.866 4.244.227.2.426.371.625.513.057.029.085.086.142.114.029.029.085.057.114.086 1.705 1.566 1.052 1.937 4.121 5.127 3.496 3.646 5.884 3.703 6.566 3.361.568-.285-.597-2.222-1.905-4.387a1.853 1.853 0 00-.199-.313c-.17-.285-.341-.57-.511-.883-1.535-2.592-1.137-3.817-.768-7.72.285-3.162 4.889-17.604 4.889-17.604l-6.679-1.139s-2.018 11.508-4.235 13.274c-1.194.94-2.501.969-3.638 1.652-.085.057-.171.086-.228.143-.653.541-1.307.968-1.819 1.566z"
      fill="#FFE5C2"
    />
    <path
      d="M748.692 235.696c-.511.598-.596 1.253-.341 1.965.569 1.311 2.104 2.763 3.866 4.245.227.199.426.37.625.541.057.028.085.085.142.114.029.028.085.057.114.085 1.705 1.567 1.052 1.937 4.121 5.128 3.496 3.646 5.884 3.703 6.566 3.361.568-.285-.597-2.222-1.905-4.387-.682-.256-3.837-1.538-6.196-4.586-2.103-2.678-3.496-6.125-4.917-8.119-.085.057-.17.086-.227.143-.682.484-1.336.911-1.848 1.51z"
      fill="#112B46"
    />
    <path
      d="M802.496 231.508c.426 2.051 3.354 1.709 6.622.912 3.269-.798 2.501.142 7.162-.371 4.662-.512 6.083-2.25 6.225-2.962.114-.627-2.189-.826-4.661-1.111-.313-.029-.626-.086-.938-.114-2.786-.342-4.832-2.165-7.39-3.646-2.416-1.396-5.997-7.122-5.997-7.122l-6.395.513s3.354 5.127 4.661 7.349c.768 1.311.711 3.048.625 4.387 0 .712-.056 1.453.086 2.165z"
      fill="#FFE5C2"
    />
    <path
      d="M809.146 232.448c3.269-.797 2.502.143 7.163-.37 4.661-.513 6.082-2.251 6.224-2.963.114-.626-2.188-.826-4.661-1.111l-.028.057s-2.814 1.994-6.68 2.08c-3.154.085-6.537-.826-8.697-.798-.057.712-.085 1.481.057 2.194.398 2.051 3.325 1.709 6.622.911z"
      fill="#112B46"
    />
    <path
      d="M777.967 190.488l-5.997 16.152-7.816 21.079s-2.132.2-4.149-.37c-1.876-.541-4.008-1.966-4.008-1.966s2.16-22.646 6.224-35.636c1.052-3.332 2.189-6.039 3.496-7.548 6.253-7.435 12.25 8.289 12.25 8.289z"
      fill="url(#girlOnDesk_prefix__paint31_linear)"
    />
    <path
      d="M771.885 164.851c-3.922-1.282-15.121.37-26.347 2.563l-2.359-3.902-24.273-1.88s-8.015 17.804-3.638 29.141c.654 1.681 1.933 2.735 4.121 2.763h19.469c12.08 0 25.552-8.318 27.286-8.66 2.842-.569 4.007 5.156 6.849 11.138 2.843 6.011 26.831 26.606 26.831 26.606s3.695.029 5.201-.541c1.649-.627 2.246-1.424 2.246-1.424s-26.547-52.956-35.386-55.804z"
      fill="url(#girlOnDesk_prefix__paint32_linear)"
    />
    <path
      d="M742.241 92.696c-3.098-1.624-7.845-1.852-8.612-1.966-9.209-1.253-14.751 12.164-15.064 16.209-.597 7.492-4.548 8.631-10.118 13.303-11.142 9.4-7.873 20.31-1.791 24.355 3.666 2.422 10.09 0 10.09 0 .085.057 17.622-9.827 26.262-14.585 12.989-1.851 7.134-33.157-.767-37.316z"
      fill="url(#girlOnDesk_prefix__paint33_linear)"
    />
    <path
      d="M735.789 125.967c.028.029-4.264 1.482-7.134.228-2.7-1.196-4.747-5.64-4.69-5.64 1.222-.057 2.587-1.139 3.61-8.204l.511.199 8.783 3.162s-.625 2.821-1.052 5.527c-.312 2.222-.511 4.387-.028 4.728z"
      fill="#FFE5C2"
    />
    <path
      d="M736.897 115.684s-.625 2.82-1.051 5.526c-5.06-.911-7.021-5.953-7.731-8.688l8.782 3.162z"
      fill="#FED2A4"
    />
    <path
      d="M738.205 118.504s-12.023-1.624-10.914-9.115c1.108-7.492.312-12.734 8.043-12.022 7.731.713 8.782 3.789 9.01 6.381.256 2.593-2.984 14.984-6.139 14.756z"
      fill="#FFE5C2"
    />
    <path
      d="M743.946 99.646s-7.702 6.638-12.932 7.179c-5.23.541-7.219-1.14-7.219-1.14s3.098-2.563 4.945-6.722a3.655 3.655 0 013.041-2.165c3.724-.314 10.801-.485 12.165 2.848z"
      fill="url(#girlOnDesk_prefix__paint34_linear)"
    />
    <path
      d="M739.114 99.048s2.587 2.507 3.382 4.358c.796 1.795 1.024 6.068.398 7.749 0 0 4.008-7.834 1.734-11.623-2.387-4.016-5.514-.484-5.514-.484z"
      fill="url(#girlOnDesk_prefix__paint35_linear)"
    />
    <path
      d="M745.936 143.714c-.37 24.413-.114 19.314-2.757 21.621-.654.57-2.445.883-4.832 1.054-7.475.484-20.578-.741-21.089-2.108-1.734-4.786-.512-6.267-1.933-13.588-.142-.769-.341-1.623-.54-2.535-1.194-5.042-2.359-6.751 1.876-14.813 3.837-7.378 7.844-13.303 8.214-13.103 10.175 6.039 10.999.968 10.999.968s10.232 10.597 10.062 22.504z"
      fill="url(#girlOnDesk_prefix__paint36_linear)"
    />
    <path
      d="M771.97 206.64l-7.816 21.079s-2.132.2-4.149-.37c-1.876-.541-4.008-1.965-4.008-1.965s2.16-22.647 6.224-35.636l9.749 16.892z"
      fill="url(#girlOnDesk_prefix__paint37_linear)"
    />
    <path
      d="M735.846 121.21s10.459 1.766 11.681 6.295c1.222 4.501 8.47 31.05 8.47 31.05s-5.315.969-7.788-1.51c-2.444-2.449-8.555-22.845-8.555-22.845l-3.808-12.99z"
      fill="url(#girlOnDesk_prefix__paint38_linear)"
    />
    <path
      d="M738.347 166.389c-7.475.484-20.578-.741-21.09-2.108-1.733-4.786-.511-6.267-1.932-13.588l3.581-10.198s-.597 11.309 2.728 14.3c3.326 2.963 13.643 5.754 15.263 8.233.768 1.196 1.308 2.392 1.45 3.361z"
      fill="url(#girlOnDesk_prefix__paint39_linear)"
    />
    <path
      d="M729.792 161.632s7.361-1.653 8.555-.997c1.193.655 4.093 3.048 4.462 3.874.37.826-2.643-.627-3.638-.883-.625-.171-2.302.398-3.126.683-3.468 1.168-5.6.57-5.6.57l-.653-3.247z"
      fill="#FFE5C2"
    />
    <path
      d="M724.846 120.242s-9.891.598-13.301 5.384c-4.832 6.722-14.382 35.436-8.897 39.282 6.168 4.301 28.082 1.139 28.082 1.139s.739-3.418-.285-5.07c-1.023-1.652-17.508-4.33-18.275-5.099-.767-.769 9.521-14.87 9.521-17.149 0-2.279 3.155-18.487 3.155-18.487z"
      fill="url(#girlOnDesk_prefix__paint40_linear)"
    />
    <path
      d="M738.688 162.686s1.535 1.994 2.046 2.222c.512.228.825.171.825.171l-1.052-1.88-1.819-.513zM738.347 160.635l4.49 2.193s-.198.314-.738.285c-.541-.028-1.933-.541-1.933-.541l-1.819-1.937z"
      fill="#FFE5C2"
    />
    <path
      d="M740.734 167.984h35.5l10.686-26.606h-35.442l-10.744 26.606z"
      fill="url(#girlOnDesk_prefix__paint41_linear)"
    />
    <path
      d="M776.66 166.93h-46.868v1.054h46.442l.426-1.054z"
      fill="url(#girlOnDesk_prefix__paint42_linear)"
    />
    <path
      d="M767.281 154.681c.255-.94-.285-1.681-1.194-1.681-.91 0-1.904.77-2.16 1.681-.256.94.284 1.681 1.193 1.681.91 0 1.876-.769 2.161-1.681z"
      fill="#fff"
    />
    <path
      d="M926.104 252.645h.114c.596 0 1.108-.541 1.08-1.168l-1.649-75.545-4.832-.598 4.122 76.143c.028.655.54 1.168 1.165 1.168z"
      fill="url(#girlOnDesk_prefix__paint43_linear)"
    />
    <path
      d="M729.962 252.645h-.113c-.597 0-1.109-.541-1.081-1.168l1.649-75.545 4.832-.598-4.121 76.143c-.029.655-.541 1.168-1.166 1.168z"
      fill="url(#girlOnDesk_prefix__paint44_linear)"
    />
    <path
      d="M931.135 177.328H725.756a3.874 3.874 0 01-3.866-3.874v-1.368a3.874 3.874 0 013.866-3.874h205.379a3.874 3.874 0 013.865 3.874v1.368a3.874 3.874 0 01-3.865 3.874z"
      fill="url(#girlOnDesk_prefix__paint45_linear)"
    />
    <path
      d="M863.404 168.211h-35.499l-10.687-26.606h35.443l10.743 26.606z"
      fill="url(#girlOnDesk_prefix__paint46_linear)"
    />
    <path
      d="M827.479 167.186h46.868v1.025h-46.442l-.426-1.025z"
      fill="url(#girlOnDesk_prefix__paint47_linear)"
    />
    <path
      d="M836.858 154.908c-.256-.94.284-1.681 1.194-1.681.909 0 1.904.769 2.16 1.681.256.94-.284 1.68-1.194 1.68-.909 0-1.876-.74-2.16-1.68z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="girlOnDesk_prefix__paint0_linear"
        x1={37.249}
        y1={1.295}
        x2={37.249}
        y2={59.448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint1_linear"
        x1={9.963}
        y1={167.431}
        x2={38.264}
        y2={167.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint2_linear"
        x1={29.448}
        y1={165.679}
        x2={63.341}
        y2={165.679}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint3_linear"
        x1={39.523}
        y1={48.472}
        x2={50.563}
        y2={98.084}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint4_linear"
        x1={8.923}
        y1={50.078}
        x2={41.053}
        y2={120.569}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint5_linear"
        x1={42.533}
        y1={56.843}
        x2={96.398}
        y2={93.003}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#295EB7" />
        <stop offset={0.433} stopColor="#305BB7" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint6_linear"
        x1={3.057}
        y1={56.478}
        x2={64.581}
        y2={113.566}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#305BB7" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint7_linear"
        x1={23.204}
        y1={69.498}
        x2={23.204}
        y2={120.946}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DEED" />
        <stop offset={1} stopColor="#E5EFFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint8_linear"
        x1={32.449}
        y1={74.849}
        x2={32.449}
        y2={82.109}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DEED" />
        <stop offset={1} stopColor="#E5EFFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint9_linear"
        x1={-1.36}
        y1={50.755}
        x2={28.848}
        y2={102.981}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint10_linear"
        x1={37.451}
        y1={2.186}
        x2={37.451}
        y2={28.236}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint11_linear"
        x1={139.994}
        y1={29.27}
        x2={139.994}
        y2={75.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DEED" />
        <stop offset={1} stopColor="#E5EFFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint12_linear"
        x1={186.854}
        y1={171.23}
        x2={222.969}
        y2={171.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint13_linear"
        x1={152.783}
        y1={170.194}
        x2={209.218}
        y2={170.194}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint14_linear"
        x1={187.362}
        y1={0.076}
        x2={187.362}
        y2={24.691}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint15_linear"
        x1={128.026}
        y1={50.234}
        x2={158.672}
        y2={149.036}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint16_linear"
        x1={197.256}
        y1={99.904}
        x2={209.454}
        y2={77.294}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint17_linear"
        x1={198.558}
        y1={3.054}
        x2={198.558}
        y2={23.712}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint18_linear"
        x1={132.131}
        y1={31.079}
        x2={132.131}
        y2={37.108}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DEED" />
        <stop offset={1} stopColor="#E5EFFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint19_linear"
        x1={882.035}
        y1={196.299}
        x2={882.035}
        y2={251.505}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint20_linear"
        x1={903.352}
        y1={196.299}
        x2={903.352}
        y2={251.505}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint21_linear"
        x1={847.616}
        y1={196.299}
        x2={847.616}
        y2={251.505}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint22_linear"
        x1={882.277}
        y1={144.654}
        x2={882.277}
        y2={194.789}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6587C4" />
        <stop offset={1} stopColor="#78A0C8" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint23_linear"
        x1={879.875}
        y1={193.394}
        x2={879.875}
        y2={199.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8DADE7" />
        <stop offset={1} stopColor="#88B1DA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint24_linear"
        x1={865.735}
        y1={193.365}
        x2={865.735}
        y2={199.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8DADE7" />
        <stop offset={1} stopColor="#88B1DA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint25_linear"
        x1={729.806}
        y1={196.47}
        x2={729.806}
        y2={251.676}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint26_linear"
        x1={708.489}
        y1={196.47}
        x2={708.489}
        y2={251.676}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint27_linear"
        x1={764.197}
        y1={196.47}
        x2={764.197}
        y2={251.676}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A90D6" />
        <stop offset={1} stopColor="#AAD5FF" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint28_linear"
        x1={729.55}
        y1={144.796}
        x2={729.55}
        y2={194.932}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6587C4" />
        <stop offset={1} stopColor="#78A0C8" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint29_linear"
        x1={731.938}
        y1={193.536}
        x2={731.938}
        y2={199.376}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8DADE7" />
        <stop offset={1} stopColor="#88B1DA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint30_linear"
        x1={746.078}
        y1={193.508}
        x2={746.078}
        y2={199.376}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8DADE7" />
        <stop offset={1} stopColor="#88B1DA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint31_linear"
        x1={756.012}
        y1={204}
        x2={777.986}
        y2={204}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint32_linear"
        x1={714.025}
        y1={192.155}
        x2={807.35}
        y2={192.155}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint33_linear"
        x1={725.711}
        y1={90.817}
        x2={725.711}
        y2={145.856}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint34_linear"
        x1={733.808}
        y1={96.673}
        x2={733.808}
        y2={106.966}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint35_linear"
        x1={742.201}
        y1={97.654}
        x2={742.201}
        y2={111.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint36_linear"
        x1={712.17}
        y1={127.291}
        x2={728.429}
        y2={170.971}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint37_linear"
        x1={756.008}
        y1={208.768}
        x2={771.984}
        y2={208.768}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint38_linear"
        x1={734.904}
        y1={126.933}
        x2={750.715}
        y2={159.638}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint39_linear"
        x1={714.249}
        y1={144.46}
        x2={721.795}
        y2={170.201}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#295EB7" />
        <stop offset={0.433} stopColor="#305BB7" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint40_linear"
        x1={699.597}
        y1={127.397}
        x2={717.186}
        y2={170.701}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint41_linear"
        x1={763.827}
        y1={141.378}
        x2={763.827}
        y2={167.984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint42_linear"
        x1={753.226}
        y1={166.93}
        x2={753.226}
        y2={167.984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint43_linear"
        x1={924.058}
        y1={175.334}
        x2={924.058}
        y2={252.645}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint44_linear"
        x1={732.008}
        y1={175.334}
        x2={732.008}
        y2={252.645}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint45_linear"
        x1={828.445}
        y1={168.212}
        x2={828.445}
        y2={177.328}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint46_linear"
        x1={840.311}
        y1={141.605}
        x2={840.311}
        y2={168.211}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
      <linearGradient
        id="girlOnDesk_prefix__paint47_linear"
        x1={850.913}
        y1={167.186}
        x2={850.913}
        y2={168.211}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96B8F5" />
        <stop offset={1} stopColor="#BCDBFA" />
      </linearGradient>
    </defs>
  </svg>
);

export default GirlOnDesk;
