import React from 'react';

const LinkedinIcon = (props: any): JSX.Element => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.497 6.563h4.18v12.576H.497V6.563zM2.615.5C1.185.5.25 1.439.25 2.673c0 1.207.907 2.173 2.31 2.173h.027c1.458 0 2.365-.966 2.365-2.173C4.925 1.439 4.045.5 2.615.5zM14.938 6.268c-2.218 0-3.212 1.218-3.768 2.076V6.563H6.99v12.576h4.18v-7.023c0-.376.027-.751.138-1.02.302-.751.99-1.529 2.145-1.529 1.512 0 2.117 1.153 2.117 2.844v6.728h4.18v-7.211c0-3.863-2.062-5.66-4.812-5.66z"
      fill="#069"
    />
  </svg>
);

export default LinkedinIcon;
