import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import MouseScroll from '@shared/svg/landing/MouseScroll';
import type { ButtonProps } from '@lobox/uikit/Button';
import classes from './AboutPage.Title.module.scss';

export interface AboutPageTitleProps {
  className?: string;
  title?: string;
  description?: string;
  buttonProps?: ButtonProps;
}

const AboutPageTitle: React.FC<AboutPageTitleProps> = ({
  className,
  title,
  description,
  buttonProps,
}) => {
  const { t } = useTranslation();

  return (
    <Flex className={cnj(classes.footerPageTitleRoot, className)}>
      {!!title && (
        <Typography
          font="700"
          color="white"
          mb={80}
          textAlign="center"
          className={classes.slogan}
        >
          {title}
        </Typography>
      )}
      {!!description && (
        <Typography
          font="400"
          size={15}
          height={21}
          color="white"
          mb={80}
          textAlign="center"
          className={classes.description}
        >
          {description}
        </Typography>
      )}
      {!!buttonProps && <Button variant="large" {...buttonProps} />}
      <Flex className={classes.blurArea}>
        <MouseScroll />
        <Typography
          font="400"
          size={15}
          height={21}
          color="white"
          mt={20}
          textAlign="center"
        >
          {t('scroll_down')}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default AboutPageTitle;
