import classes from './SignUpForm.module.scss';

import React from 'react';
import { mainRoutes, useGetDeviceId, useLocation } from '@lobox/utils';
import {
  Cookies,
  getCookieKey,
  redirectUrl,
  useReactMutation,
  useTranslation,
  landingRouteNames as routeNames,
  useOnSuccessLogin,
  LandingEndpoints as endpoints,
  getXEmailValidation,
  postSignup,
  urls,
  useSignUpRedirections,
} from '@lobox/utils';
import AuthSocialIcons from '../AuthSocialIcons';
import DynamicFormBuilder from '../Form/DynamicFormBuilder';
import Flex from '../Flex';
import Form from '../Form';
import SubmitButton from '../Form/SubmitButton';
import Link from '../Link';
import { usePrefetchRoutes } from '@shared/hooks/usePrefetchRoutes';

const INITIAL_GET_EMAIL = 'INITIAL_GET_EMAIL';

interface InitialValues {
  xemail: string;
  xpassword: string;
}

const SignUpForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { search }: any = useLocation();
  const reactQuery = useGetDeviceId();
  const deviceId = reactQuery?.data?.id;
  const onSuccessLogin = useOnSuccessLogin();

  usePrefetchRoutes([`/${mainRoutes.home}?prefetch=true`]);

  const searchClass = new URLSearchParams(search);
  const invitedEmail = searchClass.get('email');
  const initialValues: InitialValues = {
    xemail: invitedEmail || '',
    xpassword: '',
  };
  const { mutate } = useReactMutation({ url: endpoints.Auth.postLogin });
  const { saveRedirectUrl } = useSignUpRedirections();
  const location = useLocation();

  const onSuccess = ({ email }: any) => {
    const params = { email };
    Cookies.set(INITIAL_GET_EMAIL, params);
    redirectUrl(`${window?.location?.origin}${routeNames.getCode}`, params);
  };
  const socialLoginHandler = (socialName: any) => async () => {
    try {
      const DEVICE_ID = getCookieKey('deviceId');
      Cookies.set(DEVICE_ID, deviceId as string);
      const url = urls.socialAuth({
        deviceId,
        socialName,
      });
      redirectUrl(url);
    } catch (e) {
      console.error({ e });
    }
  };

  const onFailure = async ({ variables }: any) =>
    new Promise((resolve) => {
      const DEVICE_ID = getCookieKey('deviceId');
      mutate(
        {
          email: variables.xemail,
          password: variables.xpassword,
          deviceId,
        },
        {
          onSuccess: (response: { data: any }) => {
            Cookies.set(DEVICE_ID, deviceId);
            onSuccessLogin(response?.data);
          },
          onError: resolve,
        }
      );
    });

  const withSaveRedirectUrl: Function =
    (callback: any) => async (args: any) => {
      if (!location?.pathname?.includes(routeNames.signup)) {
        saveRedirectUrl(window?.location?.href);
      }
      return await callback(args);
    };

  return (
    <>
      <Form
        validationSchema={getXEmailValidation(t)}
        initialValues={initialValues}
        onSuccess={onSuccess}
        apiFunc={withSaveRedirectUrl(postSignup)}
        customErrorHandler={onFailure}
        customXNames={['email', 'password']}
        submitWithEnter
      >
        {() => (
          <>
            <DynamicFormBuilder
              groups={[
                {
                  name: 'xemail',
                  cp: 'input',
                  label: t('email'),
                  helperText: t('email_helper'),
                  style: classes.input,
                  trim: true,
                  autoComplete: 'new-password',
                  required: true,
                },
                {
                  name: 'xpassword',
                  cp: 'input',
                  label: t('password'),
                  helperText: t('password_helper'),
                  type: 'password',
                  keyboardType: 'visible-password',
                  showStrength: true,
                  autoComplete: 'new-password',
                  required: true,
                },
              ]}
              className={classes.form}
            />
            <Flex as="span" className={classes.rule}>
              {t('by_clicking')}
              <Link
                // prefetch={false}
                to={routeNames.userAgreement}
                className={classes.link}
              >
                {` ${t('user_agreement')} `}
              </Link>
              <Link
                // prefetch={false}
                to={routeNames.privacySecurity}
                className={classes.link}
              >
                {t('privacy_policy')}
              </Link>
              <Flex as="span" className={classes.rule}>{` ${t('and')} `}</Flex>
              <Link
                // prefetch={false}
                to={routeNames.aboutCookies}
                className={classes.link}
              >
                {t('cookie')}
              </Link>
            </Flex>
            <SubmitButton
              variant="large"
              schema="primary-blue"
              className={classes.submitButton}
              label={t('agree')}
            />
          </>
        )}
      </Form>
      <AuthSocialIcons
        title={t('sign_up_with_social')}
        google={{
          title: t('google_sign_up'),
          onClick: withSaveRedirectUrl(socialLoginHandler('google')),
        }}
        linkedin={{
          title: t('linkedin_sign_up'),
          onClick: withSaveRedirectUrl(socialLoginHandler('linkedin')),
        }}
      />
    </>
  );
};

export default SignUpForm;
