import React from 'react';
import AccordionItem from './AccordionItem';
import type { AccordionItemProps } from './AccordionItem';

export interface AccordionColumnProps {
  list: Array<AccordionItemProps>;
}

const AccordionColumn: React.FC<AccordionColumnProps> = ({ list }) => (
  <>
    {list.map(({ label, to }) => (
      <AccordionItem
        {...{
          key: label,
          label,
          to,
        }}
      />
    ))}
  </>
);

export default AccordionColumn;
