import { useEffect, useState } from 'react';
import { useHistory } from '@lobox/utils';
import { useTranslation, routeNames, useGlobalState } from '@lobox/utils';
import useOpenConfirm from './useOpenConfirm';

const useConfirmLeavePage = (): {
  handleBlockedRoute: any;
  visibleLeavePageConfirm: boolean;
} => {
  const { t } = useTranslation();
  const visibleLeavePageConfirm = useGlobalState('visibleLeavePageConfirm');
  const { openConfirmDialog } = useOpenConfirm();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const confirmLeavePageHandler = () => updateConfirmedNavigation(true);

  const handleBlockedRoute = (nextLocation: Location): boolean => {
    if (!visibleLeavePageConfirm || confirmedNavigation) {
      return true;
    }
    setLastLocation(nextLocation);
    openConfirmDialog({
      title: t('leave_title'),
      message: t('leave_helper'),
      confirmButtonText: t('leave'),
      cancelButtonText: t('cancel'),
      noBack: true,
      confirmCallback: confirmLeavePageHandler,
    });
    return false;
  };
  useEffect(() => {
    if (confirmedNavigation) {
      history.push(lastLocation?.pathname || routeNames.home);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]); // eslint-disable-line

  return { visibleLeavePageConfirm, handleBlockedRoute };
};

export default useConfirmLeavePage;
