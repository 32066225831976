import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex/index';
import Typography from '@lobox/uikit/Typography';
import {
  mainRoutes,
  routeNames as rN,
  useTranslation,
  useAuthUser,
  landingRouteNames,
} from '@lobox/utils';
import GirlOnDesk from '@shared/svg/landing/GirlOnDesk';
import classes from './Prime.Footer.module.scss';

const PrimeFooter: React.FC = () => {
  const { data: user } = useAuthUser();
  const createPageRoute = `${mainRoutes.page}${rN.pageCreation}`;
  const link = user
    ? { href: createPageRoute }
    : { to: landingRouteNames.signup };
  const { t } = useTranslation();

  return (
    <Flex className={classes.primeFooterRoot}>
      <Flex className={classes.textWrapper}>
        <Typography
          className={classes.title}
          font="400"
          size={20}
          height={28}
          color="coal"
          textAlign="center"
        >
          {t('prime_footer_title')}
        </Typography>
        <Typography
          className={classes.subTitle}
          font="300"
          size={16}
          height={28.8}
          color="coal"
          textAlign="center"
        >
          {t('prime_footer_subtitle')}
        </Typography>
      </Flex>
      <Button
        className={classes.button}
        label={t('prime_footer_button')}
        variant="large"
        {...link}
      />
      <GirlOnDesk className={classes.girlOnDesk} />
    </Flex>
  );
};

export default PrimeFooter;
