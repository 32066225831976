import React, { useEffect, useRef, useState } from 'react';
import { useDebounceState, useReactQuery } from '@lobox/utils';
import type { AutoCompleteProps } from './index';
import AutoComplete from './index';
import useMedia from '../utils/useMedia';

export interface AsyncAutoCompleteProps
  extends Omit<AutoCompleteProps, 'options'> {
  onChange?: Function;
  url?: any;
  normalizer?: Function;
  keywords?: string;
  apiFunc?: any;
  initSearchValue?: string;
  name: string;
  params?: {};
  showDropDownWithoutEnteringAnything?: boolean;
  onSelect?: Function | undefined;
  onOptions?: Function;
}

const AsyncAutoComplete: React.FC<AsyncAutoCompleteProps> = ({
  name,
  value: parentValue,
  url,
  normalizer,
  onChange: parentOnchange,
  keywords = 'text',
  isMulti,
  apiFunc,
  initSearchValue = 'a',
  params = {},
  showDropDownWithoutEnteringAnything,
  onOptions,
  optionsVariant,
  ...rest
}) => {
  const { isMoreThanTablet } = useMedia();
  const [filteredOption, setFilteredOption] = useState([]);
  const { debounceValue, setValue } = useDebounceState<string>(
    initSearchValue,
    250
  );
  const ref = useRef<any>(null);

  const onSuccess = (data: any) => {
    const newItems = normalizer ? normalizer(data) : data;
    setFilteredOption(newItems);
    if (!!onOptions) onOptions?.(newItems || []);
  };

  const { refetch } = useReactQuery({
    action: {
      spreadParams: !!apiFunc,
      apiFunc,
      key: [name],
      url,
      params: { [keywords]: debounceValue, ...params },
    },
    config: {
      enabled: false,
      onSuccess,
    },
  });

  useEffect(() => {
    if (debounceValue || showDropDownWithoutEnteringAnything) {
      refetch();
    }
  }, [refetch, debounceValue, showDropDownWithoutEnteringAnything]);

  const onChangeInput = (input: string) => {
    setValue(input);
    if (!isMulti) {
      parentOnchange?.({ label: input, value: null });
    }
    if (!input) {
      setValue(initSearchValue);
    }
  };

  const onSelect = (input: string) => parentOnchange?.(input);

  return (
    <AutoComplete
      {...{
        onChangeInput,
        onSelect,
        ref,
        value: parentValue,
        displayName: parentValue?.label,
        isMulti,
        name,
        ...rest,
        options:
          debounceValue || showDropDownWithoutEnteringAnything
            ? filteredOption
            : [],
        optionsVariant: !!optionsVariant
          ? optionsVariant
          : isMoreThanTablet
            ? 'dropdown'
            : 'modal',
      }}
    />
  );
};

export default AsyncAutoComplete;
