import { memo } from 'react';
import { isPropsChanged } from '@lobox/utils';

const isChangedForm = (Component: any) =>
  memo(
    Component,
    isPropsChanged([
      ['value'],
      ['public'],
      ['privacy'],
      ['dirty'],
      ['error'],
      ['status'],
      ['touched'],
      ['disabled'],
      ['editable'],
      ['isSubmitting'],
      ['options'],
      ['isFocus'],
      ['label'],
    ])
  );

export default isChangedForm;
