import classes from './Prime.Header.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex/index';
import Typography from '@lobox/uikit/Typography';
import SignUpForm from '@lobox/uikit/SignUpForm';
import { useTranslation } from '@lobox/utils';
import PrimeHeaderBg from '@shared/svg/landing/PrimeHeaderBg';
import WhiteWave from '@shared/svg/landing/WhiteWave';
import PrimeHeaderSvg from '@shared/svg/landing/PrimeHeader';

const PrimeHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex className={classes.primeHeaderRoot}>
      <PrimeHeaderBg className={classes.primeHeaderBg} />
      <WhiteWave className={classes.whiteWave} />
      <Flex className={classes.primeHeader}>
        <Flex className={classes.loginFormWrapper}>
          <Typography font="700" size={30} height={42} color="coal" mb={4}>
            {t('lobox_motto')}
          </Typography>
          <Typography font="400" size={20} height={28} color="coal">
            {t('lobox_submotto')}
          </Typography>
          <Typography
            font="400"
            size={20}
            height={28}
            color="coal"
            className={classes.signup}
          >
            {t('signup')}
          </Typography>
          <SignUpForm />
        </Flex>
        <PrimeHeaderSvg className={classes.headerImage} />
      </Flex>
    </Flex>
  );
};

export default PrimeHeader;
