import React from 'react';
import type { AutoCompleteProps } from './index';
import AutoComplete from './index';
import useMedia from '../utils/useMedia';

interface DropdownSelectProps extends AutoCompleteProps {
  onChange?: Function;
}
const DropdownSelect: React.FC<DropdownSelectProps> = ({
  onChange: parentOnChange,
  value,
  visibleRightIcon = true,
  optionsVariant,
  ...rest
}) => {
  const onSelect = (item: any) => parentOnChange?.(item);
  const { isMoreThanTablet } = useMedia();

  return (
    <AutoComplete
      {...{
        onSelect,
        editable: false,
        displayName: value?.label,
        value,
        visibleRightIcon,
        optionsVariant:
          optionsVariant || isMoreThanTablet ? 'dropdown' : 'bottomsheet',
        ...rest,
      }}
    />
  );
};

export default DropdownSelect;
