import classes from './ModalForm.module.scss';

import React from 'react';
import { useTranslation } from '@lobox/utils';
import useConfirmLeavePage from '../Confirmation/useConfirmLeavePage';
import Button from '../Button';
import Prompt from '../Prompt';
import Form from '../Form';
import DynamicFormBuilder from '../Form/DynamicFormBuilder';
import SubmitButton from '../Form/SubmitButton';
import cnj from '../utils/cnj';
import ProfileBasicModal from './ProfileBasicModal';
import ProfileModalHeaderImage from './ProfileModalHeaderImage';
import useMedia from '../utils/useMedia';
import Flex from '../Flex';
import type { ModalFormProps } from '../type';

const ModalForm = ({
  enableReinitialize,
  showConfirm,
  title,
  initialValues,
  local,
  groups,
  visibleRequiredHint = true,
  primaryAction,
  secondaryAction,
  styles,
  onSuccess,
  url,
  transform,
  validationSchema,
  onClose,
  onBack,
  disableCloseOnBackClick,
  method,
  colorSchema,
  image,
  hideBack,
  hideClose,
  confirmProps,
  enableConfirmLeavePage,
  visibleHeaderBorderBottom = true,
  apiFunc,
  closeByChangeRoute,
  rightSideProps,
  onFailure,
}: Omit<ModalFormProps, 'children'> & { rightSideProps: any }): JSX.Element => {
  const { isMoreThanTablet } = useMedia();
  const { t } = useTranslation();
  const { visibleLeavePageConfirm, handleBlockedRoute } = useConfirmLeavePage();

  return (
    <Form
      {...{
        enableReinitialize,
        initialValues,
        local,
        onSuccess,
        url,
        transform,
        validationSchema,
        method,
        hideBack,
        apiFunc,
        onFailure,
      }}
    >
      {(formikProps) => (
        <ProfileBasicModal
          {...{
            title,
            hideBack: isMoreThanTablet && hideBack,
            showConfirm: formikProps?.dirty || showConfirm,
            styles,
            onClose,
            onBack,
            disableCloseOnBackClick,
            colorSchema,
            visibleHeaderBorderBottom,
            hideClose,
            confirmProps,
            closeByChangeRoute,
            rightSideProps,
            hideBack,
            hideClose,
          }}
        >
          <Prompt
            when={
              visibleLeavePageConfirm &&
              formikProps?.dirty &&
              enableConfirmLeavePage
            }
            message={handleBlockedRoute as any}
          />
          <Flex className={cnj(classes.formRoot, styles?.formRoot)}>
            {!!image && (
              <ProfileModalHeaderImage
                image={image}
                styles={styles}
                rightSideProps={rightSideProps}
              />
            )}
            <Flex className={cnj(classes.formWrap, styles?.formWrap)}>
              <DynamicFormBuilder
                groups={
                  typeof groups === 'function' ? groups(formikProps) : groups
                }
                className={styles?.formRoot}
              />
            </Flex>
          </Flex>
          <Flex className={cnj(classes.submitWrap, styles?.submitWrap)}>
            {!!secondaryAction && (
              <Button
                schema="ghost"
                labelFont="bold"
                onClick={secondaryAction.onClick}
                labelClassName={classes.deleteLabelStyle}
                className={classes.deleteButton}
                label={secondaryAction.label}
                fullWidth={secondaryAction?.fullWidth}
              />
            )}
            <SubmitButton
              schema="primary-blue"
              labelFont="bold"
              className={cnj(
                classes.submitButton,
                styles?.submitButton,
                !!secondaryAction && classes.marginLeft
              )}
              labelClassName={classes.labelStyle}
              fullWidth={primaryAction?.fullWidth}
              active={primaryAction?.active}
              label={primaryAction?.label || t('save')}
            />
          </Flex>
        </ProfileBasicModal>
      )}
    </Form>
  );
};

export default ModalForm;
