import classes from './index.module.scss';

import React from 'react';
import Typography from '../Typography';
import Link from '../Link';
import Flex from '../Flex';

interface RememberProps {
  value: any;
  hint: string;
  label: string;
  link?: string;
  href?: string;
  error: any;
  onChange: any;
  visibleCheckBox?: boolean;
}

const Remember: React.FC<RememberProps> = ({
  // value,
  hint,
  // label,
  link,
  href,
  // error,
  // onChange,
  // visibleCheckBox = true,
}) => {
  const Anchor = link ? Link : 'a';
  const anchorProps = link ? { to: link } : { href };

  return (
    <Flex className={classes.root}>
      {/* visibleCheckBox && <CheckBox {...{ value, label, error, onChange }} /> */}
      {/* @ts-ignore */}
      <Anchor {...anchorProps}>
        <Typography size={13} color="brand">
          {hint}
        </Typography>
      </Anchor>
    </Flex>
  );
};

export default Remember;
