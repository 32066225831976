import classes from './index.module.scss';

import React from 'react';
import { privacyValues, useTranslation } from '@lobox/utils';
import type { PrivacyValueType } from '@lobox/utils';
import PopperMenu from '../PopperMenu';
import IconButton from '../Button/IconButton';
import PopperItem from '../PopperItem';
import Tooltip from '../Tooltip';

interface PrivacyVisibilityProps {
  isPublic?: boolean;
  onClick: (val: any) => any;
  privacy: PrivacyValueType;
}

const PrivacyVisibility: React.FC<PrivacyVisibilityProps> = ({
  privacy,
  onClick,
}) => {
  const { t } = useTranslation();

  const onClickHandler = (val: any) => () => {
    onClick(val);
  };

  const selected =
    privacy === privacyValues.EVERYONE_AT_LOBOX
      ? 'globe-americas'
      : privacy === privacyValues.MY_FOLLOWERS
        ? 'users'
        : 'lock';

  const selectedLabel =
    privacy === privacyValues.EVERYONE_AT_LOBOX
      ? t('every_one')
      : privacy === privacyValues.MY_FOLLOWERS
        ? t('my_followers')
        : t('only_me');

  return (
    <PopperMenu
      closeOnScroll
      placement="bottom-end"
      menuClassName={classes.menuClassName}
      buttonComponent={
        <span>
          <Tooltip
            trigger={
              <span>
                <IconButton type="far" name={selected} size="md" />
              </span>
            }
          >
            {t(selectedLabel)}
          </Tooltip>
        </span>
      }
    >
      <PopperItem
        onClick={onClickHandler(privacyValues.EVERYONE_AT_LOBOX)}
        isSelected={privacy === privacyValues.EVERYONE_AT_LOBOX}
        iconName="globe-americas"
        label={t('every_one')}
      />
      <PopperItem
        onClick={onClickHandler(privacyValues.MY_FOLLOWERS)}
        iconName="users"
        label={t('my_followers')}
        isSelected={privacy === privacyValues.MY_FOLLOWERS}
      />
      <PopperItem
        onClick={onClickHandler(privacyValues.ONLY_ME)}
        iconName="lock"
        label={t('only_me')}
        isSelected={privacy === privacyValues.ONLY_ME}
      />
    </PopperMenu>
  );
};

export default PrivacyVisibility;
