import React from 'react';
import type { colorsKeys } from '../utils/makeStyle';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import Icon from '../Icon';
import Flex from '../Flex';
import classes from './FormGroupHeader.module.scss';

interface FormGroupHeaderProps {
  icon?: string;
  title?: string;
  className?: string;
  rightComponent?: React.FC;
  color?: colorsKeys;
}

const FormGroupHeader: React.FC<FormGroupHeaderProps> = ({
  title,
  icon,
  className,
  rightComponent,
  color = 'primaryText',
}) => {
  return (
    <Flex className={cnj(classes.formGroupRoot, className)}>
      <Flex className={classes.leftSection}>
        {!!icon && (
          <Flex className={classes.iconWrap}>
            <Icon color={color} type="far" size={16} name={icon} />
          </Flex>
        )}
        <Typography color={color} font="bold" height={18} size={16}>
          {title}
        </Typography>
      </Flex>
      {rightComponent}
    </Flex>
  );
};

export default FormGroupHeader;
