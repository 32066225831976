import React from 'react';

const CreateCommunity: React.FC = () => (
  <svg
    width={81}
    height={80}
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={40.333} cy={40} r={40} fill="#F1F4F9" fillOpacity={0.5} />
    <path
      d="M55.057 23.942l-3.98 8.029L55.057 40h2.777V23.942h-2.777z"
      fill="#5384EE"
    />
    <path d="M48.069 23.942h6.987V40H48.07V23.942z" fill="#3F73E3" />
    <path
      d="M48.07 19.777h-2.778l2.083 16.058 2.777 2.082V21.86c0-1.15-.933-2.082-2.083-2.082z"
      fill="#5384EE"
    />
    <path
      d="M45.292 19.777H34.833v16.058h12.542V21.859c0-1.15-.933-2.082-2.083-2.082z"
      fill="#3F73E3"
    />
    <path
      d="M48.069 35.835h-4.165v2.082c0 1.15.932 2.083 2.082 2.083h2.083a2.083 2.083 0 000-4.165z"
      fill="#072252"
    />
    <path
      d="M34.833 54.67V63c6.352 0 11.5-1.865 11.5-4.165s-5.148-4.165-11.5-4.165z"
      fill="#83B4FF"
    />
    <path
      d="M43.557 58.835c0-2.3-3.906-4.165-8.724-4.165-6.35 0-11.5 1.865-11.5 4.165S28.483 63 34.833 63c4.818 0 8.724-1.865 8.724-4.165z"
      fill="#83B4FF"
    />
    <path
      d="M34.834 17c-.767 0-1.389.622-1.389 1.388v39.058l1.389 2.777 1.388-2.776V18.388c0-.767-.622-1.388-1.388-1.388z"
      fill="#8493A8"
    />
  </svg>
);

export default CreateCommunity;
