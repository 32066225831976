import classes from './index.module.scss';

import React from 'react';
import Typography from '../Typography';
import CheckBox from '../CheckBox';
import Flex from '../Flex';

type ShareOnFeedProps = {
  value?: boolean;
  labelText: string;
  labelHelperText: string;
};

const ShareOnFeed: React.FC<ShareOnFeedProps> = ({
  labelHelperText,
  labelText,
  value,
  ...rest
}) => {
  return (
    // @ts-ignore
    <CheckBox
      {...rest}
      value={value}
      classNames={{ root: classes.checkboxRoot }}
      label={
        <Flex className={classes.checkboxWrap}>
          <Typography color="thirdText" font="700" size={15} height={18}>
            {labelText}
          </Typography>
          <Typography color="primaryDisabledText" mt={4} size={15} height={21}>
            {labelHelperText}
          </Typography>
        </Flex>
      }
    />
  );
};

export default ShareOnFeed;
