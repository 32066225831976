import React from 'react';

const FindAudience: React.FC = () => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={40} cy={40} r={40} fill="#F1F4F9" fillOpacity={0.5} />
    <path
      d="M56.57 49.034l-5.107-4.237.006-2.994a3.793 3.793 0 001.444-2.975V36.88c0-2.108-1.727-3.816-3.858-3.816-2.13 0-3.858 1.708-3.858 3.816v1.947c0 1.206.566 2.28 1.45 2.98v2.99l-5.43 4.625a.749.749 0 00-.264.57v2.587c0 .306.252.555.561.555 2.233.397 4.489.615 6.748.657l7.751-.327a1.354 1.354 0 001.304-1.346v-1.511c0-.608-.274-1.185-.747-1.574z"
      fill="#83B4FF"
    />
    <path
      d="M48.513 54.992v3.39c0 .443-.336.8-.75.8H23.75c-.414 0-.75-.357-.75-.8v-3.39c0-2.43 1.052-4.722 2.851-6.212l6.526-5.408.643-.65 5.338-.172.779.822 6.525 5.408c1.799 1.49 2.851 3.783 2.851 6.212z"
      fill="#5384EE"
    />
    <path
      d="M40.094 44.165l-.958-.794-.778-.821-1.632.052-3.706.119-.643.65-6.526 5.409C24.052 50.27 23 52.562 23 54.992v3.39c0 .443.336.8.75.8h3.193v-2.645c0-2.43 1.052-4.723 2.851-6.212l1.084-.899c.975-.808 2.256-1.103 3.443-.737.456.14.938.216 1.436.216 2.418 0 4.456-1.775 5.027-4.168l-.69-.572z"
      fill="#3F73E3"
    />
    <path
      d="M39.136 43.371c0 1.991-1.513 3.605-3.38 3.605-1.866 0-3.379-1.614-3.379-3.605v-8.12h6.76v8.12z"
      fill="#FFE5C2"
    />
    <path
      d="M42.878 28.951v-1.743c0-2.626-1.996-4.755-4.458-4.755h-6.161l-.586-1.195a.435.435 0 00-.649-.177c-1.363.976-2.18 2.612-2.18 4.363v3.507l1.522 4.485h10.872l1.64-4.485z"
      fill="#485363"
    />
    <path
      d="M32.377 29.532v-3.507c0-1.34.48-2.611 1.316-3.572h-1.434l-.586-1.195a.435.435 0 00-.649-.176c-1.363.975-2.18 2.611-2.18 4.362v3.507l1.522 4.485h3.336l-1.325-3.904z"
      fill="#072252"
    />
    <path
      d="M35.86 43.218a8.301 8.301 0 003.276-.672v-7.295H32.377v8.12c0 1.992 1.513 3.605 3.38 3.605.418 0 .82-.082 1.19-.23-.756-.303-1.385-1.505-1.772-2.438-.214-.517.144-1.09.675-1.09h.01z"
      fill="#FED2A4"
    />
    <path
      d="M28.844 28.951v4.426l1.022.227.447 3.513 5.051 3.322 2.122.25 1.623-.25c2.24-1.25 3.769-3.75 3.769-6.634v-4.854c-4.704-.918-9.383-.928-14.034 0z"
      fill="#FFE5C2"
    />
    <path
      d="M32.377 32.974v-4.547c-1.18.115-2.357.29-3.533.524v4.854c0 4.134 3.141 7.485 7.017 7.485a6.746 6.746 0 003.253-.851c-3.733-.171-6.737-3.44-6.737-7.465z"
      fill="#FED2A4"
    />
    <path
      d="M28.784 59.183v-3.73c0-.4-.303-.723-.678-.723-.374 0-.678.324-.678.723v3.73h1.356zM44.085 59.183v-3.73c0-.4-.304-.723-.678-.723-.375 0-.678.324-.678.723v3.73h1.356z"
      fill="#5384EE"
    />
  </svg>
);

export default FindAudience;
