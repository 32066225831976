import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import HeroIcon from '@lobox/uikit/HeroIcon';
import Typography from '@lobox/uikit/Typography';
import Button from '@lobox/uikit/Button';
import Link from '@lobox/uikit/Link';
import { useTranslation } from '@lobox/utils';
import classes from './CareerItem.module.scss';

export interface CareerItemProps {
  noHover?: boolean;
  className?: string;
  iconName: string;
  title: string;
  subtitle?: string;
  status?: string;
  action?: (...args: any[]) => any;
  to?: string;
}

const CareerItem: React.FC<CareerItemProps> = ({
  noHover,
  className,
  iconName,
  title,
  subtitle,
  status,
  action,
  to,
}) => {
  const { t } = useTranslation();
  const Wrapper = to ? Link : Flex;
  const wrapperProps = to ? { to } : {};

  return (
    // @ts-ignore
    <Wrapper
      className={cnj(
        classes.careerItemRoot,
        !noHover && classes.careerItemRoot_hover,
        className
      )}
      {...wrapperProps}
    >
      <HeroIcon iconName={iconName} color="brand" iconType="fas" size={80} />
      <Typography
        font="700"
        size={20}
        height={23.44}
        color="graphene"
        textAlign="center"
        mt={20}
        mb={4}
      >
        {title}
      </Typography>
      {!!subtitle && (
        <Typography
          font="400"
          size={15}
          height={21}
          color="gray"
          textAlign="center"
          mb={4}
        >
          {subtitle}
        </Typography>
      )}
      {!!status && (
        <Typography
          font="400"
          size={12}
          height={16.8}
          color="brand"
          textAlign="center"
          mb={20}
        >
          {status}
        </Typography>
      )}
      {!!action && (
        <Button
          label={t('apply')}
          schema="semi-transparent"
          className={classes.applyButton}
          onClick={action}
        />
      )}
    </Wrapper>
  );
};

export default CareerItem;
