import * as React from 'react';

interface PrimeHeaderProps {
  className?: string;
}

const PrimeHeader = ({ className }: PrimeHeaderProps): JSX.Element => (
  <svg
    width={417}
    height={424}
    viewBox="0 0 417 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M416.903 413.216c-.62 2.384-3.718 3.885-4.072 6.71-.177 1.413-.708 2.472-1.327 3.178h-9.471c.089-.529.531-1.147 1.682-1.412 2.655-.618 3.629-3.973 3.452-7.064a30.909 30.909 0 010-4.061c.088-1.766.177-3.179.177-3.179l5.576-1.412.619.794.531.795c.177.088 3.541 3.355 2.833 5.651zM373.001 423.192h-19.029c.442-.53 1.151-1.059 2.39-1.236 2.92-.53 7.966-2.296 9.116-6.269.089-.088.089-.265.089-.441.885-4.238 7.434 1.324 7.434 1.324s0 .177.089.442c.088 1.236.354 4.503-.089 6.18z"
      fill="#112B46"
    />
    <path
      d="M357.159 311.148s.973 13.067 2.212 28.872c1.594 21.367 3.452 47.59 3.718 52.623.354 8.652 1.593 22.426 1.593 22.426s3.098 3.267 9.293 1.854c0 0 5.576-19.689 7.17-40.615 0-.353.088-.706.088-1.059 1.505-21.191 3.718-65.956 3.718-65.956l-27.792 1.855z"
      fill="url(#head__prefix__paint0_linear)"
    />
    <path
      d="M357.159 311.148s.973 13.067 2.212 28.872l21.774 36.377c0-.353.088-.707.088-1.06 1.505-21.19 3.718-65.955 3.718-65.955l-27.792 1.766z"
      fill="url(#head__prefix__paint1_linear)"
    />
    <path
      d="M361.673 312.649s17.967 47.413 29.738 69.663c11.772 22.25 15.489 28.519 15.489 28.519s6.727-2.825 7.347-4.768c0 0-4.514-42.999-12.391-53.506-4.426-5.915-9.736-23.662-9.913-29.931-.266-6.181-1.594-18.542-1.594-18.542l-28.676 8.565z"
      fill="url(#head__prefix__paint2_linear)"
    />
    <path
      d="M370.877 239.454c-.796-4.945-.796-7.858-.796-7.858l9.116-7.682c-.354 10.684 6.107 15.716 6.107 15.716s-12.126 6.269-19.118 3.974c-.088-.089 1.682-2.384 4.691-4.15z"
      fill="#FED2A4"
    />
    <path
      d="M367.868 231.419s11.595 2.03 12.657-5.563c1.062-7.593 3.275-12.449-4.071-14.127-7.346-1.589-9.205 1.06-10.09 3.532-.885 2.384-1.417 15.451 1.504 16.158z"
      fill="#FFE5C2"
    />
    <path
      d="M327.243 247.136l-2.479-1.59s-3.097-4.768-8.319-1.854c0 0-2.036 1.413-.709 2.561 1.328 1.147.974.529 2.921 2.207 1.947 1.678 4.426 2.296 6.019 1.413l2.036-.177.531-2.56z"
      fill="#A16D3C"
    />
    <path
      d="M324.321 247.577c.62-2.12.974-2.296.974-2.296s19.915 2.472 21.773 2.649c1.859.176 27.969-5.563 27.969-5.563l12.303 15.805s-36.289 1.412-41.068.971c-7.878-.795-22.128-8.476-22.128-8.476s-.265-1.413.177-3.09z"
      fill="url(#head__prefix__paint3_linear)"
    />
    <path
      d="M391.678 306.557c0 .794-1.593 1.677-2.213 2.03-5.222 2.473-10.001 4.15-15.489 4.768-6.461.707-14.161-.883-16.817-.794-.265 0-.531-.089-.708-.265-.177-.177-.265-8.565-.177-19.072v-1.412c0-1.06 0-2.208.089-3.355l.265-9.36c.177-4.591.443-9.182.797-13.244.354-4.238.885-7.858 1.504-10.506 1.417-5.916 4.603-9.889 7.612-12.803l2.39-2.207c2.39.529 4.957.353 7.523-.972 2.744-1.412 5.753-3.002 6.284-2.648 3.718 2.913 6.285 9.624 7.524 18.1.708 4.591 1.062 9.712.973 15.01 0 2.03-.088 4.149-.177 6.18-.619 11.213 0 21.014.354 26.665.177 1.677.177 2.914.266 3.885z"
      fill="url(#head__prefix__paint4_linear)"
    />
    <path
      d="M330.163 202.636l-73.196-12.273c-3.098-.53-6.019 1.678-6.55 5.033l-3.275 21.543c-.177.972.089 3.797.089 3.797l2.655.441c.177 0 .265.089.442.089.443.088.974 0 1.505-.177 2.39-.883 5.134-1.236 7.877-.706 7.701 1.501 13.1 9.094 12.303 17.305-.885 9.183-8.851 15.54-17.348 14.039-3.186-.53-6.018-2.119-8.142-4.326-.443-.442-.886-.618-1.417-.707l-2.743-.441-3.452 26.223c-.531 3.267 1.593 6.269 4.691 6.799l20.888 3.531c1.947.353 2.832 2.649 1.681 4.327-1.327 1.942-2.212 4.238-2.655 6.71-1.504 9.889 6.019 19.16 16.197 18.365 5.134-.441 10.887-4.944 12.746-9.977a17.62 17.62 0 00.354-10.86c-.62-1.943.973-3.797 2.832-3.532l21.065 3.532c3.098.53 5.93-1.678 6.461-4.945l11.683-77.256c.354-3.002-1.681-6.004-4.691-6.534z"
      fill="url(#head__prefix__paint5_linear)"
    />
    <path
      d="M382.915 254.64s-5.576 27.636-26.641 33.728l.265-9.359c.177-4.591.443-9.182.797-13.244.354-.176.708-.353 1.062-.441 7.169-2.473 24.517-10.684 24.517-10.684z"
      fill="url(#head__prefix__paint6_linear)"
    />
    <path
      d="M289.715 301.877a17.62 17.62 0 00.354-10.86c-.62-1.942.973-3.797 2.832-3.532l21.065 3.532c3.098.53 5.93-1.678 6.461-4.944l11.506-77.257c.443-3.179-1.593-6.092-4.602-6.622l-73.197-12.273c-3.098-.53-6.019 1.678-6.55 5.033l-3.274 21.543c-.443 2.826 2.124 5.033 4.691 4.062 2.389-.883 5.133-1.236 7.877-.707 7.7 1.501 13.099 9.095 12.303 17.306-.885 9.182-8.851 15.54-17.348 14.039-3.186-.53-6.019-2.119-8.143-4.327-1.416-1.412-3.717-.618-4.071 1.413l-3.541 23.663c-.531 3.266 1.593 6.268 4.691 6.798l20.888 3.532c1.948.353 2.833 2.649 1.682 4.326-1.328 1.943-2.213 4.238-2.655 6.711-1.505 9.889 6.018 19.159 16.197 18.365 5.222-.265 10.975-4.768 12.834-9.801z"
      fill="url(#head__prefix__paint7_linear)"
    />
    <path
      d="M327.331 264.882l-3.009-.53s-3.452-5.209-9.382-2.296c0 0-2.39 1.413-.797 2.737 1.594 1.325 1.151.618 3.364 2.473 2.212 1.854 5.045 2.648 6.815 1.677l2.301-.088.708-3.973z"
      fill="#FFE5C2"
    />
    <path
      d="M325.03 266.56c.088-2.384 1.239-2.56 1.239-2.56s22.835-1.589 24.782-2.031c1.947-.441 20.092-16.246 20.092-16.246s6.992-6.622 11.948-4.415c4.249 1.943 5.665 5.828 5.842 9.36.265 4.238-2.036 8.211-5.665 9.977-8.142 3.973-24.428 11.919-28.145 12.626-8.232 1.501-29.474-3.356-29.474-3.356s-.708-1.589-.619-3.355z"
      fill="url(#head__prefix__paint8_linear)"
    />
    <path
      d="M366.363 215.262s-4.691-3.62-1.593-9.359c0 0 3.717 1.324 8.939 0 5.222-1.325 9.913.529 10.533 2.472 0 0 2.035.883 2.212 4.061.177 3.267-5.93 13.421-5.93 13.421s-1.327-1.678-.973-2.914c.354-1.236 1.504-3.797.088-3.797-1.327 0-1.859 2.119-2.39 1.325-.531-.883.443-4.503-1.15-5.033-1.593-.706-7.877.883-9.736-.176z"
      fill="url(#head__prefix__paint9_linear)"
    />
    <path
      d="M132.039 259.659c.21 2.199 3.254 2.199 6.718 1.675 3.465-.523 2.52.419 7.349.315 4.829-.105 6.404-1.885 6.614-2.619.209-.628-2.205-1.152-4.62-1.675-.314-.105-.629-.105-.944-.21-2.835-.628-4.724-2.827-7.244-4.608-2.31-1.78-3.884-9.739-3.884-9.739h-6.824s2.205 7.226 3.254 9.739c.63 1.467-.105 3.352-.21 4.818-.105.733-.209 1.466-.209 2.304z"
      fill="#FFE5C2"
    />
    <path
      d="M138.757 261.335c3.465-.524 2.52.419 7.349.314 4.829-.104 6.404-1.885 6.614-2.618.21-.628-2.205-1.152-4.619-1.675v.104s-3.045 1.885-7.034 1.571c-3.255-.209-6.614-1.571-8.818-1.675-.105.733-.21 1.57-.105 2.408.21 2.095 3.254 2.095 6.613 1.571z"
      fill="#112B46"
    />
    <path
      d="M78.604 259.344c.105 2.304 3.15 2.304 6.614 1.885 3.464-.419 2.52.524 7.348.629 4.83.104 6.509-1.571 6.719-2.304.21-.629-2.1-1.152-4.62-1.885-.314-.105-.629-.21-.944-.21-2.835-.733-4.62-2.932-7.034-4.922-2.31-1.885-.42-9.425-.42-9.425l-5.144-2.618s-.735 7.854-1.05 11.834c-.21 1.675-.944 3.246-1.154 4.712-.21.629-.42 1.466-.315 2.304z"
      fill="#FFE5C2"
    />
    <path
      d="M85.217 261.229c3.464-.418 2.52.524 7.349.629 4.829.105 6.508-1.571 6.718-2.304.21-.628-2.1-1.152-4.619-1.885v.105s-3.15 1.78-7.034 1.256c-3.254-.314-6.508-1.78-8.713-2.094-.105.733-.315 1.571-.21 2.408 0 2.2 3.044 2.304 6.509 1.885z"
      fill="#112B46"
    />
    <path
      d="M90.257 146.242s-1.47 7.331-2.835 44.613c0 0-2.624 6.911-4.619 15.289-1.89 8.378-3.254 43.671-3.254 43.671s.735 1.152 3.15 1.361c1.889.105 3.463-.733 3.463-.733s13.438-46.079 20.052-63.987c6.508-17.908 10.393-43.461 10.393-43.461l-26.35 3.247z"
      fill="url(#head__prefix__paint10_linear)"
    />
    <path
      d="M106.423 149.383s1.05 7.436 11.968 42.937c0 0-.21 7.436.735 16.128.945 8.587 11.968 42.518 11.968 42.518s1.469.524 3.674-.105c1.995-.523 3.674-1.885 3.674-1.885s-4.199-48.487-3.989-67.652c.315-19.164-4.514-44.508-4.514-44.508l-23.516 12.567z"
      fill="url(#head__prefix__paint11_linear)"
    />
    <path
      d="M125.74 46.65c-1.679-1.152-3.779-1.362-5.249-1.362.42-.419 1.155-.524 1.155-.524-.945-.209-3.044.629-3.044.629-12.493-5.865-15.327 13.823-15.327 18.327.105 4.607-.21 9.844-6.72 13.614-12.807 7.435-3.044 22.097-3.044 22.097-2.31 7.016 6.3 15.604 6.3 15.604-.106-3.77 16.061-27.438 24.775-32.78 2.204-3.036 3.149-8.691 3.254-9.11-.525 4.503-1.365 6.912-2.1 8.273 7.454-6.493 4.514-31.732 0-34.769z"
      fill="url(#head__prefix__paint12_linear)"
    />
    <path
      d="M123.01 79.742c.105 0-4.094 2.304-7.138 1.361-2.94-.837-4.83-4.817-4.83-4.817 1.26-.314 1.575-1.99 1.575-9.74l.525.105 9.028 1.885.315.105s-.21 3.142-.21 6.074v1.78c.21 1.676.315 3.037.735 3.247z"
      fill="#FFE5C2"
    />
    <path
      d="M122.381 74.715v1.78c-5.249-.21-8.293-6.598-9.448-9.32l9.343 1.466.315.104c0-.104-.21 3.037-.21 5.97z"
      fill="#FED2A4"
    />
    <path
      d="M112.198 65.607s-2.73-4.608-3.465-8.482c-.525-2.828.84-13.196 10.813-11.73 0 0 2.835.315 5.564 2.2 2.205 1.57 5.249 4.817 1.785 13.928l-.945 3.037-13.752 1.047z"
      fill="url(#head__prefix__paint13_linear)"
    />
    <path
      d="M123.115 73.249s-12.283-1.676-11.233-9.844c1.155-8.064.315-13.72 8.189-12.986 7.978.733 9.028 4.084 9.238 6.807.315 2.932-2.939 16.232-6.194 16.023z"
      fill="#FFE5C2"
    />
    <path
      d="M125.74 51.152s-4.724 9.006-10.078 9.53c-5.354.628-7.349-1.257-7.349-1.257s4.409-2.723 6.089-8.378c0 0 9.448-4.817 11.338.105z"
      fill="url(#head__prefix__paint14_linear)"
    />
    <path
      d="M124.794 51.782s2.204 2.513 2.519 4.712c.315 2.2 0 6.493 1.155 6.493 0 0 3.464-6.074 1.155-10.158-2.415-4.399-4.829-1.047-4.829-1.047z"
      fill="url(#head__prefix__paint15_linear)"
    />
    <path
      d="M206.679 161.532c-2.624-5.76-9.763-10.578-15.852-10.578-4.514 0-8.398 1.571-11.443 4.189-1.784 1.467-4.409.524-4.829-1.78l-5.249-25.972c-.735-3.77-4.304-6.178-7.873-5.341l-85.769 19.479c-3.464.838-5.774 4.399-5.039 8.169l18.477 90.377c.524 2.618 2.31 4.503 4.619 5.341l-1.155.314h3.36c.42 0 .84-.105 1.259-.105l1.89-.418 22.045-5.027c3.045-.733 4.305-4.608 2.31-7.121-1.995-2.409-3.464-5.446-4.094-8.797-1.785-9.635 3.884-19.165 12.807-21.678 9.973-2.828 19.947 3.665 22.151 14.138.84 3.979.315 7.854-1.05 11.31-.525 1.361-.105 2.723.84 3.56l-1.26.629h2.94c.21 0 .42 0 .735-.105l26.245-5.969c3.569-.838 5.879-4.503 5.144-8.378l-5.249-25.867c-.525-2.409 1.574-4.503 3.779-3.875 2.624.733 5.354.838 8.188.209 10.813-2.513 17.217-15.185 12.073-26.704z"
      fill="#5384EE"
      fillOpacity={0.3}
    />
    <path
      d="M187.783 150.641c-4.514 0-8.399 1.571-11.443 4.189a2.954 2.954 0 01-4.829-1.78l-5.249-25.972c-.735-3.77-4.304-6.179-7.873-5.341l-85.77 19.479c-3.463.838-5.773 4.398-5.038 8.168l18.476 90.378c.735 3.875 4.41 6.283 7.979 5.445l23.83-5.445c3.045-.733 4.304-4.608 2.31-7.121-1.995-2.409-3.464-5.446-4.094-8.797-1.785-9.635 3.884-19.165 12.807-21.678 9.973-2.828 19.946 3.665 22.151 14.138.84 3.979.315 7.854-1.05 11.31-.945 2.199.945 4.608 3.254 4.084l26.245-5.969c3.57-.838 5.879-4.503 5.144-8.378l-5.249-25.867c-.524-2.409 1.575-4.503 3.78-3.875 2.624.733 5.354.838 8.188.209 11.023-2.513 17.427-15.185 12.283-26.704-2.52-5.76-9.763-10.473-15.852-10.473z"
      fill="url(#head__prefix__paint16_linear)"
    />
    <path
      d="M156.395 116.606s5.353.942 6.718 4.607c1.365 3.666-.525 6.179-1.365 6.179-.734 0-2.204-.628-2.939-2.513-.525-1.885-2.414-8.273-2.414-8.273zM82.802 133.047s5.984 0 8.189 3.77c2.204 3.77.42 6.807-.42 7.017-.84.104-2.52-.21-3.57-2.199-1.05-1.99-4.199-8.588-4.199-8.588z"
      fill="#FFE5C2"
    />
    <path
      d="M156.604 120.898c-.525-.105-2.834-.419-5.879-.838-5.459-.943-13.017-2.513-15.642-4.922-.735-.628-2.309-4.399-3.989-8.588-1.68 9.426-1.785 16.652-1.365 22.097l-36.848 8.378c3.15-6.702 8.084-15.08 7.139-21.887-.315-2.513-1.68-5.969-2.1-9.844 0-.314-.105-.629-.105-1.047-5.144 4.503-11.128 9.425-11.338 10.472-.21 1.676 3.78 20.002 3.78 20.002-1.05 2.409-5.354 1.885-5.354 1.885-4.095-3.037-10.078-20.421-9.553-24.296.524-3.874 15.327-25.762 18.896-30.998 3.57-5.132 16.482-5.027 16.797-5.027 10.288 6.493 11.233 1.047 11.233 1.047s9.238 1.885 10.917 4.713c1.575 2.932 8.609 24.82 9.029 25.657 1.05 1.676 17.951 8.064 18.266 9.949.525 1.99-2.414 3.561-3.884 3.247z"
      fill="url(#head__prefix__paint17_linear)"
    />
    <path
      d="M105.794 133.989l-12.912 2.932c3.149-6.703 7.348-15.08 6.508-21.783-.315-2.513-1.154-5.446-1.47-9.32 0-.419-.104-1.467-.104-1.467s9.133 7.541 9.238 15.709c.21 4.084-.525 9.635-1.26 13.929z"
      fill="url(#head__prefix__paint18_linear)"
    />
    <path
      d="M151.145 120.062c-5.564-.943-13.227-2.514-15.852-4.922-.735-.629-2.309-4.399-3.989-8.588 0 0-.63-6.807 1.05-6.598 1.679.315 4.199 12.358 5.984 13.196 1.574.523 10.393 4.084 12.807 6.912z"
      fill="url(#head__prefix__paint19_linear)"
    />
    <path
      d="M349.128 104.583c-.557-2.885-1.284-5.511-1.891-8.206l-1.335.451c.727 2.626 1.403 5.442 1.891 8.206l1.335-.451zm-4.568-16.117c-1.035-2.608-2.26-5.267-3.345-7.685l-1.077.622c1.086 2.419 2.31 5.077 3.276 7.565l1.146-.502zm-7.547-15.196c-1.395-2.4-3.028-4.662-4.85-6.975l-1.007.742c1.633 2.262 3.267 4.525 4.711 6.736l1.146-.503zm-9.92-13.503c-.866-.942-1.593-1.644-2.46-2.585-1.125-1.113-2.321-2.346-3.566-3.39l-.986 1.052c1.125 1.113 2.3 2.036 3.426 3.15.867.941 1.594 1.643 2.46 2.585l1.126-.812zm-13.238-10.14l.798-1.103c-2.092-1.677-4.493-3.334-6.874-4.683l-.608 1.153c2.261 1.418 4.592 2.956 6.684 4.632zm-13.687-8.44l.539-1.273c-2.501-1.28-4.982-2.25-7.582-3.15l-.538 1.272c2.65.713 5.081 1.872 7.581 3.152zm-15.123-5.683l.28-1.445c-2.58-.592-5.231-1.304-7.98-1.638l-.16 1.375c2.63.403 5.21.995 7.86 1.708zm-15.731-2.607l.16-1.375c-2.68-.214-5.41-.239-7.951-.212l.149 1.357c2.302.111 5.031.136 7.642.23zm-15.773.623l-.148-1.356c-2.521.335-5.212.929-7.782 1.453l.268 1.288c2.501-.645 5.022-.98 7.662-1.385zm-68.211 67.664c.409-2.321.818-4.642 1.486-6.792l-1.325-.356c-.479 2.2-1.127 4.66-1.487 6.792l1.326.356zm52.946-63.966l-.577-1.27c-2.551.834-4.963 1.908-7.494 3.05l.578 1.27c2.531-1.143 4.942-2.217 7.493-3.05zm-49.106 49.4c.888-2.598 1.915-4.956 3.111-7.571l-1.155-.615c-1.197 2.616-2.224 4.974-3.231 7.64l1.275.545zm34.896-42.786l-.697-1.2c-2.202 1.433-4.594 2.816-6.657 4.49l.817 1.131c2.013-1.484 4.146-3.038 6.537-4.421zm-27.897 28.156c1.406-2.255 2.742-4.631 4.476-6.596l-1.175-.924c-1.595 2.205-3.07 4.341-4.476 6.597l1.175.923zm15.171-18.713l-.817-1.13c-1.565 1.706-3.269 3.172-4.834 4.879-.408.396-.578.655-.986 1.051l1.055.993c.409-.396.578-.655.987-1.051 1.445-1.638 2.771-3.206 4.595-4.742z"
      fill="#5384EE"
    />
    <path
      d="M189.393 97.287l-.817-1.13-4.602 3.623-2.756-5.297-1.076.623 3.473 6.804 5.778-4.623z"
      fill="#5384EE"
    />
    <path
      d="M173.736 389.185L149.1 271.543h6.738c1.579 0 2.948-1.363 2.948-3.041v-3.984c0-1.678-1.369-3.041-2.948-3.041h-85.91c-1.58 0-2.949 1.363-2.949 3.041v3.984c0 1.678 1.37 3.041 2.948 3.041h8.739L54.03 389.185c-.316 1.573.631 3.146 2.105 3.565 1.474.315 3.053-.629 3.264-2.202l24.847-118.9h59.063l24.847 118.9c.316 1.573 1.789 2.622 3.263 2.202 1.685-.314 2.632-1.992 2.317-3.565z"
      fill="url(#head__prefix__paint20_linear)"
    />
    <path
      d="M21.426 377.508l13.364.088s-.442-3.444-9.47-4.327c-3.452-10.153-13.719-37.613-22.57-36.288-8.762 1.236 12.037 26.4 20.446 36.2-1.24 0-2.301.088-3.364.265-2.035-4.238-6.107-10.33-12.568-10.065-6.903.264 2.478 6.887 8.94 11.036-3.275 1.236-5.045 2.914-5.045 2.914l10.09.088c.088.177.177.177.177.089 0 .088 0 .088 0 0z"
      fill="#5384EE"
      fillOpacity={0.2}
    />
    <path
      d="M243.947 295.999h-24.46c-3.15 0-5.144 3.561-3.675 6.493 1.47 2.828-3.779 27.333-14.697 27.962-10.288.628-18.896-8.064-18.896-18.851 0-4.084 1.155-7.854 3.254-10.891 1.365-1.99 0-4.713-2.309-4.713h-26.98c-3.675 0-6.719 3.142-6.719 7.017 0 0-2.415 30.579-4.514 29.427-2.1-1.152-4.514-1.78-7.034-1.989h-.945c-.315 0-4.724-.105-4.724-.105l-.63 37.701h3.885c3.674-.105 6.823-.524 9.658-1.99 1.994-1.047 4.409.524 4.409 2.827v26.705c0 3.875 3.044 7.017 6.719 7.017h87.973c3.569 0 6.509-3.037 6.509-6.912v-92.681c-.105-3.77-3.15-7.017-6.824-7.017z"
      fill="#5384EE"
      fillOpacity={0.3}
    />
    <path
      d="M128.258 367.735c4.409 1.048 8.609.314 12.073-1.571 1.995-1.047 4.409.524 4.409 2.828v26.705c0 3.875 3.045 7.016 6.719 7.016h87.973c3.569 0 6.509-3.037 6.509-6.912V303.12c0-3.979-3.045-7.121-6.719-7.121h-24.46c-3.15 0-5.144 3.561-3.675 6.493 1.47 2.828 2.31 6.074 2.205 9.53-.21 9.739-7.664 17.803-16.902 18.431-10.288.524-18.896-8.063-18.896-18.85 0-4.084 1.155-7.854 3.254-10.891 1.365-1.99 0-4.713-2.309-4.713h-26.98c-3.674 0-6.719 3.142-6.719 7.017v26.495c0 2.409-2.414 4.084-4.514 2.932-2.415-1.257-5.144-1.99-7.979-1.99-11.337 0-20.156 10.996-17.321 23.459 1.364 6.178 7.453 12.462 13.332 13.823z"
      fill="url(#head__prefix__paint21_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M308.745 173.036H283.52c-5.576 0-10.09-4.768-10.09-10.595v-12.626c0-5.916 4.514-10.596 10.09-10.596h42.395c5.576 0 10.09 4.768 10.09 10.596v12.626c0 5.915-4.514 10.595-10.09 10.595h-8.408l4.603 14.657-13.365-14.657z"
      fill="#5384EE"
      fillOpacity={0.2}
    />
    <path
      d="M317.683 157.409c0 1.677 1.417 3.09 3.098 3.09a3.075 3.075 0 003.098-3.09c0-1.678-1.416-3.09-3.098-3.09-1.681 0-3.098 1.412-3.098 3.09zM304.762 160.499a3.075 3.075 0 01-3.098-3.09c0-1.678 1.416-3.09 3.098-3.09s3.098 1.412 3.098 3.09a3.075 3.075 0 01-3.098 3.09zM285.643 157.409c0 1.677 1.416 3.09 3.098 3.09a3.075 3.075 0 003.098-3.09c0-1.678-1.416-3.09-3.098-3.09s-3.098 1.412-3.098 3.09z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.19 24.762h17.322c3.78 0 6.929-3.246 6.929-7.33V8.74c0-3.98-3.044-7.331-6.929-7.331h-29.08c-3.779 0-6.928 3.246-6.928 7.33v8.693c0 3.98 3.044 7.33 6.929 7.33h5.774l-3.15 10.054 9.134-10.054z"
      fill="#5384EE"
      fillOpacity={0.2}
    />
    <path
      d="M89.102 14.08a2.103 2.103 0 01-2.1 2.095c-1.154 0-2.1-.942-2.1-2.094s.946-2.095 2.1-2.095c1.155-.105 2.1.943 2.1 2.095zM97.92 16.174c1.154 0 2.099-.942 2.099-2.094a2.103 2.103 0 00-2.1-2.095c-1.154 0-2.1.943-2.1 2.095 0 1.152.946 2.094 2.1 2.094zM111.043 14.08a2.103 2.103 0 01-2.1 2.095 2.103 2.103 0 01-2.099-2.094c0-1.152.944-2.095 2.099-2.095 1.155-.105 2.1.943 2.1 2.095z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="head__prefix__paint0_linear"
        x1={357.159}
        y1={363.286}
        x2={384.95}
        y2={363.286}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint1_linear"
        x1={357.159}
        y1={342.89}
        x2={384.95}
        y2={342.89}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint2_linear"
        x1={361.673}
        y1={357.458}
        x2={414.247}
        y2={357.458}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint3_linear"
        x1={354.916}
        y1={242.692}
        x2={356.161}
        y2={260.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint4_linear"
        x1={355.131}
        y1={247.981}
        x2={390.399}
        y2={310.781}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint5_linear"
        x1={272.102}
        y1={196.455}
        x2={311.106}
        y2={314.722}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#27467A" />
        <stop offset={0.433} stopColor="#1A2C53" />
        <stop offset={0.806} stopColor="#181C57" />
        <stop offset={1} stopColor="#192074" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint6_linear"
        x1={364.671}
        y1={256.594}
        x2={375.436}
        y2={289.355}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#749EE2" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint7_linear"
        x1={269.272}
        y1={196.006}
        x2={308.268}
        y2={314.278}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5384EE" />
        <stop offset={1} stopColor="#2758C2" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint8_linear"
        x1={354.668}
        y1={241.456}
        x2={359.234}
        y2={275.928}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F8AED" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint9_linear"
        x1={375.085}
        y1={205.483}
        x2={375.085}
        y2={225.884}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint10_linear"
        x1={79.458}
        y1={197.09}
        x2={116.415}
        y2={197.09}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint11_linear"
        x1={106.469}
        y1={193.994}
        x2={138.354}
        y2={193.994}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint12_linear"
        x1={110.122}
        y1={44.586}
        x2={110.122}
        y2={115.309}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint13_linear"
        x1={118.55}
        y1={45.303}
        x2={118.55}
        y2={65.656}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint14_linear"
        x1={117.026}
        y1={48.987}
        x2={117.026}
        y2={60.847}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint15_linear"
        x1={127.605}
        y1={50.608}
        x2={127.605}
        y2={62.977}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56548C" />
        <stop offset={0.179} stopColor="#444979" />
        <stop offset={0.504} stopColor="#28395D" />
        <stop offset={0.789} stopColor="#172F4C" />
        <stop offset={1} stopColor="#112B46" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint16_linear"
        x1={130.113}
        y1={124.298}
        x2={159.521}
        y2={250.561}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint17_linear"
        x1={110.949}
        y1={78.215}
        x2={122.584}
        y2={141.238}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7EAFFF" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={0.806} stopColor="#5159C0" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint18_linear"
        x1={90.218}
        y1={110.425}
        x2={105.687}
        y2={136.33}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#749EE2" />
        <stop offset={0.433} stopColor="#5384EE" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint19_linear"
        x1={137.955}
        y1={100.927}
        x2={143.292}
        y2={121.232}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#749EE2" />
        <stop offset={0.433} stopColor="#3D69C8" />
        <stop offset={1} stopColor="#626DF0" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint20_linear"
        x1={113.882}
        y1={261.477}
        x2={113.882}
        y2={392.843}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A84B1" />
        <stop offset={1} stopColor="#92BEE9" />
      </linearGradient>
      <linearGradient
        id="head__prefix__paint21_linear"
        x1={174.133}
        y1={298.35}
        x2={197.271}
        y2={408.216}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#FAFAFB" />
      </linearGradient>
    </defs>
  </svg>
);

export default PrimeHeader;
