import classes from './ProfileBasicModal.module.scss';

import React, { useEffect, useRef } from 'react';
import { useHistory } from '@lobox/utils';
import { useGlobalDispatch, useTranslation } from '@lobox/utils';
import cnj from '../utils/cnj';
import useConfirm from '../Confirmation/useConfirm';
import ModalHeaderImage from './ProfileModalHeaderImage';
import type { IconButtonColorSchema } from '../Button/IconButton';
import IconButton from '../Button/IconButton';
import Typography from '../Typography';
import ResponsiveModal from './ResponsiveModal';
import ModalHeaderSearchInput from './ModalHeaderSearchInput';
import Flex from '../Flex';

interface StyleProps {
  backdrop?: string;
  wrapper?: string;
  content?: string;
  modalRoot?: string;
  imageStyle?: string;
  modalHeader?: string;
  backBtn?: string;
  closeBtn?: string;
  searchIcon?: string;
  title?: string;
}

export interface ProfileBasicModalProps {
  children: React.ReactNode;
  image?: React.ReactElement;
  title?: string;
  onClose?: Function;
  onBack?: Function;
  renderHeader?: () => JSX.Element;
  hideBack?: boolean;
  hideClose?: boolean;
  showConfirm?: boolean;
  actionButton?: React.ReactNode;
  styles?: Partial<StyleProps>;
  disableCloseOnBackClick?: boolean;
  colorSchema?: IconButtonColorSchema;
  isSearchable?: boolean;
  onSearchTextChanged?: (value: string) => void;
  visibleHeaderBorderBottom?: boolean;
  confirmProps?: any;
  closeByChangeRoute?: boolean;
  rightSideProps?: any;
}

const ProfileBasicModal: React.FC<ProfileBasicModalProps> = ({
  children,
  image,
  title,
  onClose,
  onBack,
  hideBack,
  hideClose,
  showConfirm,
  actionButton,
  disableCloseOnBackClick = false,
  renderHeader,
  styles,
  colorSchema = 'modalHeaderIcon',
  isSearchable,
  onSearchTextChanged,
  visibleHeaderBorderBottom = false,
  confirmProps = {},
  closeByChangeRoute,
  rightSideProps,
}) => {
  const [isOpenSearchBox, toggleSearchBox] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const isBackButtonClickedRef = useRef(false);
  const onBackHandler = () => history.goBack();
  const dispatch = useGlobalDispatch();

  function backButtonHandler() {
    if (closeByChangeRoute) {
      onCloseHandler();
    }
  }

  useEffect(() => {
    if (closeByChangeRoute) {
      dispatch({ type: 'HIDE_LEAVE_CREATE_PAGE_CONFIRM' });
    } else {
      dispatch({ type: 'SHOW_LEAVE_CREATE_PAGE_CONFIRM' });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', backButtonHandler);

    return () => {
      window.removeEventListener('popstate', backButtonHandler);
    };
  }, [showConfirm]);

  const onConfirmCloseHandler = () => {
    if (onBack && isBackButtonClickedRef.current) {
      return onBack();
    }
    if (onClose) {
      return onClose();
    }
    return onBackHandler();
  };

  const onConfirmBackeHandler = () => {
    if (onBack) {
      onBack();
    } else {
      onBackHandler();
    }
  };
  const onBackDropClick = () => !disableCloseOnBackClick && onCloseHandler();

  const { openConfirm, Confirm } = useConfirm({
    cancelCallback: onConfirmCloseHandler,
    title: t('confirm_title'),
    message: t('confirm_desc'),
    cancelButtonText: t('confirm_cancel'),
    confirmButtonText: t('confirm_ok'),
    isReverse: true,
    ...confirmProps,
  });

  const onCloseHandler = () => {
    isBackButtonClickedRef.current = false;
    showConfirm ? openConfirm() : onConfirmCloseHandler();
  };

  const handleBackClicked = () => {
    isBackButtonClickedRef.current = true;
    showConfirm ? openConfirm() : onConfirmBackeHandler();
  };
  const isRightSide = rightSideProps?.isFromRightSide;
  const ModalTag = isRightSide
    ? rightSideProps?.modalComponent
    : ResponsiveModal;

  return (
    <ModalTag styles={styles} onBackDropClick={onBackDropClick}>
      <Flex
        className={cnj(
          classes.modalHeader,
          visibleHeaderBorderBottom && classes.modalHeaderBorderBottom,
          styles?.modalHeader
        )}
      >
        <Flex className={classes.titleWrap}>
          {!hideBack && (
            <IconButton
              onClick={handleBackClicked}
              className={cnj(classes.backBtn, styles?.backBtn)}
              name="chevron-left"
              colorSchema={colorSchema}
              type="far"
            />
          )}
          <Typography
            size={24}
            height={23}
            font="bold"
            color="thirdText"
            className={cnj(
              classes.title,
              isOpenSearchBox && classes.displayNone,
              styles?.title
            )}
          >
            {title}
          </Typography>
          {!!actionButton && <Flex className={classes.verticalLine} />}
        </Flex>
        {!!actionButton && actionButton}
        {!hideClose && (
          <IconButton
            className={cnj(classes.closeBtn, styles?.closeBtn)}
            onClick={onCloseHandler}
            name="times"
            colorSchema={colorSchema}
            type="far"
          />
        )}

        {isSearchable && (
          <ModalHeaderSearchInput
            {...{
              styles,
              toggleSearchBox,
              onSearchTextChanged,
            }}
          />
        )}
      </Flex>
      {renderHeader
        ? renderHeader()
        : !!image && <ModalHeaderImage {...{ image, styles }} />}
      {children}
      <Confirm />
    </ModalTag>
  );
};

export default ProfileBasicModal;
