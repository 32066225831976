import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import classes from './CardItem.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';
import Link from 'next/link';

const backgroundList: {
  [key: string]: any;
} = {
  success: 'mint_10',
  warning: 'pendingOrange_10',
  brand: 'navyBlue_10',
};

const foregroundList: {
  [key: string]: any;
} = {
  success: 'mint',
  warning: 'pendingOrange',
  brand: 'navyBlue',
};

export type BackgroundListType = keyof typeof backgroundList;

interface CardItemProps {
  title?: string;
  subTitle?: React.ReactNode;
  icon?: string;
  iconType?: 'fas' | 'far' | 'fal';
  className?: any;
  color?: BackgroundListType;
  to?: string;
  href?: string;
}

const CardItem: React.FC<CardItemProps> = ({
  title,
  subTitle,
  icon,
  iconType = 'fas',
  className,
  color = 'white',
  to,
  href,
}) => {
  const wrapperProps: any = to ? { to } : href ? { href } : {};
  const cssVars = useCssVariables({
    scope: classes.cardItemRoot,
    variables: {
      bgColorIconWrap: `var(--${backgroundList[color]})`,
      fgColorIcon: `var(--${foregroundList[color]})`,
    },
  });

  return (
    <Link
      className={cnj(
        classes.cardItemRoot,
        (to || href) && classes.rootHover,
        className
      )}
      // {...wrapperProps}
      href={to || href || ''}
    >
      {cssVars}
      <Flex className={classes.iconWrap}>
        <Icon
          color={foregroundList[color]}
          size={30}
          name={icon}
          type={iconType}
        />
      </Flex>
      <Flex className={classes.titleWrap}>
        <Typography
          font="500"
          size={16}
          height={20}
          color="coal"
          textAlign="left"
          mb={10}
        >
          {title}
        </Typography>
        <Typography
          font="400"
          size={13}
          height={17}
          color="gray"
          textAlign="left"
        >
          {subTitle}
        </Typography>
      </Flex>
    </Link>
  );
};

export default CardItem;
