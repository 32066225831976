import React from 'react';
import partial from 'lodash/partial';
import cnj from '../utils/cnj';
import TextInput from '../TextInput';
import Button from '../Button';
import IconButton from '../Button/IconButton';
import useTheme from '../utils/useTheme';
import Flex from '../Flex';
import classes from './index.module.scss';

interface Props {
  onChange?: Function;
  value?: Array<string>;
  label?: string;
  helperText?: string;
  limit?: number;
  isFocus?: boolean | number;
  onFocus?: Function;
  onBlur?: Function;
  error?: any;
  btnClassName?: string;
  inputClassName?: string;
  addButtonLabel: string;
}

const Links: React.FC<Props> = ({
  onChange,
  onFocus,
  onBlur,
  isFocus,
  value = [],
  label,
  helperText,
  limit = 10,
  error,
  btnClassName,
  inputClassName,
  addButtonLabel,
}) => {
  const { isDark } = useTheme();

  const onChangeInput = (e: any, index: number) => {
    const input = e.target.value;
    onChange?.(value.map((text, i) => (i === index ? input : text)));
  };

  const handleAnother = () => {
    if (value?.length < limit) {
      onChange?.([...value, '']);
    }
  };

  const deleteHandler = (itemIndex: number) => {
    onChange?.(value.filter((_, index) => index !== itemIndex));
  };

  const onFocusHandler = (index: any) => onFocus?.(index);

  return (
    <Flex key={value?.length}>
      {value.map((item, index) => (
        <TextInput
          key={`${item}_${index}`}
          onChange={(e: any) => onChangeInput(e, index)}
          value={item}
          // @ts-ignore
          onFocus={() => onFocusHandler(index)}
          // @ts-ignore
          isFocus={isFocus === index}
          onBlur={onBlur}
          style={index !== 0 && cnj(classes.input, inputClassName)}
          label={label}
          helperText={index === 0 ? helperText : undefined}
          error={
            error?.length > 0 && isFocus !== index ? error[index] : undefined
          }
          rightIcon={
            <IconButton
              onClick={partial(deleteHandler, index)}
              type="far"
              name="trash"
              size="md"
            />
          }
        />
      ))}
      <Button
        className={cnj(classes.btn, btnClassName)}
        leftIcon="plus"
        labelFont="bold"
        schema={isDark ? 'secondary-dark' : 'semi-transparent'}
        label={addButtonLabel}
        onClick={handleAnother}
      />
    </Flex>
  );
};

export default Links;
