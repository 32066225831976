import classes from './SkillPicker.component.module.scss';

import React, { useState } from 'react';
import {
  uuId,
  skillNormalizer,
  useStateCallback,
  useTranslation,
} from '@lobox/utils';
import type { IHardSkill } from '@lobox/utils';
import SkillList from './SkillList.component';
import type { ModalFormProps } from '../type';
import IconButton from '../Button/IconButton';
import useMedia from '../utils/useMedia';
import ModalSkillPicker from './skillPicker.modal';
import SkillPickerInputs from './skillPicker.inputs';
import type { StateSkill } from './types';
import AlertMessage from '../AlertMessage';

const MAXIMUM_LIMIT = 10;

interface SkillPickerProps {
  onChange: (e: any) => void;
  value: any;
  modalFormProps?: Partial<ModalFormProps>;
}

const SkillPicker = ({
  onChange,
  value = [],
  modalFormProps = {},
}: SkillPickerProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useState(false);
  const [skillInputValues, setSkillInputValues] = useState<StateSkill[]>([]);
  const [initialValues, setInitialValues] = useStateCallback();
  const { isMoreThanTablet } = useMedia();

  const onClose = () => {
    setInitialValues(null);
    toggleOpen(false);
  };

  const editHandler = (item: IHardSkill) => {
    setInitialValues(item, () => {
      toggleOpen(true);
    });
  };
  const deleteHandler = (item: IHardSkill) => {
    const updatedSkills = value?.filter((skill: any) =>
      item?.id
        ? skill?.id !== item.id && skill.label !== item.label
        : skill.label !== item.label
    );
    onChange(updatedSkills);
  };

  const addSkillHandler = () => {
    if (reachMaxError) {
      return;
    }

    if (isMoreThanTablet) {
      setSkillInputValues([
        ...skillInputValues,
        {
          id: uuId().create(),
          name: {},
          level: { value: 'BEGINNER', label: 'Beginner' },
        },
      ]);
    } else toggleOpen(true);
  };

  const onSuccess = ({ name, level }: any) => {
    const { progress, level: levelLabel } = skillNormalizer({
      level: level?.value,
    });
    const newSkill = {
      label: name?.label,
      progress,
      id: name?.value,
      skillLevel: level?.value,
      level: levelLabel,
      type: name?.type,
    };
    if (initialValues) {
      onChange(
        value?.map((item: IHardSkill) =>
          item.id === initialValues?.id ? newSkill : item
        )
      );
    } else {
      onChange([...value, newSkill]);
    }
    toggleOpen(false);
    setInitialValues(null);
  };

  const reachMaxError =
    value?.length + skillInputValues?.length === MAXIMUM_LIMIT;

  return (
    <>
      {value?.length ? (
        <SkillList
          skills={value}
          visibleAction
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          initialValues={isMoreThanTablet ? initialValues : null}
          onSuccess={onSuccess}
        />
      ) : null}
      {isMoreThanTablet && (
        <SkillPickerInputs
          setSkillInputValues={setSkillInputValues}
          skillInputValues={skillInputValues}
          onSuccess={onSuccess}
        />
      )}
      {!isMoreThanTablet && isOpen && (
        <ModalSkillPicker
          modalFormProps={modalFormProps}
          onSuccess={onSuccess}
          onClose={onClose}
          initialValues={{
            name: {
              label: initialValues?.label,
              value: initialValues?.id,
            },
            level: {
              label: initialValues?.level,
              value: initialValues?.skillLevel,
              progress: initialValues?.progress,
            },
          }}
        />
      )}

      {reachMaxError && (
        <AlertMessage
          title={t('reach_max_error')}
          type="warning"
          closeAble={false}
          className={classes.alert}
        />
      )}

      <IconButton
        className={classes.skillBtn}
        type="far"
        size="md15"
        name="plus"
        colorSchema="backgroundIconSecondary"
        onClick={addSkillHandler}
        disabled={reachMaxError}
      />
    </>
  );
};

export default SkillPicker;
