'use client';

import React from 'react';
import PrimeHeader from './partials/Prime.Header';
import PrimeSocial from './partials/Prime.Social';
import PrimeGlobe from './partials/Prime.Globe';
import PrimeJobBoard from './partials/Prime.JobBoard';
import PrimeFooter from './partials/Prime.Footer';

const PrimePage = (): JSX.Element => {
  return (
    <>
      <PrimeHeader />
      <PrimeSocial />
      <PrimeGlobe />
      <PrimeJobBoard />
      <PrimeFooter />
    </>
  );
};

export default PrimePage;
