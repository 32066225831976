import React from 'react';

const FindEvents: React.FC = () => (
  <svg
    width={81}
    height={80}
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={40.667} cy={40} r={40} fill="#F1F4F9" fillOpacity={0.5} />
    <path
      d="M56.799 59.662H23.535c-1.032 0-1.868-1.027-1.868-2.294V32.162h37v25.206c0 1.267-.837 2.294-1.868 2.294z"
      fill="#3F73E3"
    />
    <path
      d="M41.134 37.165l1.376 2.507c.157.288.418.493.72.566l2.63.64c.834.202 1.162 1.295.599 1.989l-1.781 2.189a1.28 1.28 0 00-.275.915l.25 2.903c.08.919-.78 1.594-1.565 1.229l-2.476-1.154a1.047 1.047 0 00-.89 0l-2.476 1.154c-.785.365-1.645-.31-1.565-1.23l.25-2.902a1.28 1.28 0 00-.275-.915l-1.78-2.19c-.564-.693-.236-1.786.597-1.988l2.63-.64c.303-.073.563-.278.721-.566l1.376-2.507c.435-.794 1.498-.794 1.934 0z"
      fill="#fff"
    />
    <path
      d="M44.576 47.99c-.225.598-.88.943-1.489.659l-2.476-1.154a1.047 1.047 0 00-.89 0l-2.476 1.154c-.61.284-1.264-.06-1.489-.66l-.076.885c-.08.919.78 1.594 1.565 1.229l2.476-1.154c.284-.133.606-.133.89 0l2.477 1.154c.784.365 1.644-.31 1.564-1.23l-.076-.883z"
      fill="#3F73E3"
    />
    <path
      d="M56.799 24.321H23.535c-1.032 0-1.868.904-1.868 2.02v5.271h37v-5.271c0-1.116-.837-2.02-1.868-2.02z"
      fill="#64A2FF"
    />
    <path d="M21.667 30.158h37v1.454h-37v-1.454z" fill="#83B4FF" />
    <path
      d="M28.72 21c.815 0 1.477.715 1.477 1.598v4.687c0 .882-.662 1.598-1.478 1.598s-1.478-.716-1.478-1.598v-4.687c0-.883.662-1.598 1.478-1.598zM51.614 21c-.816 0-1.478.715-1.478 1.598v4.687c0 .882.662 1.598 1.478 1.598.817 0 1.478-.716 1.478-1.598v-4.687c0-.883-.661-1.598-1.478-1.598z"
      fill="#485363"
    />
    <path
      d="M28.72 26.179c-.817 0-1.479-.715-1.479-1.598v1.454c0 .882.662 1.598 1.478 1.598s1.478-.716 1.478-1.598v-1.454c0 .883-.662 1.598-1.478 1.598zM51.614 26.179c-.816 0-1.477-.715-1.477-1.598v1.454c0 .882.661 1.598 1.477 1.598.817 0 1.478-.716 1.478-1.598v-1.454c0 .883-.661 1.598-1.478 1.598z"
      fill="#072252"
    />
  </svg>
);

export default FindEvents;
