import * as React from 'react';
import useTheme from '../../utils/useTheme';

function SvgSkills(props: any) {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      width={295}
      height={191}
      viewBox="0 0 295 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.434 191s-42.876-18.489-41.903-54.581c.973-36.093 40.005-41.739 66.711-19.486 26.814 22.254 40.542 11.367 54.382-7.454 13.839-18.822 35.143-61.409 73.958-45.466 28.977 11.846 48.331 43.621 54.602 72.295 4.325 19.707-1.947 40.521-16.975 53.474-.541.443-.974.886-1.514 1.218H67.435z"
        fill="url(#SvgSkills0_linear)"
      />
      <path
        d="M20.264 76.691l-2.006 2.019M17.716 79.254l-2.006 2.02M15.71 76.691l2.006 2.019M18.258 79.254l2.006 2.02M123.645 120.748l-2.006 2.019M121.098 123.312l-2.006 2.018M119.092 120.748l2.006 2.019M121.639 123.312l2.006 2.018M31.058 41.67l-2.006 2.019M28.51 44.233l-2.006 2.019M26.504 41.67l2.006 2.019M29.052 44.233l2.006 2.019M3.898 58.524c0 .48-.414.897-.891.897a.914.914 0 01-.892-.897c0-.48.414-.897.892-.897.477 0 .891.416.891.897zM101.994 43.644a2.414 2.414 0 00.143-3.396 2.377 2.377 0 00-3.374-.144 2.414 2.414 0 00-.143 3.396 2.377 2.377 0 003.374.144zM126.669 89.347a.995.995 0 01-.987.994.995.995 0 01-.987-.993c0-.545.446-.994.987-.994.542 0 .987.449.987.993zM62.26 35.993a.995.995 0 01-.988.994.995.995 0 01-.987-.994c0-.544.446-.993.987-.993.541 0 .987.449.987.993zM4.466 111.424c0 .898-.732 1.635-1.623 1.635a1.636 1.636 0 01-1.624-1.635c0-.897.732-1.634 1.624-1.634.891 0 1.623.737 1.623 1.634zM262.754 38.094l1.605 2.612M264.611 41.41l1.606 2.612M267.43 39.472l-2.594 1.304M264.135 41.34l-2.594 1.304M285.527 62.816c-.118.466.214 1.005.714 1.16.5.156 1.035-.113 1.153-.579.117-.466-.214-1.005-.714-1.16-.5-.156-1.035.113-1.153.58zM233.247 21.962c-.133.529.223 1.109.79 1.285.567.177 1.143-.113 1.276-.641.134-.529-.223-1.109-.79-1.285-.566-.177-1.143.113-1.276.641zM261.406 85.878c-.219.87.367 1.823 1.301 2.114.933.29 1.881-.185 2.1-1.055.22-.87-.367-1.823-1.3-2.114-.934-.29-1.881.185-2.101 1.055z"
        stroke="#606060"
        strokeMiterlimit={10}
      />
      <path
        d="M209.992 190.082H38.547c-2.35 0-4.266-1.902-4.266-4.237s1.915-4.237 4.266-4.237h171.445c2.35 0 4.265 1.902 4.265 4.237s-1.915 4.237-4.265 4.237z"
        fill="url(#SvgSkills1_linear)"
      />
      <path
        d="M175.145 176.766H73.12c-2.306 0-4.134-1.883-4.134-4.16v-53.023c0-2.321 1.871-4.16 4.134-4.16h102.025c2.306 0 4.133 1.883 4.133 4.16v53.067c0 2.277-1.871 4.116-4.133 4.116z"
        fill="url(#SvgSkills2_linear)"
      />
      <path d="M176.146 118.751H72.076v54.599h104.07v-54.599z" fill="#fff" />
      <path
        d="M187.762 181.494H60.459v-2.277a2.44 2.44 0 012.436-2.452H185.37a2.44 2.44 0 012.437 2.452v2.277h-.044z"
        fill="#A5BBE0"
      />
      <path
        d="M132.463 179.13h-16.707a2.608 2.608 0 01-2.262-1.314l-.609-1.051h22.537l-.609 1.051a2.75 2.75 0 01-2.35 1.314z"
        fill="#D3E2FB"
      />
      <path
        opacity={0.2}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.955 149.794h-4.35v4.379h4.35v-4.379zm0 5.517h-4.35v4.378h4.35v-4.378zm-4.35 5.561h4.35v4.378h-4.35v-4.378zm9.31-11.078h-3.61v3.634h3.61v-3.634zm-3.611 4.641h3.61v3.634h-3.61v-3.634zm3.61 4.598h-3.61v3.634h3.61v-3.634zm9.05-9.239h4.351v4.379h-4.351v-4.379zm4.351 5.517h-4.351v4.378h4.351v-4.378zm-4.351 5.561h4.351v4.378h-4.351v-4.378zm50.296-11.078h-4.35v4.379h4.35v-4.379zm-4.352 5.517h4.351v4.378h-4.351v-4.378zm4.351 5.561h-4.351v4.378h4.351v-4.378zm10.051-11.078h4.351v4.379h-4.351v-4.379zm4.351 5.517h-4.35v4.378h4.35v-4.378zm-4.35 5.561h4.35v4.378h-4.35v-4.378zm-21.581-11.078h-4.351v4.379h4.351v-4.379zm-4.351 5.517h4.351v4.378h-4.351v-4.378zm4.351 5.561h-4.351v4.378h4.351v-4.378zm-28.715-11.078h3.611v3.634h-3.611v-3.634zm3.611 4.641h-3.611v3.634h3.611v-3.634zm-3.611 4.598h3.611v3.634h-3.611v-3.634zm15.793-9.239h-3.611v3.634h3.611v-3.634zm-3.611 4.641h3.611v3.634h-3.611v-3.634zm3.611 4.598h-3.611v3.634h3.611v-3.634zm27.106-9.239h3.611v3.634h-3.611v-3.634zm3.611 4.641h-3.611v3.634h3.611v-3.634zm-3.611 4.598h3.611v3.634h-3.611v-3.634z"
        fill="#FDFDFD"
      />
      <path
        d="M165.661 94.275H82.605v54.161h83.056V94.275z"
        fill="url(#SvgSkills3_linear)"
      />
      <path
        d="M162.311 97.69H85.998v47.331h76.313v-47.33z"
        stroke="#D9DFE8"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M107.59 110.978c0 .747-.133 1.301-.4 1.661-.266.358-.685.536-1.256.536-.572 0-.999-.19-1.284-.571-.281-.384-.421-.935-.421-1.652v-3.371c0-1.151.301-1.871.905-2.158a1.88 1.88 0 01.808-.158c1.087 0 1.631.686 1.631 2.057v1.063h-1.138v-.989c0-.398-.034-.682-.102-.852-.067-.173-.199-.259-.395-.259a.41.41 0 00-.409.272c-.076.182-.114.46-.114.835v3.612c0 .416.037.714.11.892a.399.399 0 00.4.269.4.4 0 00.4-.273c.073-.182.11-.482.11-.901v-1.107h1.155v1.094zm.828 2.109v-7.734h2.593v1.015h-1.416v2.25h1.345v.993h-1.345v2.461h1.468v1.015h-2.645zm5.006-3.634l-.378-.017v3.651h-1.177v-7.734h1.946c.405 0 .707.082.906.246.199.161.329.388.391.681.064.29.097.671.097 1.143 0 .469-.05.826-.15 1.072a.835.835 0 01-.554.497c.267.047.453.227.558.54.106.314.159.967.159 1.96 0 .99.014 1.522.044 1.595h-1.178c-.029-.058-.044-.983-.044-2.773 0-.342-.041-.569-.123-.681-.082-.114-.248-.174-.497-.18zm-.373-.932h.47c.149 0 .259-.033.33-.101.073-.067.123-.183.149-.347.029-.164.044-.414.044-.751v-.049c0-.419-.035-.692-.106-.821-.07-.129-.232-.194-.487-.194h-.4v2.263zm3.517 4.566v-6.714h-.906v-1.02h2.949v1.02h-.87v6.714h-1.173zm2.607 0v-7.734h1.178v7.734h-1.178zm2.242 0v-7.734h2.655v1.024h-1.477v2.057h1.371v1.028h-1.371v3.625h-1.178zm3.412 0v-7.734h1.177v7.734h-1.177zm5.512-2.109c0 .747-.134 1.301-.4 1.661-.267.358-.686.536-1.257.536-.571 0-.999-.19-1.283-.571-.282-.384-.422-.935-.422-1.652v-3.371c0-1.151.302-1.871.905-2.158.223-.105.492-.158.809-.158 1.087 0 1.63.686 1.63 2.057v1.063h-1.138v-.989c0-.398-.034-.682-.101-.852-.068-.173-.199-.259-.396-.259a.409.409 0 00-.408.272c-.077.182-.115.46-.115.835v3.612c0 .416.037.714.11.892a.4.4 0 00.4.269.4.4 0 00.4-.273c.073-.182.11-.482.11-.901v-1.107h1.156v1.094zm2.901-.215l-.518-3.885h-.018l-.474 3.885h1.01zm-2.426 2.324l1.104-7.734h1.555l1.174 7.734h-1.099l-.185-1.379h-1.248l-.167 1.379h-1.134zm4.814 0v-6.714h-.906v-1.02h2.949v1.02h-.87v6.714h-1.173zm2.563 0v-7.734h2.593v1.015h-1.415v2.25h1.344v.993h-1.344v2.461h1.467v1.015h-2.645z"
        fill="#5E7BA9"
      />
      <path
        d="M104.707 117.393h38.852M104.707 119.451h38.852M104.707 121.509h38.852M104.707 123.567h38.852"
        stroke="#4C6A9A"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M105.229 135.914h15.575M127.461 135.914h15.576"
        stroke="#203D75"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M159.699 136.965c0 .7-.87 1.27-1.044 1.883-.217.656.174 1.62-.217 2.145-.392.569-1.436.482-1.958.876s-.783 1.401-1.436 1.62c-.609.219-1.392-.438-2.088-.438-.697 0-1.48.657-2.089.438-.652-.219-.913-1.226-1.436-1.62-.565-.394-1.566-.351-1.957-.876-.392-.525-.044-1.489-.218-2.145-.217-.613-1.044-1.183-1.044-1.883 0-.701.87-1.27 1.044-1.883.218-.657-.174-1.62.218-2.145.391-.57 1.435-.482 1.957-.876.523-.394.784-1.401 1.436-1.62.609-.219 1.392.438 2.089.438.696 0 1.479-.657 2.088-.438.653.219.914 1.226 1.436 1.62.565.394 1.566.35 1.958.876.391.525.043 1.488.217 2.145.218.657 1.044 1.182 1.044 1.883z"
        fill="url(#SvgSkills4_linear)"
      />
      <path
        d="M156.22 151.808l-3.307-1.971-3.307 1.971V139.46h6.614v12.348z"
        fill="url(#SvgSkills5_linear)"
      />
      <path
        d="M157.502 137.73c.411-2.554-1.314-4.96-3.852-5.373-2.538-.413-4.929 1.322-5.339 3.877-.411 2.554 1.314 4.959 3.852 5.373 2.538.413 4.929-1.323 5.339-3.877z"
        fill="#fff"
      />
      <path
        d="M80.995 169.497H42.578v11.997h38.417v-11.997z"
        fill="url(#SvgSkills6_linear)"
      />
      <path
        d="M106.752 169.497H80.995v2.54h25.757v-2.54z"
        fill="url(#SvgSkills7_linear)"
      />
      <path
        d="M106.752 178.954H80.995v2.54h25.757v-2.54z"
        fill="url(#SvgSkills8_linear)"
      />
      <path d="M105.794 172.036H80.995v6.918h24.799v-6.918z" fill="#FDFDFD" />
      <path
        d="M195.51 50.39l-36.415 13.705c-.827.307-.827 1.533 0 1.84l37.764 14.185 37.765-14.186c.827-.306.827-1.532 0-1.839l-36.416-13.704a3.596 3.596 0 00-2.698 0z"
        fill="url(#SvgSkills9_linear)"
      />
      <path
        d="M196.86 83.755c8.658 0 16.489 1.576 22.189 4.116V65.014s-4.873-4.247-22.189-4.247-22.189 4.247-22.189 4.247V87.87c5.699-2.54 13.531-4.116 22.189-4.116z"
        fill="url(#SvgSkills10_linear)"
      />
      <path
        d="M196.86 92.95c12.254 0 22.189-2.275 22.189-5.08s-9.935-5.079-22.189-5.079c-12.255 0-22.189 2.274-22.189 5.08 0 2.804 9.934 5.078 22.189 5.078z"
        fill="#345486"
      />
      <path
        d="M210.216 54.9c.826-2.233 5.003-2.67 7.048-1.751 9.746 4.335 6.831 17.645 13.574 24.344 2.306 2.277 5.352 3.853 8.615 4.204"
        stroke="url(#SvgSkills11_linear)"
        strokeWidth={3.959}
        strokeMiterlimit={10}
      />
      <path
        d="M240.323 83.842c1.01 0 1.828-.823 1.828-1.839a1.833 1.833 0 00-1.828-1.839 1.833 1.833 0 00-1.827 1.84c0 1.015.818 1.838 1.827 1.838z"
        fill="#D5E5FB"
      />
      <path
        d="M240.889 81.171c.783.132 1.566-.262 2.349-.569 2.132-.876 4.525-.92 6.7-.219M240.672 81.828c.652.438 1.523.263 2.306.044 1.523-.394 3.045-.7 4.612-.613"
        stroke="#DDE6F2"
        strokeWidth={1.485}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M239.802 82.003c.174.482.609.788 1.044.963.479.175.957.175 1.436.175 1.261-.087 2.436-.437 3.698-.7 1.218-.263 2.523-.35 3.698.087M240.106 83.01c.392.745 1.175 1.226 2.045 1.358.827.13 1.697.043 2.524-.088.826-.088 1.697-.175 2.523 0"
        stroke="#DDE6F2"
        strokeWidth={1.485}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="SvgSkills0_linear"
          x1={151.019}
          y1={60.511}
          x2={151.019}
          y2={191}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="SvgSkills1_linear"
          x1={124.269}
          y1={181.608}
          x2={124.269}
          y2={190.082}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills2_linear"
          x1={124.132}
          y1={115.423}
          x2={124.132}
          y2={176.766}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills3_linear"
          x1={124.133}
          y1={94.275}
          x2={124.133}
          y2={148.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F1F6FC" />
        </linearGradient>
        <linearGradient
          id="SvgSkills4_linear"
          x1={152.956}
          y1={130.396}
          x2={152.956}
          y2={143.533}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills5_linear"
          x1={152.913}
          y1={139.46}
          x2={152.913}
          y2={151.808}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills6_linear"
          x1={45.341}
          y1={181.717}
          x2={70.433}
          y2={181.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills7_linear"
          x1={82.847}
          y1={172.084}
          x2={99.669}
          y2={172.261}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills8_linear"
          x1={82.847}
          y1={181.541}
          x2={99.669}
          y2={181.719}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills9_linear"
          x1={196.859}
          y1={50.128}
          x2={196.859}
          y2={80.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C79A8" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills10_linear"
          x1={196.86}
          y1={60.768}
          x2={196.86}
          y2={87.871}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills11_linear"
          x1={224.834}
          y1={52.697}
          x2={237.016}
          y2={81.849}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7893BE" />
          <stop offset={1} stopColor="#5774A3" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={295}
      height={191}
      viewBox="0 0 295 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.434 191s-42.876-18.489-41.903-54.581c.973-36.093 40.005-41.739 66.711-19.486 26.814 22.254 40.542 11.367 54.382-7.454 13.839-18.822 35.143-61.409 73.958-45.466 28.977 11.846 48.331 43.621 54.602 72.295 4.325 19.707-1.947 40.521-16.975 53.474-.541.443-.974.886-1.514 1.218H67.435z"
        fill="url(#SvgSkills0_linear)"
      />
      <path
        d="M20.264 76.691l-2.006 2.019M17.716 79.254l-2.006 2.02M15.71 76.691l2.006 2.019M18.258 79.254l2.006 2.02M123.645 120.748l-2.006 2.019M121.098 123.312l-2.006 2.018M119.092 120.748l2.006 2.019M121.639 123.312l2.006 2.018M31.058 41.67l-2.006 2.019M28.51 44.233l-2.006 2.019M26.504 41.67l2.006 2.019M29.052 44.233l2.006 2.019M3.898 58.524c0 .48-.414.897-.891.897a.914.914 0 01-.892-.897c0-.48.414-.897.892-.897.477 0 .891.416.891.897zM101.994 43.644a2.414 2.414 0 00.143-3.396 2.377 2.377 0 00-3.374-.144 2.414 2.414 0 00-.143 3.396 2.377 2.377 0 003.374.144zM126.669 89.347a.995.995 0 01-.987.994.995.995 0 01-.987-.993c0-.545.446-.994.987-.994.542 0 .987.449.987.993zM62.26 35.993a.995.995 0 01-.988.994.995.995 0 01-.987-.994c0-.544.446-.993.987-.993.541 0 .987.449.987.993zM4.466 111.424c0 .898-.732 1.635-1.623 1.635a1.636 1.636 0 01-1.624-1.635c0-.897.732-1.634 1.624-1.634.891 0 1.623.737 1.623 1.634zM262.754 38.094l1.605 2.612M264.611 41.41l1.606 2.612M267.43 39.472l-2.594 1.304M264.135 41.34l-2.594 1.304M285.527 62.816c-.118.466.214 1.005.714 1.16.5.156 1.035-.113 1.153-.579.117-.466-.214-1.005-.714-1.16-.5-.156-1.035.113-1.153.58zM233.247 21.962c-.133.529.223 1.109.79 1.285.567.177 1.143-.113 1.276-.641.134-.529-.223-1.109-.79-1.285-.566-.177-1.143.113-1.276.641zM261.406 85.878c-.219.87.367 1.823 1.301 2.114.933.29 1.881-.185 2.1-1.055.22-.87-.367-1.823-1.3-2.114-.934-.29-1.881.185-2.101 1.055z"
        stroke="#5384EE"
        strokeOpacity={0.2}
        strokeMiterlimit={10}
      />
      <path
        d="M209.992 190.082H38.547c-2.35 0-4.266-1.902-4.266-4.237s1.915-4.237 4.266-4.237h171.445c2.35 0 4.265 1.902 4.265 4.237s-1.915 4.237-4.265 4.237z"
        fill="url(#SvgSkills1_linear)"
      />
      <path
        d="M175.145 176.766H73.12c-2.306 0-4.134-1.883-4.134-4.16v-53.023c0-2.321 1.871-4.16 4.134-4.16h102.025c2.306 0 4.133 1.883 4.133 4.16v53.067c0 2.277-1.871 4.116-4.133 4.116z"
        fill="url(#SvgSkills2_linear)"
      />
      <path d="M176.146 118.751H72.076v54.599h104.07v-54.599z" fill="#fff" />
      <path
        d="M187.762 181.494H60.459v-2.277a2.44 2.44 0 012.436-2.452H185.37a2.44 2.44 0 012.437 2.452v2.277h-.044z"
        fill="#A5BBE0"
      />
      <path
        d="M132.463 179.13h-16.707a2.608 2.608 0 01-2.262-1.314l-.609-1.051h22.537l-.609 1.051a2.75 2.75 0 01-2.35 1.314z"
        fill="#D3E2FB"
      />
      <path
        opacity={0.2}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.955 149.794h-4.35v4.379h4.35v-4.379zm0 5.517h-4.35v4.378h4.35v-4.378zm-4.35 5.561h4.35v4.378h-4.35v-4.378zm9.31-11.078h-3.61v3.634h3.61v-3.634zm-3.611 4.641h3.61v3.634h-3.61v-3.634zm3.61 4.598h-3.61v3.634h3.61v-3.634zm9.05-9.239h4.351v4.379h-4.351v-4.379zm4.351 5.517h-4.351v4.378h4.351v-4.378zm-4.351 5.561h4.351v4.378h-4.351v-4.378zm50.296-11.078h-4.35v4.379h4.35v-4.379zm-4.352 5.517h4.351v4.378h-4.351v-4.378zm4.351 5.561h-4.351v4.378h4.351v-4.378zm10.051-11.078h4.351v4.379h-4.351v-4.379zm4.351 5.517h-4.35v4.378h4.35v-4.378zm-4.35 5.561h4.35v4.378h-4.35v-4.378zm-21.581-11.078h-4.351v4.379h4.351v-4.379zm-4.351 5.517h4.351v4.378h-4.351v-4.378zm4.351 5.561h-4.351v4.378h4.351v-4.378zm-28.715-11.078h3.611v3.634h-3.611v-3.634zm3.611 4.641h-3.611v3.634h3.611v-3.634zm-3.611 4.598h3.611v3.634h-3.611v-3.634zm15.793-9.239h-3.611v3.634h3.611v-3.634zm-3.611 4.641h3.611v3.634h-3.611v-3.634zm3.611 4.598h-3.611v3.634h3.611v-3.634zm27.106-9.239h3.611v3.634h-3.611v-3.634zm3.611 4.641h-3.611v3.634h3.611v-3.634zm-3.611 4.598h3.611v3.634h-3.611v-3.634z"
        fill="#FDFDFD"
      />
      <path
        d="M165.661 94.275H82.605v54.161h83.056V94.275z"
        fill="url(#SvgSkills3_linear)"
      />
      <path
        d="M162.311 97.69H85.998v47.331h76.313v-47.33z"
        stroke="#D9DFE8"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M107.59 110.978c0 .747-.133 1.301-.4 1.661-.266.358-.685.536-1.256.536-.572 0-.999-.19-1.284-.571-.281-.384-.421-.935-.421-1.652v-3.371c0-1.151.301-1.871.905-2.158a1.88 1.88 0 01.808-.158c1.087 0 1.631.686 1.631 2.057v1.063h-1.138v-.989c0-.398-.034-.682-.102-.852-.067-.173-.199-.259-.395-.259a.41.41 0 00-.409.272c-.076.182-.114.46-.114.835v3.612c0 .416.037.714.11.892a.399.399 0 00.4.269.4.4 0 00.4-.273c.073-.182.11-.482.11-.901v-1.107h1.155v1.094zm.828 2.109v-7.734h2.593v1.015h-1.416v2.25h1.345v.993h-1.345v2.461h1.468v1.015h-2.645zm5.006-3.634l-.378-.017v3.651h-1.177v-7.734h1.946c.405 0 .707.082.906.246.199.161.329.388.391.681.064.29.097.671.097 1.143 0 .469-.05.826-.15 1.072a.835.835 0 01-.554.497c.267.047.453.227.558.54.106.314.159.967.159 1.96 0 .99.014 1.522.044 1.595h-1.178c-.029-.058-.044-.983-.044-2.773 0-.342-.041-.569-.123-.681-.082-.114-.248-.174-.497-.18zm-.373-.932h.47c.149 0 .259-.033.33-.101.073-.067.123-.183.149-.347.029-.164.044-.414.044-.751v-.049c0-.419-.035-.692-.106-.821-.07-.129-.232-.194-.487-.194h-.4v2.263zm3.517 4.566v-6.714h-.906v-1.02h2.949v1.02h-.87v6.714h-1.173zm2.607 0v-7.734h1.178v7.734h-1.178zm2.242 0v-7.734h2.655v1.024h-1.477v2.057h1.371v1.028h-1.371v3.625h-1.178zm3.412 0v-7.734h1.177v7.734h-1.177zm5.512-2.109c0 .747-.134 1.301-.4 1.661-.267.358-.686.536-1.257.536-.571 0-.999-.19-1.283-.571-.282-.384-.422-.935-.422-1.652v-3.371c0-1.151.302-1.871.905-2.158.223-.105.492-.158.809-.158 1.087 0 1.63.686 1.63 2.057v1.063h-1.138v-.989c0-.398-.034-.682-.101-.852-.068-.173-.199-.259-.396-.259a.409.409 0 00-.408.272c-.077.182-.115.46-.115.835v3.612c0 .416.037.714.11.892a.4.4 0 00.4.269.4.4 0 00.4-.273c.073-.182.11-.482.11-.901v-1.107h1.156v1.094zm2.901-.215l-.518-3.885h-.018l-.474 3.885h1.01zm-2.426 2.324l1.104-7.734h1.555l1.174 7.734h-1.099l-.185-1.379h-1.248l-.167 1.379h-1.134zm4.814 0v-6.714h-.906v-1.02h2.949v1.02h-.87v6.714h-1.173zm2.563 0v-7.734h2.593v1.015h-1.415v2.25h1.344v.993h-1.344v2.461h1.467v1.015h-2.645z"
        fill="#5E7BA9"
      />
      <path
        d="M104.707 117.393h38.852M104.707 119.451h38.852M104.707 121.509h38.852M104.707 123.567h38.852"
        stroke="#4C6A9A"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M105.229 135.914h15.575M127.461 135.914h15.576"
        stroke="#203D75"
        strokeWidth={1.071}
        strokeMiterlimit={10}
      />
      <path
        d="M159.699 136.965c0 .7-.87 1.27-1.044 1.883-.217.656.174 1.62-.217 2.145-.392.569-1.436.482-1.958.876s-.783 1.401-1.436 1.62c-.609.219-1.392-.438-2.088-.438-.697 0-1.48.657-2.089.438-.652-.219-.913-1.226-1.436-1.62-.565-.394-1.566-.351-1.957-.876-.392-.525-.044-1.489-.218-2.145-.217-.613-1.044-1.183-1.044-1.883 0-.701.87-1.27 1.044-1.883.218-.657-.174-1.62.218-2.145.391-.57 1.435-.482 1.957-.876.523-.394.784-1.401 1.436-1.62.609-.219 1.392.438 2.089.438.696 0 1.479-.657 2.088-.438.653.219.914 1.226 1.436 1.62.565.394 1.566.35 1.958.876.391.525.043 1.488.217 2.145.218.657 1.044 1.182 1.044 1.883z"
        fill="url(#SvgSkills4_linear)"
      />
      <path
        d="M156.22 151.808l-3.307-1.971-3.307 1.971V139.46h6.614v12.348z"
        fill="url(#SvgSkills5_linear)"
      />
      <path
        d="M157.502 137.73c.411-2.554-1.314-4.96-3.852-5.373-2.538-.413-4.929 1.322-5.339 3.877-.411 2.554 1.314 4.959 3.852 5.373 2.538.413 4.929-1.323 5.339-3.877z"
        fill="#fff"
      />
      <path
        d="M80.995 169.497H42.578v11.997h38.417v-11.997z"
        fill="url(#SvgSkills6_linear)"
      />
      <path
        d="M106.752 169.497H80.995v2.54h25.757v-2.54z"
        fill="url(#SvgSkills7_linear)"
      />
      <path
        d="M106.752 178.954H80.995v2.54h25.757v-2.54z"
        fill="url(#SvgSkills8_linear)"
      />
      <path d="M105.794 172.036H80.995v6.918h24.799v-6.918z" fill="#FDFDFD" />
      <path
        d="M195.51 50.39l-36.415 13.705c-.827.307-.827 1.533 0 1.84l37.764 14.185 37.765-14.186c.827-.306.827-1.532 0-1.839l-36.416-13.704a3.596 3.596 0 00-2.698 0z"
        fill="url(#SvgSkills9_linear)"
      />
      <path
        d="M196.86 83.755c8.658 0 16.489 1.576 22.189 4.116V65.014s-4.873-4.247-22.189-4.247-22.189 4.247-22.189 4.247V87.87c5.699-2.54 13.531-4.116 22.189-4.116z"
        fill="url(#SvgSkills10_linear)"
      />
      <path
        d="M196.86 92.95c12.254 0 22.189-2.275 22.189-5.08s-9.935-5.079-22.189-5.079c-12.255 0-22.189 2.274-22.189 5.08 0 2.804 9.934 5.078 22.189 5.078z"
        fill="#345486"
      />
      <path
        d="M210.216 54.9c.826-2.233 5.003-2.67 7.048-1.751 9.746 4.335 6.831 17.645 13.574 24.344 2.306 2.277 5.352 3.853 8.615 4.204"
        stroke="url(#SvgSkills11_linear)"
        strokeWidth={3.959}
        strokeMiterlimit={10}
      />
      <path
        d="M240.323 83.842c1.01 0 1.828-.823 1.828-1.839a1.833 1.833 0 00-1.828-1.839 1.833 1.833 0 00-1.827 1.84c0 1.015.818 1.838 1.827 1.838z"
        fill="#D5E5FB"
      />
      <path
        d="M240.889 81.171c.783.132 1.566-.262 2.349-.569 2.132-.876 4.525-.92 6.7-.219M240.672 81.828c.652.438 1.523.263 2.306.044 1.523-.394 3.045-.7 4.612-.613"
        stroke="#DDE6F2"
        strokeWidth={1.485}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M239.802 82.003c.174.482.609.788 1.044.963.479.175.957.175 1.436.175 1.261-.087 2.436-.437 3.698-.7 1.218-.263 2.523-.35 3.698.087M240.106 83.01c.392.745 1.175 1.226 2.045 1.358.827.13 1.697.043 2.524-.088.826-.088 1.697-.175 2.523 0"
        stroke="#DDE6F2"
        strokeWidth={1.485}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="SvgSkills0_linear"
          x1={151.019}
          y1={58.855}
          x2={151.019}
          y2={189.091}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="SvgSkills1_linear"
          x1={124.269}
          y1={181.608}
          x2={124.269}
          y2={190.082}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills2_linear"
          x1={124.132}
          y1={115.423}
          x2={124.132}
          y2={176.766}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills3_linear"
          x1={124.133}
          y1={94.275}
          x2={124.133}
          y2={148.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F1F6FC" />
        </linearGradient>
        <linearGradient
          id="SvgSkills4_linear"
          x1={152.956}
          y1={130.396}
          x2={152.956}
          y2={143.533}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills5_linear"
          x1={152.913}
          y1={139.46}
          x2={152.913}
          y2={151.808}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills6_linear"
          x1={45.341}
          y1={181.717}
          x2={70.433}
          y2={181.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills7_linear"
          x1={82.847}
          y1={172.084}
          x2={99.669}
          y2={172.261}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills8_linear"
          x1={82.847}
          y1={181.541}
          x2={99.669}
          y2={181.719}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC8D8" />
          <stop offset={1} stopColor="#D9DFE8" />
        </linearGradient>
        <linearGradient
          id="SvgSkills9_linear"
          x1={196.859}
          y1={50.128}
          x2={196.859}
          y2={80.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C79A8" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills10_linear"
          x1={196.86}
          y1={60.768}
          x2={196.86}
          y2={87.871}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7994BF" />
          <stop offset={1} stopColor="#4B6999" />
        </linearGradient>
        <linearGradient
          id="SvgSkills11_linear"
          x1={224.834}
          y1={52.697}
          x2={237.016}
          y2={81.849}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7893BE" />
          <stop offset={1} stopColor="#5774A3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSkills;
