import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { landingRouteNames, useTranslation } from '@lobox/utils';
import FooterNavbar from '../FooterNavbar';
import classes from './AboutPage.Header.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

export interface AboutPageHeaderProps {
  className?: string;
  bgImage?: string;
  children?: React.ReactNode;
}

const AboutPageHeader: React.FC<AboutPageHeaderProps> = ({
  className,
  bgImage,
  children,
}) => {
  const { t } = useTranslation();
  const menuList = [
    { label: t('who_v_r'), to: landingRouteNames.aboutUs_whoWeAre },
    { label: t('team'), to: landingRouteNames.meetTheTeam },
    { label: t('career'), to: landingRouteNames.aboutUs_career },
  ];
  const cssVars = useCssVariables({
    scope: classes.aboutUsHeaderRoot,
    variables: { bgImageAbout: `url(${bgImage}) no-repeat center / cover` },
  });

  return (
    <Flex className={cnj(classes.aboutUsHeaderRoot, className)}>
      {cssVars}
      <Flex className={classes.overlay} />
      <FooterNavbar
        itemsList={menuList}
        specialButtonProps={{
          label: `${t('go_to')} lobox.com`,
          href: landingRouteNames.prime,
        }}
      />
      {!!children && (
        <Flex className={classes.childrenWrapper}>{children}</Flex>
      )}
    </Flex>
  );
};

export default AboutPageHeader;
