import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  Fragment,
} from 'react';
import partial from 'lodash/partial';
import get from 'lodash/get';
import {
  preventClickHandler,
  useOutsideListener,
  useTranslation,
} from '@lobox/utils';
import unionBy from 'lodash/unionBy';
import cnj from '../utils/cnj';
import TextInput from '../TextInput';
import Button from '../Button';
import useTheme from '../utils/useTheme';
import Flex from '../Flex';
import Ripple from '../Ripple';
import Tag from '../Tag';
import IconButton from '../Button/IconButton';
import classes from './AutoComplete.component.module.scss';
import BottomSheet from '../BottomSheet';
import Typography from '../Typography';
import FixedRightSideModal from '@shared/components/molecules/Modal/FixedRightSideModalDialog';
import { ModalHeaderSimple } from '@shared/components/molecules';
import { elementIsVisibleInViewport } from '@shared/utils-pkg/utils/elementIsVisibleInViewport';
import { createPortal } from 'react-dom';
import useMedia from '../utils/useMedia';
import { AUTO_COMPLETE_OPTIONS_ID } from '@shared/constants/enums';

interface StyleProps {
  root?: string;
  options?: string;
  rightIcon?: string;
}

export interface AutoCompleteProps {
  helperText?: string;
  label?: string;
  value?: any;
  options: Array<{ label: string; value?: any }>;
  onChangeInput?: Function;
  onBlur?: Function;
  onSelect?: Function;
  error?: string;
  style?: string;
  styles?: Partial<StyleProps>;
  disabled?: boolean;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  displayName?: string;
  isFocus?: boolean;
  onFocus?: Function;
  trim?: boolean;
  editable?: boolean;
  placeholder?: string;
  inputWrapClassName?: string;
  withRightIconClassName?: string;
  className?: string;
  inputStyle?: string;
  renderItem?: (args: any) => React.ReactNode;
  renderContent?: (args: any) => React.ReactNode;
  optionItemClassName?: string;
  visibleCheck?: boolean;
  translate?: (key: string) => string;
  isMulti?: boolean;
  limit?: number;
  name?: string;
  visibleRightIcon?: boolean;
  rightIconClassName?: string;
  rightIconProps?: any;
  maxLength?: number;
  autoComplete?: string;
  optionItemProps?: any;
  popperClassName?: string;
  variant?: 'simple' | 'form-input' | 'simple-large';
  visibleCharCounter?: boolean;
  cp?: string;
  doNotUseTranslation?: boolean;
  disabledReadOnly?: boolean;
  checkIsValid?: (value: string) => boolean;
  textInputProps?: Record<string, any>;
  optionsVariant?: 'dropdown' | 'modal' | 'bottomsheet' | 'none';
  onClick?: Function;
}
const AutoComplete = (props: AutoCompleteProps, inputRef: any) => {
  const {
    label,
    helperText,
    value,
    options = [],
    onChangeInput,
    onSelect,
    onBlur: parentOnBlur,
    onFocus,
    error,
    isFocus,
    styles,
    style,
    disabled,
    rightIcon,
    leftIcon,
    displayName,
    trim,
    editable,
    placeholder,
    inputWrapClassName,
    withRightIconClassName,
    className,
    inputStyle,
    renderItem,
    renderContent,
    optionItemClassName,
    visibleCheck = true,
    isMulti,
    limit = 3,
    visibleRightIcon,
    rightIconClassName,
    rightIconProps = {},
    optionItemProps = {},
    maxLength,
    autoComplete,
    popperClassName,
    variant = 'form-input',
    visibleCharCounter = false,
    cp,
    doNotUseTranslation,
    disabledReadOnly,
    checkIsValid,
    textInputProps = {},
    optionsVariant = 'dropdown',
    onClick: onClickProp,
  } = props;
  const { t: translate } = useTranslation();
  const { theme } = useTheme();
  const { isDark } = theme;
  const [show, setShow] = useState(false);
  const defaultDisplayName =
    displayName ||
    get(
      options.find((item) => item.value === value?.value),
      ['label']
    );
  const [textInputValue, setTextInputValue] = useState(defaultDisplayName);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const isSimpleVariant = variant === 'simple';
  const isSimpleLarge = variant === 'simple-large';
  const { isMoreThanTablet } = useMedia();

  useOutsideListener(
    wrapperRef.current as any,
    () => {
      if (optionsVariant !== 'dropdown') return;
      setTimeout(() => setShow(false), 0);
    },
    [AUTO_COMPLETE_OPTIONS_ID]
  );

  const t = (str: string): string =>
    cp === 'dropdownSelect' && !doNotUseTranslation ? translate(str) : str;

  const onChange = (e: any) => {
    const input = e?.target?.value;
    if (!!checkIsValid && !checkIsValid(input)) return;
    if (input !== undefined) {
      setShow(true);
      setTextInputValue(input);
      onChangeInput?.(input);
    }
    if (input === '') {
      setTextInputValue(undefined);
      if (!isMulti) {
        onSelect?.(undefined);
      }
    }
  };

  const onClickButton = (item: any) => {
    if (item?.noClick) return;
    if (isMulti) {
      setTextInputValue('');
      const isValueArray = Array.isArray(value);
      if (!isValueArray) {
        console.warn('isMulti value should be array', label, value);
      }
      const updateValue = unionBy(
        [item],
        isValueArray ? value : [],
        'value'
      ).slice(0, limit);
      onSelect?.(updateValue as any);
      setShow(false);
    } else {
      onSelect?.(item);
      setTextInputValue(item?.label);
      setTimeout(() => {
        setShow(false);
      }, 200);
    }
  };

  const onBlur = (e: any) => {
    parentOnBlur?.(e);
  };

  const removeHandler = (item: any) => {
    const updatedValue = value?.filter((i: any) => i.value !== item.value);
    onSelect?.(updatedValue);
  };

  const onClick = () => {
    onClickProp?.();
    setShow((prev) => !prev);
  };

  useEffect(() => {
    setTextInputValue(displayName);
  }, [displayName, value]);

  const onClickRightIcon = () => {
    setShow((prev) => !prev);
  };

  const onClickOptionsWrapper = (event: React.MouseEvent<any>) =>
    preventClickHandler(event);

  useEffect(() => {
    if (!isMoreThanTablet) return;
    const close = (e) => {
      if (optionsWrapperRef?.current?.contains?.(e.target)) return;
      setShow(false);
    };
    if (show) {
      window.addEventListener('scroll', close, true);
    } else {
      window.removeEventListener('scroll', close);
    }
    return () => {
      window.removeEventListener('scroll', close);
    };
  }, [show, isMoreThanTablet]);

  return (
    <Flex ref={wrapperRef} className={className}>
      {renderContent ? (
        renderContent({ isOpen: show, onClick })
      ) : (
        <TextInput
          {...{
            ref: inputRef,
            label,
            helperText,
            onBlur,
            onChange,
            value: t(textInputValue as string),
            error: show ? undefined : error,
            visibleCharCounter,
            disabled,
            rightIcon:
              rightIcon ||
              (visibleRightIcon ? (
                <IconButton
                  onClick={onClickRightIcon}
                  type="far"
                  name={
                    show && options.length > 0 ? 'chevron-up' : 'chevron-down'
                  }
                  size="md"
                  {...rightIconProps}
                />
              ) : undefined),
            leftIcon,
            style: cnj(classes.inputStyle, style),
            inputStyle: cnj(
              (isSimpleVariant || isSimpleLarge) && classes.simpleInputStyle,
              inputStyle
            ),
            inputWrapClassName: cnj(
              isSimpleVariant && classes.inputWrapClassName,
              isSimpleLarge && classes.isSimpleLarge,
              inputWrapClassName
            ),
            withRightIconClassName: cnj(
              (isSimpleVariant || isSimpleLarge) && classes.withRightIcon,
              isSimpleLarge && classes.simpleLarge,
              withRightIconClassName
            ),
            onClick,
            isFocus: typeof isFocus === 'boolean' ? isFocus : show,
            onFocus,
            trim,
            editable,
            placeholder,
            disabledRightIconPropagation: false,
            touched: show,
            rightIconClassName,
            maxLength,
            autoComplete,
            disabledReadOnly,
            ...textInputProps,
          }}
        />
      )}
      {show && (
        <>
          {optionsVariant === 'bottomsheet' ? (
            <BottomSheet
              open={show}
              onRequestClose={() => setShow(false)}
              modalElementClass={classes.bottomsheetwrapper}
            >
              <Flex className={classes.header}>
                <Typography size={16} height={19} font="700" color="smoke_coal">
                  {t(label || '')}
                </Typography>
              </Flex>
              {renderOptions({
                container: cnj(classes.optionsWrapper,classes.bottomSheetOptionsWrapper),
                optionBtn: classes.optionBtnBottomSheet,
              })}
            </BottomSheet>
          ) : optionsVariant === 'modal' ? (
            <FixedRightSideModal modalClassName={classes.modalRoot}>
              <ModalHeaderSimple
                visibleHeaderDivider
                title={t(label || '')}
                hideBack={false}
                backButtonProps={{
                  onClick: () => setShow(false),
                }}
                noCloseButton
              />
              <Flex className={classes.modalBodyContainer}>
                <Flex className={classes.modalInputWrapper}>
                  <AutoComplete {...props} optionsVariant="none" />
                </Flex>
                {renderOptions({
                  container: cnj(
                    classes.optionsWrapper,
                    classes.modalWrapperContainer
                  ),
                })}
              </Flex>
            </FixedRightSideModal>
          ) : optionsVariant === 'dropdown' ? (
            <>
              {renderOptions({
                option: cnj(
                  classes.options,
                  !!helperText && classes.optionsWithHelperText,
                  styles?.options
                ),
              })}
            </>
          ) : null}
        </>
      )}
      {isMulti && value?.length > 0 && (
        <Flex flexDir="row" className={classes.tagsWrapper}>
          {value?.map((item: any) => (
            <Tag
              className={classes.tag}
              key={item.value || item.label}
              onDelete={() => removeHandler(item)}
              realData={item}
              label={item.label}
              visibleIcon
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
  function renderOptions(classNames?: {
    option?: string;
    container?: string;
    optionBtn?: string;
  }) {
    function createPortalOrNot(nodes: ReactNode, parent: Element) {
      return isMoreThanTablet ? createPortal(nodes, parent) : nodes;
    }

    return (
      <Flex
        ref={(el: HTMLDivElement) => {
          if (optionsVariant !== 'dropdown' || !el) return;
          const container = el.querySelector('div');
          if (!container || elementIsVisibleInViewport(container)) return;
          container.style.top = 'unset';
          container.style.bottom = `${(wrapperRef.current?.clientHeight || 0) + 6}px`;
        }}
        className={cnj(
          classes.root,
          classes.show,
          classNames?.container,
          popperClassName
        )}
      >
        {options.length > 0 &&
          !!wrapperRef?.current &&
          createPortalOrNot(
            <Flex
              onClick={onClickOptionsWrapper}
              className={classNames?.option}
              ref={(el) => {
                if (!el || !isMoreThanTablet) return;
                optionsWrapperRef.current = el;
                setSpecs(optionsWrapperRef.current, wrapperRef?.current);
              }}
              id={AUTO_COMPLETE_OPTIONS_ID}
            >
              {options.map((item, index) => {
                const isSelected = value?.value === item.value;
                const schema = isSelected
                  ? 'primary-blue'
                  : isDark
                    ? 'black'
                    : 'ghost';
                return renderItem ? (
                  <Ripple
                    key={item.value}
                    onClick={() => onClickButton(item)}
                    // onLongPress={() => onClickButton(item)}
                    className={classes.ripple}
                  >
                    {renderItem({
                      item,
                      isSelected,
                      index,
                      text: textInputValue,
                    })}
                  </Ripple>
                ) : (
                  <Ripple
                    onClick={partial(onClickButton, item)}
                    // onLongPress={partial(onClickButton, item)}
                    key={item.value}
                    className={cnj(
                      classes.ripple,
                      isSelected && classes.borderRadius
                    )}
                  >
                    <Button
                      variant="large"
                      schema={schema}
                      key={schema}
                      label={t(item.label)}
                      labelProps={{
                        font: '400',
                        isTruncated: true,
                      }}
                      rightIcon={
                        isSelected && visibleCheck ? 'check' : undefined
                      }
                      rightColor="white"
                      rightIconClassName={classes.rightIcon}
                      {...optionItemProps}
                      className={cnj(
                        classes.optionBtn,
                        index !== 0 && !isSelected,
                        isSelected && classes.borderRadius,
                        classNames?.optionBtn,
                        optionItemClassName
                      )}
                    />
                  </Ripple>
                );
              })}
            </Flex>,
            document.body
          )}
      </Flex>
    );
  }
};

export default forwardRef<any, AutoCompleteProps>(AutoComplete);

function setSpecs(el: HTMLDivElement, parentElement: HTMLDivElement) {
  if (!el || !parentElement) return;
  const width = parentElement?.clientWidth;
  const height = parentElement?.clientHeight;
  const { top, left } = getElementOffset({
    parentElement,
    elementHeight: el?.clientHeight,
    parentHeight: height,
  });
  el.style.left = `${left}px`;
  el.style.top = `${top + height}px`;
  el.style.width = `${width}px`;
  el.style.zIndex = '5000';
}

const getElementOffset = ({
  parentElement,
  elementHeight,
  parentHeight,
}: {
  parentElement: HTMLDivElement;
  elementHeight: number;
  parentHeight: number;
}) => {
  const rect = parentElement.getBoundingClientRect();
  const scrollLeft = window.pageXOffset;
  const scrollTop = window.pageYOffset;

  let top = rect.top + scrollTop;
  const left = rect.left + scrollLeft;

  if (
    rect.top + scrollTop + parentHeight + elementHeight >
    window.innerHeight
  ) {
    // Should open up to top
    top = top - parentHeight - elementHeight;
  }

  return {
    top,
    left,
  };
};
