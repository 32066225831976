import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import HeroIcon from '@lobox/uikit/HeroIcon';
import IconButton from '@lobox/uikit/Button/IconButton';
import Link from '@lobox/uikit/Link';
import Typography from '@lobox/uikit/Typography';
import classes from './AccordionSubject.module.scss';

export interface AccordionSubjectProps {
  isActive?: boolean;
  className?: string;
  iconName: string;
  title: string;
  noChevron?: boolean;
  isExpanded?: boolean;
  to?: string;
}

const AccordionSubject: React.FC<AccordionSubjectProps> = ({
  isActive,
  className,
  iconName,
  title,
  noChevron,
  isExpanded,
  to,
}) => {
  const Wrapper = to ? Link : Flex;
  const wrapperProps = to ? { to } : {};

  return (
    // @ts-ignore
    <Wrapper
      {...wrapperProps}
      className={cnj(classes.accordionSubjectRoot, className)}
    >
      <HeroIcon
        iconName={iconName}
        color={isActive ? 'brand' : 'coal'}
        size={40}
        iconSize={20}
        iconType="fas"
      />
      <Typography
        font="700"
        size={16}
        height={20}
        color={isActive ? 'brand' : 'coal'}
        ml={12}
        noWrap
      >
        {title}
      </Typography>
      {!noChevron && (
        <IconButton
          name={isExpanded ? 'chevron-up' : 'chevron-down'}
          size="sm"
          className={cnj(classes.icon, isActive && classes.iconActive)}
          colorSchema="secondary"
        />
      )}
    </Wrapper>
  );
};

export default AccordionSubject;
