import classes from './index.module.scss';

import React from 'react';
import LinkedinIcon from '../shared/svg/LinkedinIcon';
import GoogleIcon from '../shared/svg/GoogleIcon';
import cnj from '../utils/cnj';
import Button from '../Button';
import Flex from '../Flex';
import DividerTitled from '../Divider/DividerTitled';

type SocialProps = {
  title: string;
  className?: string;
  onClick?: () => any;
};

interface AuthSocialIconsProps {
  title: string;
  google?: SocialProps;
  linkedin?: SocialProps;
  column?: boolean;
}
const AuthSocialIcons: React.FC<AuthSocialIconsProps> = ({
  title,
  google,
  linkedin,
  column,
}) => {
  return (
    <Flex
      className={cnj(
        column ? classes.authSocialIconsRootColumn : classes.authSocialIconsRoot
      )}
    >
      <DividerTitled label={title} className={cnj(classes.dividerTitled)} />
      <Flex className={cnj(column ? classes.btnWrapColumn : classes.btnWrap)}>
        <Button
          variant="large"
          leftSvg={<GoogleIcon className={classes.googleIcon} />}
          className={cnj(
            column ? classes.authBtnColumn : classes.authBtn,
            google?.className
          )}
          labelClassName={classes.labelStyle}
          schema="dark-primary"
          label={google?.title}
          labelFont="400"
          onClick={google?.onClick}
        />
        <Flex className={classes.spacer} />
        <Button
          leftSvg={<LinkedinIcon className={classes.linkedInIcon} />}
          className={cnj(
            column ? classes.authBtnColumn : classes.authBtn,
            column ? classes.btnLinkedInColumn : classes.btnLinkedIn,
            linkedin?.className
          )}
          labelClassName={classes.labelStyle}
          schema="dark-primary"
          label={linkedin?.title}
          variant="large"
          labelFont="400"
          onClick={linkedin?.onClick}
        />
      </Flex>
    </Flex>
  );
};

export default AuthSocialIcons;
