import React, { useState } from 'react';
import cnj from '../utils/cnj';
import IconButton from '../Button/IconButton';
import Typography from '../Typography';
import Flex from '../Flex';
import classes from './index.module.scss';

export type AlertType = 'error' | 'success' | 'warning';

interface AlertMessageProps {
  type: AlertType;
  title?: string;
  subTitle?: string;
  className?: string;
  onCloseClick?: () => void;
  variant?: 'default' | 'thin';
  closeAble?: boolean;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  title,
  subTitle,
  type,
  className,
  onCloseClick,
  variant = 'default',
  closeAble = true,
}) => {
  const [hide, setHide] = useState(false);

  const handleCloseClicked = () => {
    setHide(true);
    onCloseClick?.();
  };
  if (hide) {
    return null;
  }

  return (
    <Flex
      className={cnj(
        classes.root,
        variant !== 'default' && classes.rootNotDefault,
        className
      )}
    >
      <Typography font="bold" color="error">
        {title}
      </Typography>
      {subTitle && <Typography mt={10}>{subTitle}</Typography>}
      {closeAble && (
        <IconButton
          colorSchema="error"
          type="fas"
          name="times"
          size="sm"
          onClick={handleCloseClicked}
          className={cnj(
            classes.btn,
            variant !== 'default' && classes.btnNotDefault,
            type === 'error' && classes.btnError,
            type === 'success' && classes.btnSuccess,
            type === 'warning' && classes.btnWarning
          )}
        />
      )}
    </Flex>
  );
};

export default AlertMessage;
