import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Typography from '@lobox/uikit/Typography';
import Flex from '@lobox/uikit/Flex';
import Link from '@lobox/uikit/Link';
import { useAuthUser, landingRouteNames } from '@lobox/utils';
import classes from './Portrayal.module.scss';

interface PortrayalProps {
  className?: string;
  svg?: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const Portrayal: React.FC<PortrayalProps> = ({
  className,
  svg,
  title,
  subtitle,
}) => {
  const { data: user } = useAuthUser();

  return (
    <Link
      as={landingRouteNames.prime}
      to={!user ? landingRouteNames.signup : landingRouteNames.prime}
      className={cnj(classes.portrayalRoot, className)}
    >
      {svg}
      <Flex className={classes.textWrapper}>
        {title && (
          <Typography color="coal" font="500" mb={6} className={classes.first}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography color="graphene" font="400" className={classes.second}>
            {subtitle}
          </Typography>
        )}
      </Flex>
    </Link>
  );
};

export default Portrayal;
