import React from 'react';
import Button from '@lobox/uikit/Button';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { useAuthUser, useTranslation, landingRouteNames } from '@lobox/utils';
import { CardItem } from '../../../shared/components/molecules';
import classes from './Board.module.scss';

export interface BoardProps {
  className?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  list?: Array<{
    title: string;
    subTitle: React.ReactNode;
    icon: string;
    iconType: 'fas' | 'far' | 'fal';
    className?: string;
    direction?: 'column' | 'row';
    color: 'success' | 'warning' | 'brand';
    to?: string;
    href?: string;
  }>;
}

const Board: React.FC<BoardProps> = ({
  className,
  title,
  subtitle,
  description,
  list,
}) => {
  const { data: user } = useAuthUser();
  const link = user
    ? { href: landingRouteNames.prime }
    : { to: landingRouteNames.signup };
  const { t } = useTranslation();

  return (
    <Flex className={cnj(classes.boardRoot, className)}>
      <Flex className={classes.titleWrapper}>
        {title && (
          <Typography font="700" size={16} height={20} color="graphene_60">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            font="400"
            size={20}
            height={28}
            color="coal"
            mt={10}
            mb={20}
          >
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography font="300" size={16} height={28.8} color="coal">
            {description}
          </Typography>
        )}
      </Flex>
      <Flex className={classes.cardsWrapper}>
        {list?.map(
          ({ title: tit, subTitle: st, icon, iconType, color, to, href }) => (
            <CardItem
              key={tit + st}
              title={tit}
              subTitle={st}
              icon={icon}
              iconType={iconType}
              color={color}
              className={classes.simpleCard}
              to={to}
              href={href}
            />
          )
        )}
      </Flex>
      <Button
        className={classes.button}
        variant="large"
        label={!user ? t('signup') : `${t('go_to')} lobox.com`}
        {...link}
      />
    </Flex>
  );
};

export default Board;
