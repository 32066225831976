import * as React from 'react';

interface MouseScrollProps {
  className?: string;
}

const MouseScroll: React.FC<MouseScrollProps> = ({ className }) => (
  <svg
    width={22}
    height={61}
    viewBox="0 0 22 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect
      x={1}
      y={1.257}
      width={20}
      height={30}
      rx={10}
      stroke="#fff"
      strokeWidth={2}
    />
    <rect x={10} y={17.257} width={2} height={5} rx={1} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.271 36.572a1 1 0 111.458 1.37l-.638-.6.638.6-.06.063-.17.18a267.508 267.508 0 01-2.72 2.832c-1.646 1.688-3.67 3.712-5.117 4.99l-.705.622-8.664-8.665a1 1 0 111.414-1.414l7.327 7.327c1.305-1.208 2.926-2.834 4.313-4.256a261.702 261.702 0 002.699-2.81l.168-.177.057-.062zM18.271 50.944a1 1 0 111.458 1.37l-.638-.6.638.6-.06.063-.17.18-.627.66c-.53.557-1.267 1.325-2.094 2.172-1.645 1.687-3.668 3.712-5.116 4.99l-.705.621-8.664-8.664a1 1 0 111.414-1.414l7.327 7.327c1.305-1.208 2.926-2.834 4.313-4.256a261.702 261.702 0 002.699-2.81l.168-.178.057-.061z"
      fill="#fff"
    />
  </svg>
);

export default MouseScroll;
