import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useHistory } from '@lobox/utils';
import { preventClickHandler } from '@lobox/utils';
import classes from './AccordionItem.module.scss';

export interface AccordionItemProps {
  className?: string;
  label: string;
  to: string;
}

const AccordionItem = ({
  className,
  label,
  to,
}: AccordionItemProps): JSX.Element => {
  const history = useHistory();

  const handleClick = (e: any) => {
    preventClickHandler(e);

    history.push(to);
    if (to?.includes('#')) {
      const id = to.split('#')?.[1];
      const el = document.getElementById(id);
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'smooth' });
        }, 25);
      }
    }
  };

  return (
    <Flex
      as="a"
      href={to}
      onClick={handleClick}
      className={cnj(classes.accordionItemRoot, className)}
    >
      <Typography font="400" size={15} height={21} color="coal">
        {label}
      </Typography>
    </Flex>
  );
};

export default AccordionItem;
