import classes from './index.module.scss';
// @ts-nocheck

import React from 'react';
import BaseButton from '../Button/BaseButton';
import Flex from '../Flex';
import cnj from '../utils/cnj';

interface RippleProps {
  onClick: (arg: any) => void;
  isActive?: boolean;
  onLongPress?: (arg: any) => void;
  longPressConfig?: { shouldPreventDefault: boolean; delay: number };
  className?: string;
}

const Ripple: React.FC<RippleProps> = ({
  onClick,
  onLongPress,
  children,
  className,
  longPressConfig = {
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    detect: 'both',
  },
}) => {
  const [coords, setCoords] = React.useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState(false);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const clicked = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  // @ts-ignore
  // const longPress = useLongPress((e) => {
  //   clicked(e);
  //   onLongPress?.(e);
  // }, longPressConfig);

  const onClickHandler = (e: any) => {
    clicked(e);
    onClick(e);
  };

  return (
    <BaseButton
      className={cnj(classes.rippleWrapper, className)}
      onClick={onClickHandler}
    >
      {isRippling && (
        <Flex
          as="span"
          className={classes.ripple}
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      )}
      {children}
    </BaseButton>
  );
};

export default Ripple;
