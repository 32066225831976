import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Typography from '../Typography';
import type { TypographyProps } from '../Typography';
import classes from './Divider.titled.module.scss';

interface DividerProps {
  className?: string;
  label: string;
  labelProps?: TypographyProps;
}

const DividerTitled = ({
  className,
  label,
  labelProps,
}: DividerProps): JSX.Element => {
  return (
    <Flex className={cnj(classes.dividerTitledRoot, className)}>
      <Flex className={classes.divider} />
      <Typography
        font="400"
        color="graphene"
        size={15}
        className={classes.title}
        {...labelProps}
      >
        {label}
      </Typography>
      <Flex className={classes.divider} />
    </Flex>
  );
};

export default DividerTitled;
