import classes from './index.module.scss';

import React from 'react';
import isString from 'lodash/isString';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import Flex from '../Flex';

interface SwitchProps {
  value?: boolean;
  className?: string;
  label?: string;
  onChange?: Function;
  labelProps?: any;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => any;
}

const Switch: React.FC<SwitchProps> = ({
  value,
  className,
  label,
  onChange,
  labelProps = {},
  onClick,
}) => {
  const onChangeHandler = () => onChange?.(!value);

  return (
    <Flex className={cnj(classes.switchRoot, className)} onClick={onClick}>
      {label &&
        (isString(label) ? (
          <Typography size={15} font="bold" color="thirdText" {...labelProps}>
            {label}
          </Typography>
        ) : (
          label
        ))}
      <Flex as="label" className={classes.toggle}>
        <input
          onChange={onChangeHandler}
          className={classes.toggleCheckbox}
          type="checkbox"
          checked={value}
        />
        <Flex
          className={cnj(
            classes.toggleSwitch,
            value && classes.toggleSwitchActive
          )}
        />
      </Flex>
    </Flex>
  );
};

export default Switch;
