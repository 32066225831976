import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import HeroIcon from '@lobox/uikit/HeroIcon';
import { TitleLine } from '../../../shared/components/molecules';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';
import classes from './FooterSection.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';
import isString from 'lodash/isString';

type BgGradientType = {
  start: ColorsKeys;
  end: ColorsKeys;
};

export interface FooterSectionProps {
  htmlId?: string;
  className?: string;
  topIconName?: string;
  childrenWrapperClassName?: string;
  title?: string;
  titleColor?: ColorsKeys;
  noTitleLine?: boolean;
  bg?: BgGradientType | ColorsKeys;
  children?: React.ReactNode;
  decorateImage?: React.ReactNode;
}

const FooterSection: React.FC<FooterSectionProps> = ({
  htmlId,
  className,
  topIconName,
  childrenWrapperClassName,
  title,
  titleColor = 'coal',
  noTitleLine,
  bg = 'white',
  children,
  decorateImage,
}) => {
  // TODO
  const cssVars = useCssVariables({
    scope: classes.footerSectionRoot,
    variables: {
      ...(!isString(bg) && {
        bgColorImage: `linear-gradient(var(--${bg.start}), var(--${bg.end}))`,
      }),
    },
  });

  return (
    <Flex
      id={htmlId}
      className={cnj(
        classes.footerSectionRoot,
        typeof bg === 'string' && classes[`background-${bg}`],
        className
      )}
    >
      {cssVars}
      {!!topIconName && (
        <Flex className={classes.contentWrapper}>
          <HeroIcon
            iconName={topIconName}
            color="brand"
            iconType="fas"
            size={80}
            className={classes.heroIcon}
          />
        </Flex>
      )}
      <Flex className={classes.contentWrapper}>
        <Flex
          className={cnj(
            decorateImage ? classes.leftWrapper_decorated : classes.leftWrapper
          )}
        >
          <TitleLine
            size="large"
            label={title}
            labelColor={titleColor}
            noLine={noTitleLine}
          />
          <Flex className={childrenWrapperClassName}>{children}</Flex>
        </Flex>
        {decorateImage && (
          <Flex className={classes.decoratedImageWrapper}>{decorateImage}</Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default FooterSection;
