import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useDisclosure, landingRouteNames } from '@lobox/utils';
import Logo from '@shared/svg/landing/LogoIcon';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';
import type { NavMenuProps } from './NavMenu';
import NavMenu from './NavMenu';
import classes from './FooterNavbar.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

export interface FooterNavbarProps extends NavMenuProps {
  className?: string;
  logoColor?: ColorsKeys;
}

const FooterNavbar: React.FC<FooterNavbarProps> = ({
  className,
  logoColor = 'white',
  specialButtonProps,
  itemsList,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const cssVars = useCssVariables({
    scope: classes.placeHolder,
    variables: { logoColor: `var(--${logoColor})` },
  });

  return (
    <Flex className={classes.placeHolder}>
      <Flex
        className={cnj(
          classes.navbarWrapper,
          isOpen && classes.navbarOpen,
          className
        )}
      >
        <Flex as="a" href={landingRouteNames.prime}>
          {cssVars}
          <Logo
            visiblelobox
            className={cnj(classes.logo, isOpen && classes.logoOpen)}
          />
        </Flex>
        <NavMenu
          className={classes.navbar}
          specialButtonProps={specialButtonProps}
          itemsList={itemsList}
        />
        <IconButton
          size="lg"
          name={isOpen ? 'times' : 'bars'}
          type="far"
          colorSchema="transparent"
          className={classes.hamburgerButton}
          iconClassName={classes.hamburgerIcon}
          onClick={onToggle}
        />
        {isOpen && (
          <Flex className={classes.itemsWrapper}>
            {itemsList.map((item) => (
              <Button
                key={item.label as string}
                schema="ghost"
                labelColor="white"
                className={classes.item}
                {...item}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default FooterNavbar;
