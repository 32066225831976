import React from 'react';
import isString from 'lodash/isString';
import dynamic from 'next/dynamic';
import TextInput from '../TextInput';
import FieldWrapper from './FieldWrapper';
import Switch from '../Switch';
import CheckBox from '../CheckBox';
import FuseAutoComplete from '@lobox/uikit/AutoComplete/FuseAutoComplete';
import AsyncAutoComplete from '@lobox/uikit/AutoComplete/AsyncAutoComplete';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import ShareOnFeed from '../ShareOnFeed';
import Links from '../Links';
import Remember from '../Remember';
import SkillPicker from '../SkillPicker';
import CheckBoxGroup from '../CheckBoxGroup';

const ImageCropper = dynamic(() => import('../ImageCropper'), {
  ssr: false,
});

const ImageDelete = dynamic(() => import('../ImageDelete'), {
  ssr: false,
});
const Datepicker = dynamic(() => import('../Datepicker'), {
  ssr: false,
});
const UserPicker = dynamic(() => import('../UserPicker'), {
  ssr: false,
});
const UsernameInput = dynamic(() => import('../UsernameInput'), {
  ssr: false,
});
const LanguagePicker = dynamic(() => import('../LanguagePicker'), {
  ssr: false,
});
const LatLanPicker = dynamic(() => import('../LatLanPicker'), {
  ssr: false,
});
const PhoneInput = dynamic(() => import('../PhoneInput'), {
  ssr: false,
});
const RadioGroup = dynamic(() => import('../RadioGroup'), {
  ssr: false,
});

const RichText = dynamic(() => import('../RichText'), {
  ssr: false,
});
const JobWorkPlaceType = dynamic(() => import('../JobWorkPlaceType'), {
  ssr: false,
});
const JobQuestionPicker = dynamic(() => import('../JobQuestionPicker'), {
  ssr: false,
});
const HashtagPicker = dynamic(() => import('../HashtagPicker'), {
  ssr: false,
});
const ResumePicker = dynamic(() => import('../ResumePicker'), {
  ssr: false,
});

const LocationPicker = dynamic(() => import('../LocationPicker'), {
  ssr: false,
});

const CityPicker = dynamic(() => import('../CityPicker'), {
  ssr: false,
});
const JobQuestionAnswer = dynamic(() => import('../JobQuestionAnswer'), {
  ssr: false,
});
const AddExternalJobLink = dynamic(() => import('../AddExternalJobLink'), {
  ssr: false,
});
const SliderPicker = dynamic(() => import('../SliderPicker'), {
  ssr: false,
});
const RichTextEditor = dynamic(() => import('../RichTextEditor'), {
  ssr: false,
});
const AttachmentPicker = dynamic(() => import('../AttachmentPicker'), {
  ssr: false,
});

const SalaryPicker = dynamic(() => import('../SalaryPicker'), {
  ssr: false,
});

const components = {
  richTextEditor: { component: RichTextEditor },
  input: { component: TextInput },
  datePicker: { component: Datepicker },
  switch: { component: Switch },
  richtext: { component: RichText },
  checkBox: { component: CheckBox },
  fuseAutoComplete: { component: FuseAutoComplete },
  asyncAutoComplete: { component: AsyncAutoComplete },
  shareOnFeed: { component: ShareOnFeed },
  links: { component: Links },
  imageCropper: { component: ImageCropper },
  imageDelete: { component: ImageDelete },
  remember: { component: Remember },
  userPicker: { component: UserPicker },
  dropdownSelect: { component: DropdownSelect },
  usernameInput: { component: UsernameInput },
  locationPicker: { component: LocationPicker },
  skillPicker: { component: SkillPicker },
  cityPicker: { component: CityPicker },
  languagePicker: { component: LanguagePicker },
  latLonPicker: { component: LatLanPicker },
  phoneInput: { component: PhoneInput },
  radioGroup: { component: RadioGroup },
  checkBoxGroup: { component: CheckBoxGroup },
  jobWorkPlaceType: { component: JobWorkPlaceType },
  jobQuestionPicker: { component: JobQuestionPicker },
  hashtagPicker: { component: HashtagPicker },
  resumePicker: { component: ResumePicker },
  jobQuestionAnswer: { component: JobQuestionAnswer },
  addExternalJobLink: { component: AddExternalJobLink },
  sliderPicker: { component: SliderPicker },
  attachmentPicker: { component: AttachmentPicker },
  salaryPicker: { component: SalaryPicker },
};

export type ComponentNameTypes =
  | 'richTextEditor'
  | 'input'
  | 'datePicker'
  | 'switch'
  | 'richtext'
  | 'checkBox'
  | 'fuseAutoComplete'
  | 'asyncAutoComplete'
  | 'shareOnFeed'
  | 'links'
  | 'imageCropper'
  | 'imageDelete'
  | 'remember'
  | 'userPicker'
  | 'dropdownSelect'
  | 'usernameInput'
  | 'locationPicker'
  | 'skillPicker'
  | 'cityPicker'
  | 'languagePicker'
  | 'latLonPicker'
  | 'phoneInput'
  | 'radioGroup'
  | 'checkBoxGroup'
  | 'jobWorkPlaceType'
  | 'jobQuestionPicker'
  | 'hashtagPicker'
  | 'resumePicker'
  | 'jobQuestionAnswer'
  | 'addExternalJobLink'
  | 'sliderPicker'
  | 'attachmentPicker'
  | 'salaryPicker';

export type CPType = ComponentNameTypes | React.FC<any>;

interface Props {
  cp: CPType;
  label?: string;
  name: string;
}

const ComponentBuilder: React.FC<Props> = ({ cp, label, name, ...rest }) => {
  const component = isString(cp) ? components[cp].component : cp;
  return (
    <FieldWrapper
      key={name}
      {...{
        label,
        component,
        name,
        cp,
        ...rest,
      }}
    />
  );
};

export default ComponentBuilder;
