import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { BrandLine } from '../../../../shared/components/atoms';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';
import classes from './TitleLine.module.scss';

export interface TitleLineProps {
  className?: string;
  label?: string;
  labelColor?: ColorsKeys;
  size?: 'large' | 'regular';
  noLine?: boolean;
}

const TitleLine: React.FC<TitleLineProps> = ({
  className,
  label,
  labelColor = 'coal',
  size = 'regular',
  noLine,
}) => {
  return (
    <Flex className={cnj(classes.titleLineRoot, className)}>
      <Typography
        font="700"
        size={size === 'regular' ? 30 : 40}
        height={size === 'regular' ? 35.16 : 46.88}
        color={labelColor}
        mb={noLine ? 40 : 0}
      >
        {label}
      </Typography>
      {!noLine && <BrandLine />}
    </Flex>
  );
};

export default TitleLine;
