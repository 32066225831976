import React from 'react';

const GoogleIcon = (props: any): JSX.Element => (
  <svg
    width={23}
    height={24}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12.267c0-.831-.074-1.631-.213-2.399H11.735v4.538h6.315a5.398 5.398 0 01-2.341 3.54L19.5 20.89c2.219-2.042 3.5-5.05 3.5-8.622z"
      fill="#3E82F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.735 23.73c3.168 0 5.824-1.05 7.766-2.842l-3.793-2.943c-1.05.704-2.395 1.12-3.973 1.12-3.057 0-5.644-2.064-6.567-4.837l-3.92 3.04a11.73 11.73 0 0010.487 6.462z"
      fill="#32A753"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.169 14.23A7.048 7.048 0 014.8 12c0-.773.133-1.525.368-2.229L1.248 6.733A11.726 11.726 0 000 12.001c0 1.893.453 3.684 1.248 5.268l3.92-3.04z"
      fill="#F9BB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.735 4.936c1.723 0 3.27.592 4.486 1.754l3.366-3.365C17.553 1.433 14.896.271 11.735.271A11.73 11.73 0 001.248 6.733l3.92 3.039c.923-2.773 3.51-4.836 6.567-4.836z"
      fill="#E74133"
    />
  </svg>
);

export default GoogleIcon;
