import classes from './index.module.scss';

import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  forwardRef,
} from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from '@lobox/utils';
import cnj from '../utils/cnj';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import PasswordStrength from './PasswordStrength';
import IconButton from '../Button/IconButton';
import Icon from '../Icon';
import Button from '../Button';
import type { FieldProps } from 'formik';
import Flex from '../Flex/Flex.component';
import isChangedForm from '../Form/isChangedForm';

export interface TextInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  parentOnChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    fp?: FieldProps
  ) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement> | boolean) => void;
  value?: number | string;
  helperText?: string | React.ReactNode;
  label?: string;
  style?: string;
  labelStyle?: string;
  inputWrapClassName?: string;
  withRightIconClassName?: string;
  inputStyle?: string;
  placeholder?: string;
  type?: string;
  error?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
  bordered?: boolean;
  isFocus?: boolean;
  showStrength?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => any;
  trim?: boolean;
  editable?: boolean;
  setStatus?: Function;
  status?: any;
  name?: string;
  maxLength?: number;
  disabledRightIconPropagation?: boolean;
  formikFieldProps?: FieldProps;
  visibleChangeSecure?: boolean;
  autoComplete?: string;
  wrapperId?: string;
  rightIconClassName?: string;
  isAutoFillAble?: boolean;
  visibleCharCounter?: boolean;
  labelProps?: Omit<TypographyProps, 'children'>;
  disabledReadOnly?: boolean;
  labelClassName?: boolean;
  leftIconClassname?: boolean;
}
const TextInput = (
  {
    onChange,
    parentOnChange,
    value,
    helperText,
    label,
    style,
    labelStyle,
    inputWrapClassName,
    withRightIconClassName,
    inputStyle,
    placeholder,
    type,
    error: parentError,
    disabled,
    bordered = true,
    rightIcon,
    leftIcon,
    isFocus: isParentFocus,
    onBlur: onBlurHandler,
    onFocus: onFocusHandler,
    showStrength,
    onClick,
    trim,
    editable = true,
    setStatus,
    status = {},
    name,
    maxLength,
    disabledRightIconPropagation,
    formikFieldProps,
    visibleChangeSecure = true,
    autoComplete = 'off',
    wrapperId,
    rightIconClassName,
    visibleCharCounter,
    labelProps = {},
    disabledReadOnly,
    labelClassName,
    leftIconClassname,
  }: TextInputProps,
  ref: any
) => {
  const inputRef = ref || useRef<HTMLInputElement>(null);
  const firstClickAfterChange = useRef(true);
  const { t } = useTranslation();
  const isPassword = type === 'password';
  const isNumber = type === 'number';
  const defaultType = isPassword || isNumber ? 'text' : type;
  const [secure, setSecure] = useState(isPassword);
  const error = parentError;
  const [hasAutofill, setHasAutofill] = useState(false);
  const isFocus = isParentFocus;
  const labelColor = error ? 'error' : isFocus ? 'brand' : 'border';

  const onChangeWithDebounce = debounce((inputValue) => {
    parentOnChange?.(inputValue, formikFieldProps);
    if (name && status) {
      setStatus?.({ ...status, [name]: undefined });
    }
  }, 250);

  const toggleHandler = () => setSecure((prev) => !prev);

  const onChangHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    firstClickAfterChange.current = false;
    const { value: val } = e?.target;
    const slicedValue = val.slice(0, maxLength);
    const event = {
      ...e,
      target: { ...e.target, value: slicedValue },
    };
    if (!isNumber || /^-?\d+\.?\d*$/.test(val) || val === '') {
      onChange?.(event);
    }
    onChangeWithDebounce(event);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (trim && !!value) {
      onChange?.(value?.toString()?.replace(/ /g, ''));
    }
    onBlurHandler?.(e);
    onFocusHandler?.(false);
    firstClickAfterChange.current = true;
  };

  const onFocus = (e: any) => {
    if (editable) {
      onFocusHandler?.(true);
    }
  };

  const onClickRightIconDiv = (e: React.MouseEvent<HTMLElement>) => {
    if (!disabledRightIconPropagation) {
      e?.stopPropagation();
      e?.preventDefault();
    }
  };

  useEffect(() => {
    if (isParentFocus && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [isParentFocus]);

  useLayoutEffect(() => {
    const _autofillTimer = setTimeout(() => {
      if (!value) {
        let inputHasAutoFill;
        try {
          inputHasAutoFill = inputRef.current?.matches(':autofill');
        } catch (err) {
          try {
            inputHasAutoFill = inputRef.current?.matches(':-webkit-autofill');
          } catch (er) {
            inputHasAutoFill = false;
          }
        }
        setHasAutofill(!!inputHasAutoFill);
      }
    }, 500);

    return () => {
      clearTimeout(_autofillTimer);
    };
  }, []);
  const wrapperProps = wrapperId ? { id: wrapperId } : {};
  const onClickHandler = (e: any) => {
    onClick?.(e);
  };

  return (
    <Flex
      {...wrapperProps}
      className={cnj(
        classes.inputRoot,
        disabled && classes.disabled,
        disabledReadOnly && classes.disabledReadOnly,
        style
      )}
    >
      <Button
        tabIndex={-1}
        schema="transparent"
        onClick={onClick}
        className={classes.wrapBtn}
      >
        <Flex
          as="label"
          className={cnj(
            classes.inputWrap,
            !bordered && classes.borderColorTransparent,
            error && classes.borderColorError,
            isFocus && classes.borderColorFocused,
            disabledReadOnly && classes.inputWrapReadOnly,
            inputWrapClassName
          )}
        >
          {!placeholder && (
            <Typography
              className={cnj(
                classes.label,
                leftIcon && classes.labelWithLeftIcon,
                labelStyle,
                isFocus &&
                  !value &&
                  firstClickAfterChange?.current &&
                  classes.active,
                (!!value || !firstClickAfterChange?.current || hasAutofill) &&
                  classes.defaultActive,
                labelClassName
              )}
              color={labelColor}
              onClick={onClickHandler}
              {...labelProps}
            >
              {label}
            </Typography>
          )}
          <Flex
            flexDir="row"
            className={cnj(
              rightIcon && classes.withRightIcon,
              withRightIconClassName
            )}
          >
            {leftIcon && (
              <Flex className={cnj(classes.leftIcon, leftIconClassname)}>
                {leftIcon}
              </Flex>
            )}
            <input
              ref={inputRef}
              placeholder={placeholder}
              {...{
                onClick: onFocus,
                name,
                value: value || '',
                onFocus,
                onBlur,
                maxLength,
                readOnly: !editable,
                autoComplete,
                spellCheck: false,
              }}
              // @ts-ignore
              onChange={onChangHandler}
              type={
                secure && (autoComplete !== 'new-password' || !!value)
                  ? 'password'
                  : defaultType
              }
              className={cnj(
                classes.input,
                leftIcon && classes.inputWithLeftIcon,
                inputStyle
              )}
            />
          </Flex>
          {rightIcon && (
            <Flex
              className={cnj(classes.rightIcon, rightIconClassName)}
              onClick={onClickRightIconDiv}
            >
              {rightIcon}
            </Flex>
          )}
          {!rightIcon && error && !isPassword && (
            <Icon
              type="fas"
              color="error"
              name="exclamation-circle"
              className={cnj(
                classes.bell,
                classes.rightIcon,
                classes.cursorDefault
              )}
            />
          )}
          {isPassword && visibleChangeSecure && (
            <Flex className={classes.rightIcon} onClick={onClickRightIconDiv}>
              <IconButton
                colorSchema="transparent"
                onClick={toggleHandler}
                name={secure ? 'eye' : 'eye-slash'}
                type="fas"
                size="md"
                variant="circle"
              />
            </Flex>
          )}
        </Flex>
      </Button>
      <Flex className={classes.helperWrap}>
        {(error || helperText) && (
          <Typography
            size={13}
            height={15}
            color="border"
            className={cnj(classes.helperText, error && classes.errorText)}
          >
            {t(error as string) || helperText}
          </Typography>
        )}
        {visibleCharCounter && (
          <Typography
            size={13}
            height={15}
            color="border"
            className={classes.maxLength}
          >{`${value?.toString()?.length || 0}/${maxLength}`}</Typography>
        )}
      </Flex>
      {showStrength && (value?.toString()?.length || 0) > 0 && (
        <PasswordStrength {...{ value }} />
      )}
    </Flex>
  );
};

export default isChangedForm(forwardRef<any, TextInputProps>(TextInput));
