import classes from './PasswordStrength.module.scss';

import React from 'react';
import Typography from '../Typography';
import type { colorsKeys } from '../helpers/theme';
import Flex from '../Flex';

const strengthLabels = {
  0: 'Weak',
  1: 'Okay',
  2: 'Strong',
  3: 'Very strong',
};

export type StrengthLabelsTypes = keyof typeof strengthLabels;

const strengthColors: {
  [key: number]: colorsKeys;
} = {
  0: 'error',
  1: 'warning',
  2: 'lightGreen',
  3: 'success',
};

interface PasswordStrengthProps {
  value: any;
}

const checkStrength = (value: string): StrengthLabelsTypes => {
  if (value.length < 6) {
    return 0;
  }
  if (value.length === 6) {
    return 1;
  }
  if (value.length > 6 && value.length <= 8) {
    return 2;
  }
  if (value.length > 8) {
    return 3;
  }
  return 0;
};
const PasswordStrength: React.FC<PasswordStrengthProps> = ({ value }) => {
  const strength = checkStrength(value);

  return (
    <Flex>
      <Flex className={classes.strengthMeter}>
        <Flex className={classes.strengthMeterFill} data-strength={strength} />
      </Flex>
      <Typography size={13} color={strengthColors[strength]}>
        {strengthLabels[strength]}
      </Typography>
    </Flex>
  );
};

export default PasswordStrength;
