import classes from './skillPicker.inputs.module.scss';

import React, { useState } from 'react';
import {
  db,
  Endpoints,
  skillsResponseNormalizer,
  useTranslation,
} from '@lobox/utils';
import trim from 'lodash/trim';
import cnj from '../utils/cnj';
import AsyncAutoComplete from '@lobox/uikit/AutoComplete/AsyncAutoComplete';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import Flex from '../Flex';
import type { StateSkill } from './types';
import Button from '../Button/Button.component';

interface SkillPickerInputsProps {
  onSuccess?: ({ name, level }: any) => void;
  skillInputValues: StateSkill[] | null;
  setSkillInputValues?: (skill: StateSkill[]) => void;
  editingMode?: boolean;
  deleteEditingRow?: (item: any) => void;
}

const SkillPickerInput = ({
  onSuccess,
  skillInputValues = [],
  setSkillInputValues,
  editingMode,
  deleteEditingRow,
}: SkillPickerInputsProps): JSX.Element => {
  const [editInputsValue, setEditInputsValue] = useState<any>();
  const { t } = useTranslation();

  const handleChange = (id: string, name: string) => (e: any) => {
    const newSkillInputValues = skillInputValues?.map((skl: any) => {
      if (skl.id === id) skl[name] = e;

      return skl;
    });

    if (!editingMode) {
      setSkillInputValues?.(newSkillInputValues!);
    } else {
      setEditInputsValue(newSkillInputValues);
    }
  };

  const confirmSkill = ({ name, level }: any, id: string) => {
    if (!editingMode) {
      onSuccess?.({ name, level });
      deleteRow(id);
    } else if (editInputsValue) {
      onSuccess?.(editInputsValue?.[0]);
    } else {
      const editSkillInputValue = skillInputValues?.find(
        (skl: any) => skl.id === id
      );
      onSuccess?.(editSkillInputValue);
    }
  };

  const deleteRow = (id: string) => {
    if (!editingMode) {
      const newSkillInputValues = skillInputValues?.filter(
        (skl: StateSkill) => skl.id !== id
      );
      setSkillInputValues?.(newSkillInputValues!);
    } else {
      deleteEditingRow?.(editInputsValue?.[0]);
      setEditInputsValue(null);
    }
  };

  return (
    <>
      {(editInputsValue || skillInputValues)?.map((skill: any) => {
        const isValidLabel = trim(skill.name?.label)?.length;

        return (
          <Flex className={cnj(classes.inputsContainer)} key={skill.id}>
            <AsyncAutoComplete
              variant="simple"
              name="skillName"
              placeholder={t('add_skill')}
              rightIconProps={{ name: 'search', size: 'md18' }}
              visibleRightIcon
              value={skill.name}
              onChange={handleChange(skill.id, 'name')}
              url={Endpoints.App.Common.getSkills}
              normalizer={skillsResponseNormalizer}
            />

            {!!skill.name?.value && (
              <DropdownSelect
                variant="simple"
                options={db.SKILL_LEVELS}
                className={classes.levelDropDown}
                value={skill.level}
                onChange={handleChange(skill.id, 'level')}
              />
            )}

            <Flex className={classes.buttonsWrapper}>
              <Button
                leftIcon="times"
                leftSize={18}
                schema="gray-semi-transparent"
                onClick={() => deleteRow(skill.id)}
                className={classes.button}
              />
              <Button
                schema="semi-transparent"
                leftIcon="check"
                leftSize={18}
                disabled={!isValidLabel}
                onClick={() =>
                  confirmSkill(
                    { name: skill.name, level: skill.level },
                    skill.id
                  )
                }
                className={classes.button}
              />
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export default SkillPickerInput;
