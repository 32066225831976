import classes from './SkillList.component.module.scss';

import React from 'react';
import partial from 'lodash/partial';
import { useTranslation } from '@lobox/utils';
import type { IHardSkill } from '@lobox/utils';
import Flex from '../Flex/Flex.component';
import ProgressItem from '../ProgressItem';
import PopperMenu from '../PopperMenu';
import IconButton from '../Button/IconButton';
import PopperItem from '../PopperItem';
import cnj from '../utils/cnj';
import SkillPickerInput from './skillPicker.inputs';

interface SkillListProps {
  skills: Array<IHardSkill>;
  visibleAction?: boolean;
  editHandler?: (item: any) => void;
  deleteHandler?: (item: any) => void;
  className?: string;
  initialValues?: any;
  onSuccess?: any;
}

const SkillList: React.FC<SkillListProps> = ({
  skills,
  visibleAction,
  editHandler,
  deleteHandler,
  className,
  initialValues,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const isEmptySkill = skills?.length === 0;

  return (
    <Flex className={cnj(classes.skillWrap, className)}>
      {!isEmptySkill && (
        <Flex className={classes.listWrap}>
          {skills?.map((item, index) =>
            initialValues?.label === item?.label &&
            initialValues?.id === item.id ? (
              <SkillPickerInput
                skillInputValues={
                  initialValues
                    ? [
                        {
                          name: {
                            label: initialValues?.label,
                            value: initialValues?.id,
                          },
                          level: {
                            label: initialValues?.level,
                            value: initialValues?.skillLevel,
                          },
                        },
                      ]
                    : null
                }
                onSuccess={onSuccess}
                editingMode
                // @ts-ignore
                deleteEditingRow={partial(deleteHandler, item)}
              />
            ) : (
              <ProgressItem
                key={item.id || index}
                title={item.label}
                progressValue={item.progress}
                progressSteps={4}
                tooltipText={item.level}
                styles={
                  index !== 0 ? { root: classes.progressItem } : undefined
                }
                actionButton={
                  visibleAction ? (
                    <PopperMenu
                      placement="bottom-end"
                      buttonComponent={
                        <IconButton
                          type="far"
                          name="ellipsis-h"
                          size="sm"
                          colorSchema="transparent"
                        />
                      }
                    >
                      <PopperItem
                        // @ts-ignore
                        onClick={partial(editHandler, item as any)}
                        iconName="pen"
                        label={t('edit_skill')}
                      />
                      <PopperItem
                        // @ts-ignore
                        onClick={partial(deleteHandler, item as any)}
                        iconName="trash"
                        label={t('remove_skill')}
                      />
                    </PopperMenu>
                  ) : undefined
                }
              />
            )
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default SkillList;
