import React from 'react';

interface PrimeHeaderBgProps {
  className?: string;
}

const PrimeHeaderBg: React.FC<PrimeHeaderBgProps> = ({ className }) => (
  <svg
    width={840}
    height={546}
    viewBox="0 0 840 546"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M341.035 513.627C662.616 631.569 587.657 388.051 840 404.694V0H0c179.2 0 19.455 395.686 341.035 513.627z"
      fill="url(#chief_header_bg_prefix__paint0_linear)"
    />
    <defs>
      <linearGradient
        id="chief_header_bg_prefix__paint0_linear"
        x1={-329.753}
        y1={682.38}
        x2={473.562}
        y2={25.136}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.238} stopColor="#C9D6EA" />
        <stop offset={0.915} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default PrimeHeaderBg;
