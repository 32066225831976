import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import HeroIcon from '@lobox/uikit/HeroIcon';
import Typography from '@lobox/uikit/Typography';
import { TitleLine } from '../../../shared/components/molecules';
import classes from './ValueItem.module.scss';

export interface ValueItemProps {
  className?: string;
  iconName: string;
  title: string;
  description: string;
}

const ValueItem: React.FC<ValueItemProps> = ({
  className,
  iconName,
  title,
  description,
}) => {
  return (
    <Flex className={cnj(classes.valueItemRoot, className)}>
      <HeroIcon iconName={iconName} color="brand" iconType="fas" size={80} />
      <TitleLine label={title} className={classes.titleLine} />
      <Typography font="400" size={15} height={21} color="coal">
        {description}
      </Typography>
    </Flex>
  );
};

export default ValueItem;
