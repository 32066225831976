import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Square from '@shared/svg/landing/Square';
import Dots from '@shared/svg/landing/Square';
import classes from './DecoratedImage.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

interface DecoratedImageProps {
  className?: string;
  src: string;
  width?: number;
  height?: number;
  curveDir?: 'left' | 'right';
}

const DecoratedImage: React.FC<DecoratedImageProps> = ({
  className,
  src,
  width,
  height,
  curveDir,
}) => {
  const _src = typeof src === 'string' ? src : src?.src;
  const cssVars = useCssVariables({
    scope: classes.decoratedImageRoot,
    variables: {
      widthDecorated: `${width}px`,
      heightDecorated: `${height}px`,
      bgImageDecorated: `url(${_src}) no-repeat center / cover`,
    },
  });
  const isRight = curveDir === 'right';

  return (
    <Flex className={cnj(classes.decoratedImageRoot, className)}>
      {cssVars}
      {!!curveDir && (
        <>
          <Dots className={cnj(classes.dots, isRight && classes.dots_right)} />
          <Square
            className={cnj(classes.square, isRight && classes.square_right)}
          />
        </>
      )}
      <Flex className={cnj(classes.image, isRight && classes.image_right)} />
    </Flex>
  );
};

export default DecoratedImage;
