import React from 'react';

interface SquareProps {
  className?: string;
}

const Square: React.FC<SquareProps> = ({ className }) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      stroke="#5384EE"
      strokeWidth={5}
      d="M3.774 20.915l30.31-17.5 17.5 30.31-30.31 17.5z"
    />
  </svg>
);

export default Square;
