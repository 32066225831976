import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import DecoratedImage from '../../../shared/components/molecules/DecoratedImage';
import type { BoardProps } from './Board';
import Board from './Board';
import classes from './JobBoard.module.scss';

interface JobBoardProps extends BoardProps {
  reverse?: boolean;
  image?: any;
  className?: string;
}

const JobBoard: React.FC<JobBoardProps> = ({
  reverse,
  title,
  subtitle,
  description,
  list,
  image,
  className,
}) => {
  return (
    <Flex
      className={cnj(
        classes.jobBoardRoot,
        reverse && classes.jobBoardRoot_reverse,
        className
      )}
    >
      <DecoratedImage
        src={image}
        curveDir={reverse ? 'right' : 'left'}
        className={classes.decoratedImage}
      />
      <Board
        className={classes.modifyBoard}
        title={title}
        subtitle={subtitle}
        description={description}
        list={list}
      />
    </Flex>
  );
};

export default JobBoard;
