import React from 'react';
import Flex from '@lobox/uikit/Flex/index';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import { Portrayal } from '../../../shared/components/molecules';
import FindAudience from '@shared/svg/landing/FindAudience';
import FindEvents from '@shared/svg/landing/FindEvents';
import CreateCommunity from '@shared/svg/landing/CreateCommunity';
import classes from './Prime.Social.module.scss';

const PrimeSocial: React.FC = () => {
  const { t } = useTranslation();
  const portrayalList = [
    {
      svg: <FindAudience />,
      title: t('find_audience'),
      subtitle: t('find_audience_content'),
    },
    {
      svg: <CreateCommunity />,
      title: t('create_community'),
      subtitle: t('create_community_content'),
    },
    {
      svg: <FindEvents />,
      title: t('find_events'),
      subtitle: t('find_events_content'),
    },
  ];

  return (
    <Flex className={classes.socialRoot}>
      <Typography
        font="700"
        size={16}
        height={20}
        textAlign="center"
        color="graphene_60"
        mb={10}
      >
        {t('social_media')}
      </Typography>
      <Typography
        font="400"
        size={20}
        height={28}
        textAlign="center"
        color="coal"
        mb={40}
      >
        {t('social_content')}
      </Typography>
      <Flex className={classes.portrayalWrapper}>
        {portrayalList.map(({ svg, title, subtitle }) => (
          <Portrayal
            key={title}
            svg={svg}
            title={title}
            subtitle={subtitle}
            className={classes.portrayal}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default PrimeSocial;
