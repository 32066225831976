import classes from './ResponsiveModal.module.scss';

import React, { useRef } from 'react';
import cnj from '../utils/cnj';
import Modal from './index';
import Flex from '../Flex';

interface StyleProps {
  backdrop?: string;
  wrapper?: string;
  content?: string;
  modalRoot?: string;
  imageStyle?: string;
  modalHeader?: string;
  backBtn?: string;
  closeBtn?: string;
  searchIcon?: string;
}

export interface ResponsiveModalProps {
  children: React.ReactNode;
  styles?: Partial<StyleProps>;
  onBackDropClick?: Function;
}
const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  children,
  styles,
  onBackDropClick,
}) => {
  const wrapperRef = useRef(null);

  return (
    <Modal
      styles={{
        backdrop: cnj(classes.backdrop, styles?.backdrop),
        wrapper: cnj(classes.wrapper, styles?.wrapper),
        content: cnj(classes.content, styles?.content),
      }}
      onBackDropClick={onBackDropClick}
    >
      <Flex
        ref={wrapperRef}
        className={cnj(classes.modalRoot, styles?.modalRoot)}
      >
        {children}
      </Flex>
    </Modal>
  );
};

export default ResponsiveModal;
