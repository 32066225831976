import React, { useEffect, useRef, useState } from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex/index';
import Image from '@lobox/uikit/Image';
import Typography from '@lobox/uikit/Typography';
import {
  appEnvironment,
  useResizeWindow,
  useTranslation,
  landingRouteNames,
} from '@lobox/utils';
import classes from './Prime.Globe.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

const globeImage = `${appEnvironment.storageBaseUrl}/assets/images/landing/globe.webp`;

const ROOT_BG_ASPECT_RATIO = 0.492293;

const PrimeGlobe: React.FC = () => {
  const { t } = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [rootHeight, setRootHeight] = useState(0);

  const calculateRootHeight = () => {
    const viewWidth = window.innerWidth;
    const rootWidth = rootRef?.current?.clientWidth || 0;
    setRootHeight(
      rootWidth *
        (viewWidth > 830
          ? ROOT_BG_ASPECT_RATIO
          : viewWidth > 510
            ? 0.6
            : viewWidth > 455
              ? 0.7
              : viewWidth > 370
                ? 0.8
                : viewWidth > 340
                  ? 0.85
                  : 0.9)
    );
  };

  useResizeWindow(calculateRootHeight);

  useEffect(() => {
    calculateRootHeight();
  }, []);

  const cssVars = useCssVariables({
    scope: classes.globeRoot,
    variables: {
      bgImagePoz: `url(${globeImage}) no-repeat top center / contain`,
    },
  });

  return (
    <Flex
      className={classes.globeRoot}
      ref={rootRef}
      style={{ height: rootHeight }}
    >
      {cssVars}
      <Flex className={classes.globeChildrenWrapper}>
        <Image
          defaultTag
          src={`${appEnvironment.storageBaseUrl}/assets/images/landing/avatarsTop.webp`}
          alt="."
          className={classes.image}
        />
        <Flex className={classes.textWrapper}>
          <Typography
            font="400"
            size={20}
            height={28}
            color="coal"
            textAlign="center"
            className={classes.text}
          >
            {t('prime_globe_title')}
          </Typography>
          <Button
            variant="large"
            label={t('prime_globe_button')}
            className={classes.button}
            to={landingRouteNames.signup}
          />
        </Flex>
        <Image
          defaultTag
          src={`${appEnvironment.storageBaseUrl}/assets/images/landing/avatarsBottom.webp`}
          alt="."
          className={classes.image}
        />
      </Flex>
    </Flex>
  );
};

export default PrimeGlobe;
