import Fuse from 'fuse.js';

export type FuseConfigType = {
  // Basic Options
  isCaseSensitive?: boolean;
  includeScore?: boolean;
  includeMatches?: boolean;
  minMatchCharLength?: number;
  maxPatternLength?: number;
  shouldSort?: boolean;
  findAllMatches?: boolean;
  keys?: Array<string | object>;
  // Fuzzy Matching Options
  location?: number;
  threshold?: number;
  distance?: number;
  ignoreLocation?: boolean;
  // Advanced Options
  useExtendedSearch?: boolean;
  getFn?: (obj: object, path: string | string[]) => string | string[];
  sortFn?: (a: any, b: any) => number;
  ignoreFieldNorm?: boolean;
};

const fuseSearch = <T>(items: T[], config: FuseConfigType) => {
  const fuse = new Fuse(items, {
    shouldSort: true,
    includeMatches: false,
    findAllMatches: true,
    threshold: 0.1,
    distance: 100,
    maxPatternLength: 32,
    ...config,
  } as any);

  return (pattern: string): T[] =>
    pattern === '' ? items : fuse.search(pattern).map(({ item }) => item);
};

export default fuseSearch;
