import classes from './index.module.scss';

import React, { cloneElement } from 'react';
import isString from 'lodash/isString';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import ProgressBar from '../ProgressBar';
import Flex from '../Flex';
import Image from '../Image';
import Tooltip from '@lobox/uikit/Tooltip';

interface StyleProps {
  root?: string;
}

type ProgressItemProps = {
  title: string;
  subtitle?: string;
  image?: string | React.ReactNode;
  actionButton?: React.ReactNode | React.ReactElement;
  progressSteps?: number;
  progressValue: number;
  progressName?: string;
  progressHeight?: number;
  styles?: Partial<StyleProps>;
  tooltipText?: string;
};

const ProgressItem: React.FC<ProgressItemProps> = ({
  title,
  subtitle,
  image,
  actionButton,
  progressSteps,
  progressValue,
  progressName,
  progressHeight,
  styles,
  tooltipText,
}) => {
  const isStepsPassed = typeof progressSteps !== 'undefined';

  return (
    <Flex className={cnj(classes.progressItemRoot, styles?.root)}>
      <Flex className={classes.titleWrapper}>
        {!image ? null : isString(image) ? (
          <Image alt={title} className={classes.image} src={image} />
        ) : (
          cloneElement(image as any, { className: classes.image })
        )}
        <Typography
          className={classes.progressItemTitle}
          color="thirdText"
          font="700"
          size={15}
          height={18}
          isTruncated
        >
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            color="primaryDisabledText"
            font="400"
            size={13}
            height={15}
            className={classes.subtitle}
            isTruncated
          >
            {subtitle}
          </Typography>
        )}
        {isStepsPassed ? (
          <Flex className={classes.actionButton}>{actionButton}</Flex>
        ) : (
          <Flex className={classes.actionWrapper}>
            <Typography
              color="colorIconForth"
              font="500"
              size={14}
              height={16.41}
            >
              {`${progressValue}%`}
            </Typography>
          </Flex>
        )}
      </Flex>
      {tooltipText ? (
        <Tooltip
          placement="bottom"
          triggerWrapperClassName={classes.toolTip}
          trigger={
            <ProgressBar
              value={progressValue}
              steps={progressSteps}
              progressName={progressName}
              height={progressHeight}
            />
          }
        >
          <Typography
            className={classes.toolTipContent}
            size={14}
            font="400"
            height={18}
            color="tooltipText"
          >
            {tooltipText}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          className={classes.toolTipContent}
          size={14}
          font="400"
          height={18}
          color="tooltipText"
        >
          {subtitle}
        </Typography>
      )}
    </Flex>
  );
};

export default ProgressItem;
