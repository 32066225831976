import React, { useState } from 'react';
import isString from 'lodash/isString';
import PrivacyVisibility from '../PrivacyVisibility';
import type { FieldProps } from 'formik';
import type { PrivacyValueType } from '@lobox/utils';

type ValueType =
  | string
  | { [key: string]: any; value: string | number; label: string };

const isInput = (cp: string): boolean =>
  isString(cp) && ['input', 'addExternalJobLink'].includes(cp);

const hasValueObject = (cp?: any | React.ReactNode) =>
  isString(cp) && ['fuseAutoComplete', 'asyncAutoComplete'].includes(cp);

interface Props {
  name: string;
  component: any;
  cp: any;
  formikFieldProps: FormikFieldProps;
  label?: string;
  privateable?: boolean;
  onChange?: Function;
  trim?: boolean;
  forceVisibleError?: boolean;
  isFocused?: boolean;
  required?: boolean;
}

type FormikFieldProps = FieldProps;

const privateableHandler = ({
  formikFieldProps,
  cp,
  name,
  parentOnChange,
}: {
  formikFieldProps: FormikFieldProps;
  cp: any;
  name: string;
  parentOnChange?: Function;
}) => {
  const {
    field: { value },
    form: { setFieldValue, handleBlur },
  } = formikFieldProps;
  const onClick = (privacy: PrivacyValueType) => {
    setFieldValue(name, { ...value, privacy });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = hasValueObject(cp)
      ? {
          ...value,
          ...e,
        }
      : isInput(cp)
        ? { ...value, value: e.target.value }
        : { ...value, value: e };
    setFieldValue(name, updatedValue);
    handleBlur(name);
    parentOnChange?.(updatedValue, formikFieldProps);
  };
  const inputProps = hasValueObject(cp)
    ? { onChange, value, privacy: value?.privacy }
    : { onChange, value: value?.value, privacy: value?.privacy };
  return {
    rightIcon: <PrivacyVisibility privacy={value?.privacy} onClick={onClick} />,
    ...inputProps,
  };
};

const Field: React.FC<Props> = ({
  name,
  component,
  cp,
  formikFieldProps,
  label,
  privateable,
  onChange: parentOnChange,
  forceVisibleError,
  isFocused = false,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(isFocused);

  const {
    field: { value },
    form: {
      errors,
      handleChange,
      setFieldValue,
      setFieldTouched,
      touched,
      handleSubmit,
      validateField,
      handleBlur,
      setStatus,
      status = {},
    },
  } = formikFieldProps;

  const hasError =
    !!value &&
    (!!errors[name] || !!status?.[name]) &&
    !!touched[name] &&
    !isFocus;

  const errorMessage = errors[name] || status?.[name];
  const error = hasError || forceVisibleError ? errorMessage : undefined;

  const handleConfirm = (val: ValueType) => {
    parentOnChange?.(val, formikFieldProps);
    setFieldValue(name, val);
    setFieldTouched(name, true, false);
    setStatus({ ...status, [name]: undefined });
    validateField(name);
  };

  const onChange = isInput(cp) ? handleChange(name) : handleConfirm;
  return React.createElement(component, {
    ...rest,
    isFocus,
    name,
    label,
    error,
    onChange,
    parentOnChange,
    formikFieldProps,
    onBlur: handleBlur(name),
    onFocus: setIsFocus,
    handleSubmit,
    value,
    setStatus,
    status,
    privateable,
    cp,
    ...(privateable
      ? privateableHandler({
          formikFieldProps,
          parentOnChange,
          cp,
          name,
        })
      : {}),
  });
};

export default Field;
