import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import fuseSearch from '../utils/fuseSearch';
import type { AutoCompleteProps } from './index';
import AutoComplete from './index';

interface FuseAutoCompleteProps extends AutoCompleteProps {
  keys?: Array<string>;
  onChange?: Function;
  initSearchValue?: string;
}

const FuseAutoComplete: React.FC<FuseAutoCompleteProps> = ({
  options = [],
  onChange: parentOnChange,
  keys = ['label'],
  limit = 6,
  value,
  visibleRightIcon,
  name,
  isMulti,
  initSearchValue = '',
  ...rest
}) => {
  const ref = useRef<any>(null);
  const [filteredOption, setFilteredOption] = useState<any>([]);

  const search = fuseSearch(options, { keys });

  const onChangeInput = (input: string) => {
    const items = search(input).slice(0, limit);
    setFilteredOption(items);
  };

  const onSelectWithDebounce = debounce((item: any) => {
    parentOnChange?.(item);
  }, 250);

  return (
    <AutoComplete
      {...{
        options: filteredOption,
        onChangeInput,
        onSelect: onSelectWithDebounce,
        value,
        // @ts-ignore
        displayName: value?.label,
        visibleRightIcon,
        ref,
        isMulti,
        name,
        ...rest,
      }}
    />
  );
};

export default FuseAutoComplete;
