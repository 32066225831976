import React from 'react';

interface WhiteWaveProps {
  className?: string;
}

const WhiteWave: React.FC<WhiteWaveProps> = ({ className }) => (
  <svg
    width={1920}
    height={55}
    viewBox="0 0 1920 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    preserveAspectRatio="none"
  >
    <path
      d="M1028.19 1.509C663.843 13.017 213.413 33.999 0 55c603.347.333 1641.53 0 1920 0-343.44-6.499-527.46-65-891.81-53.491z"
      fill="#fff"
    />
  </svg>
);

export default WhiteWave;
